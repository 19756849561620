import INSIGHT from './INSIGHT.json'
import FORM from './FORM.json'
import CONFIG from './CONFIG.json'
import AUTH from './AUTH.json'
import DASHBOARD from './DASHBOARD.json'
import AUDIT from './AUDIT.json'
import SEARCH from './SEARCH.json'

const LOCAL = {
  ToolTipLabelNew: 'Create',
  ToolTipLabelView: 'View',
  ToolTipLabelResults: 'Results',
  ToolTipLabelEdit: 'Edit',
  ToolTipLabelDelete: 'Delete',
  ToolTipLabelStar: 'Favourite',
  ToolTipLabelBrowse: 'Browse',
  ToolTipLabelSync: 'Sync Now',
  LoadMore: 'Load More',
  Forms: 'Forms',
  Groups: 'Groups',
  User: 'User(s)',
  Group: 'Group(s)',
  PermittedGroups: 'permitted_groups',
  Entity: 'Entity',
  Entities: 'Entities',
  Link: 'Link',
  Links: 'Links',
  Query: 'Query',
  Chart: 'Chart',
  ReleaseNotes: 'Download Release Notes',
  Search: 'Search',
  AriaLabelSearch: 'Search',
  AriaLabelDelete: 'Delete',
  AriaLabelNew: 'Add',
  AriaLabelRefresh: 'Refresh',
  AriaLabelView: 'View',
  AriaLabelEdit: 'Edit',
  AriaLabelField: 'Field',
  AriaLabelOperator: 'Operator',
  AriaLabelSingleInput: 'Single value input',
  AriaLabelDatalist: 'Datalist',
  AriaLabelSelect: 'Select',
  AriaLabelMonthInput: 'Month input',
  AriaLabelYearInput: 'Year input',
  AriaLabelDualInputLower: 'Lower bound input',
  AriaLabelDualInputUpper: 'Upper bound input',
  AriaLabelAddLinkEnd: 'Add link end',
  AriaLabelChangeLinkEnd: 'Change link end',
  COMMON_WORD: {
    ALL: 'All',
    ASSIGN: 'Assign',
    BACK: 'Back',
    EDIT: 'Edit',
    CREATE: 'Create',
    EXPORT: 'Export',
    FOR: 'For',
    NEW: 'New',
    NO: 'No',
    VIEW: 'View',
    YES: 'Yes',
    DELETE: 'Delete',
    SUBMIT: 'Submit',
    CANCEL: 'Cancel',
    CREATED: 'Created',
    DELETED: 'Deleted',
    EDITED: 'Created',
    DEFAULT: 'Default',
    RESULTS: 'Results',
    ATTACHMENT: 'attachment',
    LOADING: 'Loading',
    CHECKING: 'Checking',
    FETCHING: 'Fetching',
    GENERATING: 'Generating',
    DISPLAYING: 'Displaying',
    SETTING: 'Setting',
    SETTINGS: 'Settings',
    DESCRIPTION: 'Description',
    OK: 'OK',
    RETRY: 'Retry',
    REVIEW: 'Review',
    REFRESH: 'Refresh',
    FINISH: 'Finish',
    RESET: 'Reset',
    TEST: 'Test',
    SAVE: 'Save',
    CONTINUE: 'Continue',
    SEARCH: 'Search',
    TODAY: 'Today',
    NEXT: 'Next',
    NOW: 'Now',
    CLEAR: 'Clear',
    SELECT: 'Select',
    SELECTING: 'Selecting',
    REMOVE: 'Remove',
    CONFIRM: 'Confirm',
    SELECTALL: 'Select All',
    UNSELECTALL: 'Unselect All',
    OVERVIEW: 'Overview',
    ACTION: 'Action',
    PERMISSION: 'Permission',
    NAME: 'Name',
    RECORDS: 'Records',
    EXISTING: 'Existing',
    SUCCESS: 'Success',
    ERROR: 'Error',
    OTHERS: 'Others',
    CHARACTERS_REMAINING: ' character(s) remaining',
    SYNC: 'Sync'
  },
  CONFIRMATION_PLACEHOLDER: 'Type "Yes" to Confirm',
  CONFIRMATION_ERROR_MESSAGE: 'Invalid confirmation text. Please type "Yes" to Confirm.',
  CANNOT_CREATE_RECORDS: 'Cannot Create Records because one of the forms is restricted',
  LEFT_PARENTHESIS: '(',
  RIGHT_PARENTHESIS: ')',
  HTML_ENCODE_MAP: {
    '&': '&amp;',
    '<': '&lt;',
    '>': '&gt;',
    '"': '&quot;',
    "'": '&#039;',
    '=': '&#61;',
    '(': '&#40;',
    ')': '&#41;'
  },
  HTML_PATTERN: {
    HIGHLIGHT_PREFIX: "<span style='background-color:#FF0;'>",
    HIGHLIGHT_POSTFIX: '</span>'
  },
  NO_PERMISSION_ITEM: 'Nothing to assign',
  API_SERVICE: {
    TOAST_ID: 'interceptor_toast',
    MAINTENANCE_MODE_TOAST_TITLE: 'Maintenance',
    MAINTENANCE_MODE_TOAST_MSG: 'Insight is currently under maintenance.',
    INVALID_TOKEN_TOAST_TITLE: 'Session Expired',
    INVALID_TOKEN_TOAST_MSG: 'Your login session has expired. Please login again.'
  },
  KEYBOARD_EVENT_CODE: {
    // digit -> US keyboard, numpad -> numpad or phone keyboards
    NUMBER_CODES: ['Digit0', 'Digit1', 'Digit2', 'Digit3', 'Digit4', 'Digit5', 'Digit6', 'Digit7', 'Digit8', 'Digit9',
      'Numpad0', 'Numpad1', 'Numpad2', 'Numpad3', 'Numpad4', 'Numpad5', 'Numpad6', 'Numpad7', 'Numpad8', 'Numpad9'],
    BACKSPACE: 'Backspace',
    DELETE: 'Delete',
    LEFT_ARROW: 'ArrowLeft',
    RIGHT_ARROW: 'ArrowRight',
    ARROWKEYS: ['ArrowLeft', 'ArrowRight']
  },
  HOME_MESSAGES: {
    NO_USER_GROUP_ASSIGNED_MSG: 'You may encounter difficulty accessing the portal because you are not assigned to any user group.'
  },
  NO_USER_GROUP_MODEL: {
    MODEL_MSG: 'You are unable to access the portal because you are not assigned to any user group. Please contact the administrator.',
    MODEL_TITLE: 'No user group assigned'
  },
  KEYS: {
    OPEN_BRACKET: {
      key: '{',
      keyCode: 219
    },
    CLOSE_BRACKET: {
      key: '}',
      keyCode: 221
    }
  },
  LABEL_FORMATTER: {
    SUBSTRING: {
      START_POS: 1,
      END_POS: 1
    }
  }
}

const Constants = {
  LOCAL,
  INSIGHT,
  FORM,
  CONFIG,
  AUTH,
  DASHBOARD,
  AUDIT,
  SEARCH
}

Constants.install = function (Vue) {
  Vue.prototype.$LOCAL = key => Constants.LOCAL[key]
  Vue.prototype.$INSIGHT = key => Constants.INSIGHT[key]
  Vue.prototype.$FORM = key => Constants.FORM[key]
  Vue.prototype.$CONFIG = key => Constants.CONFIG[key]
  Vue.prototype.$AUTH = key => Constants.AUTH[key]
  Vue.prototype.$DASHBOARD = key => Constants.DASHBOARD[key]
  Vue.prototype.$AUDIT = key => Constants.AUDIT[key]
  Vue.prototype.$SEARCH = key => Constants.SEARCH[key]
}

export default Constants

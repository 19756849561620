import actions from './actions'
import getters from './getters'
import mutations from './mutations'
import state from './state'
import insight from './insight'
import admin from './admin'

const namespaced = true

export default {
  namespaced,
  state,
  getters,
  actions,
  mutations,
  modules: {
    insight,
    admin
  }
}

export default {
  errorCode: 0,
  error: '',
  loading: false,
  saved: false,
  dirty: false,
  dbConnectionFields: {},
  dbConnectionIsValid: false,
  auditDbConnectionFields: {},
  auditDbConnectionIsValid: false,
  showAuditInSettings: false,
  entityTypes: [],
  linkTypes: [],
  indexDetails: [],
  entityTableLoading: false,
  linkTableLoading: false,
  adSetupFields: {},
  adSetupIsValid: false,
  auth_options: {},
  generatingModels: false,
  generatingErrors: {},
  systemFields: null
}

export default {
  clearError (state) {
    state.error = false
  },

  setError (state, error) {
    state.error = error
  },

  setFormList (state, items) {
    state.forms = items
  },

  appendFormList (state, items) {
    state.forms.next = items.next
    for (const item of items.results) {
      state.forms.results.push(item)
    }
  },
  userPermissions (state, permissions) {
    state.userPermissions = permissions
  },

  setCompositeForm (state, formData) {
    state.compositeForm = formData
  }
}

import SearchService from '../services/search.service'

export default {
  setGlobalSearchInput ({ commit }, globalSearchInput) {
    commit('setGlobalSearchInput', globalSearchInput)
  },
  getSearchResultList: async ({ commit }, { params }) => {
    commit('clearError')
    commit('clearSearchResultList')
    try {
      const items = await SearchService.getSearchResultList(params)
      commit('setSearchResultList', items)
      return true
    } catch (error) {
      commit('setError', {
        code: error.errorCode,
        message: error.message
      })

      return false
    }
  }
}

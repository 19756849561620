import TokenService from '@/modules/auth/services/token.service'
import Configuration from '../../../../Configuration'
import store from '@/store'
import StringFormatUtils from '@/utils/string_formatter'
import { cloneDeep } from 'lodash'

const WebSocketService = {

  DEFAULT_OPTIONS: {
    url: null,
    encodeMessage: true,
    onmessage: null,
    retryDelay: 3000,
    maxNumberOfConnectionRetries: 20
  },

  /**
   * This function gets the hostname required to connect the websocket host
   * @return {string} returns the VUE_APP_WEB_SOCKET_HOST value if defined. Otherwise, returns window.location.hostname
   */
  getHost () {
    return Configuration.value('VUE_APP_WEB_SOCKET_HOST') || window.location.hostname
  },

  /**
   * This function sets the default option if the option is not specified.
   * @param options The options to connect to the websocket. (See 'connect' comments below)
   * @return {*}
   * @private
   */
  _setDefaultOptionsIfOptionIsUndefined (options) {
    const newOptions = cloneDeep(options)
    // Replace all undefined options with the default option
    for (const [key, value] of Object.entries(this.DEFAULT_OPTIONS)) {
      if (newOptions[key] === undefined) {
        newOptions[key] = value
      }
    }
    return newOptions
  },

  /**
   * This function allows connection to the websocket backend based on the options specified. The connection has an
   * automatic timeout and disconnect with the duration set to the 'VUE_APP_INACTIVITY_DURATION_IN_MILLISECONDS' by
   * default.
   *
   * Upon disconnection, the service will automatically retry with a new access token by default. This configuration
   * can be override by overriding the "onclose" event of the websocket connection.
   * @param options The options to connect to the websocket.
   *                The current available options are:
   *                1) url: string -> The websocket URL to connect to
   *                2) encodeMessage: boolean -> By default, the websocket messages will be encoded to prevent unintended
   *                html/javascript execution. You may turn this option off but please be take note of the unintended
   *                consequences.
   *                4) onmessage: function -> The callback function to execute whenever a message is received from the
   *                server.
   * @param numOfConnectionRetries This parameter is used to determine how many times a connection is retried. The
   *                               connection will be retried a max of 20 times before giving up and close
   *                               the connection. (Every retry is 3 secs * 20 times retry is approximately 1 min)
   * @return {Promise<unknown>}
   */
  async connect (options, numOfConnectionRetries = 0) {
    return new Promise((resolve, reject) => {
      const newOptions = this._setDefaultOptionsIfOptionIsUndefined(options)

      if (!newOptions.url) {
        reject(new Error('Websocket url option cannot be null or empty'))
      }

      // Switch between ws or wss protocol when http or https is used respectively
      const protocol = window.location.protocol === 'http:' ? 'ws://' : 'wss://'
      const token = TokenService.getToken()

      const webSocketUrl = `${protocol}${this.getHost()}${newOptions.url}?access_token=${token}`

      const connection = new WebSocket(webSocketUrl)

      connection.onopen = async (event) => {
        // Reset the number of connection retries upon successful connection
        numOfConnectionRetries = 0
        // To add a timer to close the websocket connection after a certain period due to backend expiring the channel
        // session for security purpose.
        // The websocket will then automatically reconnect using the onclose function below.
        setTimeout(() => {
          connection.close()
          // To use the group channel expiry as the websocket validity duration
        }, parseInt(Configuration.value('VUE_APP_WEB_SOCKET_EXPIRY_IN_MILLISECONDS')))
        resolve(connection)
      }

      connection.onmessage = ({ data }) => {
        // Parse the data as a JSON object
        let jsonData = JSON.parse(data)
        // To encode the message by default in case invalid html or javascript tags has been added.
        // This option is configurable.
        if (newOptions.encodeMessage) {
          jsonData = StringFormatUtils.encodeHtmlInDictionaryValue(jsonData)
        }
        // If a callback function is specified, execute the callback function with the received data from the server
        if (newOptions.onmessage instanceof Function) {
          newOptions.onmessage(jsonData)
        } else {
          throw new Error('onmessage option is not a function')
        }
      }

      // To gracefully reconnect in case of disconnection due to token expiry or unexpected client/server disconnection.
      // This event can be override if reconnection is not required.
      connection.onclose = newOptions.onclose || (async (event) => {
        // Every retry is 3 secs * 20 times retry is approximately 1 min of retries
        if (numOfConnectionRetries < newOptions.maxNumberOfConnectionRetries) {
          // Get a new access token before retrying
          await store.dispatch('auth/refreshToken')
          // Wait for an interval before retrying
          await new Promise(resolve => setTimeout(resolve, newOptions.retryDelay))
          // Recursively retry connection
          const newConnection = await this.connect(newOptions, ++numOfConnectionRetries)
          // Save the new successful connection
          await store.commit('auth/setWebSocketConnectionCache', { connection: newConnection, url: newOptions.url })
        } else {
          // If number of retries exceeded limit, remove the connection
          await store.commit('auth/setWebSocketConnectionCache', { connection: null, url: newOptions.url })
        }
      })
    })
  }
}

export default WebSocketService

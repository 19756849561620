export default {
  setUserDashboardPermissions (state, permissions) {
    state.userDashboardPermissions = permissions
  },
  setFields (state, fields) {
    state.formFields = JSON.parse(JSON.stringify(fields))
  },
  appendSelectedQuery (state, { queryId, queryName, outputModels, securityGroups }) {
    state.selectedQueries.push({ queryId: parseInt(queryId), queryName: queryName, outputModels: outputModels, securityGroups: securityGroups })
  },
  removeSelectedQuery (state, { queryId }) {
    // filter out the selected query from the resultant array
    state.selectedQueries = state.selectedQueries.filter(query => parseInt(query.queryId) !== parseInt(queryId))
  },
  updateSelectedQueries (state, newSelectedQueries) {
    state.selectedQueries = JSON.parse(JSON.stringify(newSelectedQueries))
  },
  setStoredOutputModelTypesAndFields (state, item) {
    // appends to existing results
    const key = item.query_id
    state.storedOutputModelsAndFields[key] = item.results
  },
  removeStoredOutputModelTypesAndFields (state, { queryId }) {
    delete state.storedOutputModelsAndFields[queryId]
  },
  toggleIsQueryModalOpenStatus (state) {
    state.isQueryModalOpen = !state.isQueryModalOpen
  },
  setIsQueryModalOpenStatus (state, value) {
    state.isQueryModalOpen = value
  },
  clearError (state) {
    state.error = false
  },
  setError (state, error) {
    state.error = error
  },
  setChartIndexError (state, error) {
    state.chartIndexError.push(error)
  },
  resetChartBuilder (state) {
    state.selectedQueries = []
    state.storedOutputModelsAndFields = {}
    state.isQueryModalOpen = false
    state.oneOutputModelTypeSelected = []
  },
  updatePreviewButtonFalse (state) {
    state.previewButtonStatus = false
  },
  updatePreviewButtonTrue (state) {
    state.previewButtonStatus = true
  },
  toggleIsAddQueryLoading (state) {
    state.isAddQueryLoading = !state.isAddQueryLoading
  },
  setIsAddQueryLoading (state, value) {
    state.isAddQueryLoading = value
  },
  addOneOutputModelTypeSelected (state, outputModel) {
    state.oneOutputModelTypeSelected.push(outputModel)
  },
  clearOneOutputModelTypeSelected (state) {
    state.oneOutputModelTypeSelected = []
  },
  // Index.vue
  setOrderingFields (state, fields) {
    state.orderingFields = fields
  },
  setChartAPIDetails (state, items) {
    state.chartAPIDetails = items
  },
  resetChartAPIDetails (state) {
    state.chartAPIDetails = false
  },
  setViewableCharts (state, items) {
    state.viewableCharts = items
  },
  appendViewableCharts (state, items) {
    if (state.viewableCharts === false) return // do nothing
    for (const chart of items) {
      state.viewableCharts.push(chart)
    }
  },
  resetViewableCharts (state) {
    state.viewableCharts = false
  },
  updateViewableChartsWithSingleChart (state, singleChart) {
    if (state.viewableCharts === false) return // do nothing
    for (let i = 0; i < state.viewableCharts.length; i++) {
      if (state.viewableCharts[i].id === singleChart.id) {
        state.viewableCharts.splice(i, 1, singleChart) // replaces 1 chart at index i; triggers reactivity change
        break
      }
    }
  },
  toggleIsManageState (state) {
    state.indexState.isManage = !state.indexState.isManage
  },
  setIsManageState (state, val) {
    state.indexState.isManage = val
  },
  setActiveTabIdxState (state, val) {
    state.indexState.activeTabIdx = val
  },
  setLastSortText (state, lastSortText) {
    state.indexState.lastSortText = lastSortText
  },
  incrementPageNum (state) {
    state.indexState.params.page++
  },
  resetPageNum (state) {
    state.indexState.params.page = 1
  },
  setOrdering (state, val) {
    state.indexState.params.ordering = val
  },
  setSearch (state, val) {
    state.indexState.params.search = val
  },
  setGroupId (state, id) {
    state.indexState.params.group_id = id
  },
  resetIndexState (state) {
    state.indexState = {
      isManage: false,
      activeTabIdx: 0, // Favourite's tab_idx
      lastSortText: 'Sort',
      params: {
        page: 1,
        perPage: 4,
        ordering: '-created_on,id',
        search: '',
        group_id: -1 // Favourite's group_id
      }
    }
  }
}

import Vue from 'vue'
import ApiService from '@/modules/auth/services/api.service'
import handlers from '@/utils/error_handler'

const TARGET = 'record'

class ModelError extends Error {
  constructor (errorResponse) {
    super(errorResponse.data)
    this.name = this.constructor.name
    this.message = handlers.ErrorHandler(TARGET, errorResponse) // Creates custom error messages
    this.errorCode = errorResponse.status
  }
}

const ModelService = {
  getEntities: async function () {
    try {
      const response = await ApiService.get('/insight/generic_model/null/get_entity_list/')
      return response.data
    } catch (error) {
      throw new ModelError(error.response)
    }
  },
  getLinks: async function () {
    try {
      const response = await ApiService.get('/insight/generic_model/null/get_link_list/')
      return response.data
    } catch (error) {
      throw new ModelError(error.response)
    }
  },
  getModelsFields: async function (model) {
    try {
      const response = await ApiService.options(`/insight/generic_model/${model}/`)
      return response.data.actions.POST
    } catch (error) {
      throw new ModelError(error.response)
    }
  },
  getModelCreateFields: async function (model) {
    try {
      const response = await ApiService.get(`/insight/generic_model/${model}/create_form/`)
      return response.data
    } catch (error) {
      throw new ModelError(error.response)
    }
  },
  getRecords: async function (model, data) {
    try {
      const response = await ApiService.get(`/insight/generic_model/${model}/`, data)
      return response.data
    } catch (error) {
      throw new ModelError(error.response)
    }
  },
  createRecord: async function (model, data) {
    try {
      const response = await ApiService.post(`/insight/generic_model/${model}/`, data)
      return response.data
    } catch (error) {
      throw new ModelError(error.response)
    }
  },
  getRecord: async function (model, id) {
    try {
      const response = await ApiService.get(`/insight/generic_model/${model}/${id}/`)
      return response.data
    } catch (error) {
      throw new ModelError(error.response)
    }
  },
  getRecordForDynamicForm: async function (modelId, formId) {
    try {
      const response = await ApiService.get(`/dynamic_forms/${formId}/?model_id=${modelId}`)
      return response.data
    } catch (error) {
      throw new ModelError(error.response)
    }
  },
  updateRecord: async function (model, id, data) {
    try {
      const response = await ApiService.put(`/insight/generic_model/${model}/${id}/`, data)
      return response.data
    } catch (error) {
      throw new ModelError(error.response)
    }
  },
  deleteRecord: async function (model, id) {
    try {
      const response = await ApiService.delete(`/insight/generic_model/${model}/${id}/`)
      return response.status
    } catch (error) {
      throw new ModelError(error.response)
    }
  },
  getRecordConnections: async function (entity, id) {
    try {
      const response = await ApiService.get(`/insight/generic_model/${entity}/${id}/get_entity_connections/`)
      return response.data
    } catch (error) {
      throw new ModelError(error.response)
    }
  },
  getRelevantLinks: async function (entity) {
    try {
      const response = await ApiService.get(`/insight/generic_model/${entity}/get_link_list/`)
      return response.data
    } catch (error) {
      throw new ModelError(error.response)
    }
  },
  deleteAttachmentByFieldName: async function (model, id, fieldName) {
    try {
      const response = await ApiService.delete(`/insight/generic_model/${model}/${id}/delete_attachment/?field=${fieldName}`)
      return response.status
    } catch (error) {
      throw new ModelError(error.response)
    }
  },
  getAttachment: async function (model, id, field) {
    try {
      const response = await ApiService.customRequest({
        method: 'get',
        url: `/insight/generic_model/${model}/${id}/get_attachment/?field=${field}`,
        responseType: 'blob'
      })
      return response
    } catch (error) {
      throw new ModelError(error.response)
    }
  },
  getIconImage: async function (model) {
    try {
      const response = await ApiService.customRequest({
        method: 'get',
        url: '/insight/generic_model/' + model + '/get_image/',
        responseType: 'blob'
      })
      return response
    } catch (error) {
      throw new ModelError(error.response)
    }
  },
  getAdvancedSearchFields: async function (model) {
    try {
      const response = await ApiService.get(`/insight/generic_model/${model}/create_query_form/`)

      const fields = {}
      // TODO: eslint 6.8.0 false-positive bug on no-unused-vars. To be resolved when it is fixed in newer version
      // eslint-disable-next-line no-unused-vars
      for (const field of response.data.fields) {
        const operators = []
        // TODO: eslint 6.8.0 false-positive bug on no-unused-vars. To be resolved when it is fixed in newer version
        // eslint-disable-next-line no-unused-vars
        for (const operator of field.meta.operators) {
          operators.push({
            value: operator.value,
            text: operator.label,
            type: operator.type,
            meta: operator.meta
          })
        }

        // TODO Probably should move into action
        let fieldType
        switch (field.type) {
          case Vue.prototype.$INSIGHT('INPUT_TYPES').CALCULATED_NUMBER:
            fieldType = Vue.prototype.$INSIGHT('INPUT_TYPES').NUMBER
            break
          case Vue.prototype.$INSIGHT('INPUT_TYPES').CALCULATED_DATE:
            fieldType = Vue.prototype.$INSIGHT('INPUT_TYPES').DATE
            break
          case Vue.prototype.$INSIGHT('INPUT_TYPES').CALCULATED_DATE_PART:
            fieldType = (field.meta.choices) ? Vue.prototype.$INSIGHT('INPUT_TYPES').SELECT : Vue.prototype.$INSIGHT('INPUT_TYPES').NUMBER
            break
          default:
            fieldType = field.type
        }

        fields[field.name] = {
          value: field.name,
          text: field.label,
          type: fieldType,
          meta: field.meta,
          // TODO Either pop out operators from meta or dont need operators
          operators
        }
      }

      return fields
    } catch (error) {
      throw new ModelError(error.response)
    }
  },
  getQueryFilterFields: async function (model) {
    try {
      const response = await ApiService.get(`/insight/generic_model/${model}/create_query_form/`)

      const fields = {}
      for (const field of response.data.fields) {
        const operators = []
        for (const operator of field.meta.new_operators) {
          operators.push({
            value: operator.value,
            text: operator.label,
            type: operator.type,
            meta: operator.meta
          })
        }

        fields[field.name] = {
          value: field.name,
          text: field.label,
          type: field.type,
          meta: field.meta,
          operators
        }
      }

      return fields
    } catch (error) {
      throw new ModelError(error.response)
    }
  },
  checkDiscriminators: async function (model, data) {
    try {
      const response = await ApiService.get(`/insight/generic_model/${model}/check_discriminators/`, data)
      return response.data
    } catch (error) {
      throw new ModelError(error.response)
    }
  },
  getDynamicForms: async function (model) {
    try {
      const response = await ApiService.get(`/insight/generic_model/${model}/get_dynamic_forms/`)
      return response.data
    } catch (error) {
      throw new ModelError(error.response)
    }
  },
  getSegregatedEntities: async function (model, id, linkId, targetType, page) {
    if (!page) {
      page = 1
    }
    try {
      const response = await ApiService.get(`/insight/generic_model/${model}/${id}/segregate_entities/?page=${page}&link_type=${linkId}&target_type=${targetType}`)
      return response.data
    } catch (error) {
      throw new ModelError(error.response)
    }
  },

  getSegregatedLinks: async function (model, id, targetId, linkTypeId, getAll, page) {
    if (!page) {
      page = 1
    }
    try {
      const response = await ApiService.get(`/insight/generic_model/${model}/${id}/segregated_links/?page=${page}&target=${targetId}&get_all=${getAll}&link_type=${linkTypeId}`)
      return response.data
    } catch (error) {
      throw new ModelError(error.response)
    }
  },

  getIndexDisplay: async function (model = null, displayIcon = false, displayFields = false) {
    try {
      const response = await ApiService.get(`/insight/generic_model/${model}/get_index_display/?display_icon=${displayIcon}&display_fields=${displayFields}`)
      return response.data
    } catch (error) {
      throw new ModelError(error.response)
    }
  },
  getBlacklistedFileExt: async function () {
    try {
      const response = await ApiService.get('/insight/generic_model/null/get_blacklisted_file_ext/')
      return response.data
    } catch (error) {
      throw new ModelError(error.response)
    }
  },
  getMaxFileSize: async function () {
    try {
      const response = await ApiService.get('/insight/generic_model/null/get_max_file_size/')
      return response.data
    } catch (error) {
      throw new ModelError(error.response)
    }
  }
}

export { ModelService, ModelError }
export default ModelService

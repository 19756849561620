import ApiService from '@/modules/auth/services/api.service'
import { ConfigurationError } from '../errors/configuration_error'

const DatabaseIndexService = {
  getIndexDetailsFromInsightIndexAPI: async function () {
    try {
      const response = await ApiService.get('/insight/index/')
      return response.data
    } catch (error) {
      throw new ConfigurationError(error.response.status, error.response.data)
    }
  }
}

export { DatabaseIndexService, ConfigurationError }
export default DatabaseIndexService

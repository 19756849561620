import { cloneDeep } from 'lodash'
import Vue from 'vue'

/**
 * @public
 * Format Help Text from backend as an Array of strings
 * @param {String} rawText - Help Text from backend as an array encapsulated in a string
 * @returns {Array}
 */
function formatHelpText (rawText) {
  const helpTextForPasswordString = rawText.replace(/[^0-9a-zA-Z,. ]/g, '')
  return helpTextForPasswordString.split(', ')
}

function encodeHtmlInDictionaryValue (dict) {
  const encodedDict = cloneDeep(dict)

  for (const [key, value] of Object.entries(encodedDict)) {
    if (encodedDict[key] instanceof Object) {
      encodedDict[key] = encodeHtmlInDictionaryValue(encodedDict[key])
    } else {
      encodedDict[key] = Vue.prototype.$sanitize('' + value, {
        allowedTags: [],
        disallowedTagsMode: 'escape'
      })
    }
  }

  return encodedDict
}

const StringFormatUtils = {
  formatHelpText,
  encodeHtmlInDictionaryValue
}
export default StringFormatUtils

import actions from './actions'
import getters from './getters'
import mutations from './mutations'
import state from './state'
import queries from './queries'
import notification from './notification'

const namespaced = true

export default {
  namespaced,
  state,
  getters,
  actions,
  mutations,
  modules: {
    queries,
    notification
  }
}

import ToastMessageHelper from '@/utils/toast_message_helper'
import INSIGHT from '@/constants/INSIGHT.json'

/**
 * ToastMessage is an object to manage the toast message
 * config = { vueInstance, prefixName, name, postfixText, textMessage }
 */
const ToastMessage = {
  showCreatedSuccess (config) {
    const variant = INSIGHT.TOAST_MESSAGE.VARIANT.SUCCESS
    const methodKey = INSIGHT.TOAST_MESSAGE.METHOD_KEY.CREATED
    ToastMessageHelper.showToastMessage({ variant, methodKey, ...config })
  },
  showEditedSuccess (config) {
    const variant = INSIGHT.TOAST_MESSAGE.VARIANT.SUCCESS
    const methodKey = INSIGHT.TOAST_MESSAGE.METHOD_KEY.EDITED
    ToastMessageHelper.showToastMessage({ variant, methodKey, ...config })
  },
  showRegisteredSuccess (config) {
    const variant = INSIGHT.TOAST_MESSAGE.VARIANT.SUCCESS
    const methodKey = INSIGHT.TOAST_MESSAGE.METHOD_KEY.REGISTERED
    ToastMessageHelper.showToastMessage({ variant, methodKey, ...config })
  },
  showVerifiedSuccess (config) {
    const variant = INSIGHT.TOAST_MESSAGE.VARIANT.SUCCESS
    const methodKey = INSIGHT.TOAST_MESSAGE.METHOD_KEY.VERIFIED
    ToastMessageHelper.showToastMessage({ variant, methodKey, ...config })
  },
  showDeletedSuccess (config) {
    const variant = INSIGHT.TOAST_MESSAGE.VARIANT.SUCCESS
    const methodKey = INSIGHT.TOAST_MESSAGE.METHOD_KEY.DELETED
    ToastMessageHelper.showToastMessage({ variant, methodKey, ...config })
  },
  showDeletedSuccessWithPostfixText (config) {
    const variant = INSIGHT.TOAST_MESSAGE.VARIANT.SUCCESS
    const methodKey = INSIGHT.TOAST_MESSAGE.METHOD_KEY.DELETED
    ToastMessageHelper.showToastMessage({ variant, methodKey, ...config })
  },
  showSelectedDefault (config) {
    const variant = INSIGHT.TOAST_MESSAGE.VARIANT.DEFAULT
    const methodKey = INSIGHT.TOAST_MESSAGE.METHOD_KEY.SELECTED
    ToastMessageHelper.showToastMessage({ variant, methodKey, ...config })
  },
  showNoAssignedForm (config) {
    const variant = INSIGHT.TOAST_MESSAGE.VARIANT.DANGER
    const methodKey = INSIGHT.TOAST_MESSAGE.METHOD_KEY.NO_ASSIGNED_FORM
    ToastMessageHelper.showToastMessage({ variant, methodKey, ...config })
  },
  showErrorDefault (config) {
    const variant = INSIGHT.TOAST_MESSAGE.VARIANT.DANGER
    const methodKey = INSIGHT.TOAST_MESSAGE.METHOD_KEY.GET
    ToastMessageHelper.showToastMessage({ variant, methodKey, ...config })
  },
  showWarningDefault (config) {
    const variant = INSIGHT.TOAST_MESSAGE.VARIANT.WARNING
    const methodKey = INSIGHT.TOAST_MESSAGE.METHOD_KEY.GET
    ToastMessageHelper.showToastMessage({ variant, methodKey, ...config })
  }
}

export default ToastMessage

const routes = [
  {
    path: '/forms/',
    name: 'forms',
    component: () => import(/* webpackChunkName: "forms" */ './views/Index.vue'),
    meta: {
      layout: 'DashboardLayout',
      activeMenus: ['forms']
    }
  },
  {
    path: '/forms/create',
    name: 'create-form',
    component: () => import(/* webpackChunkName: "forms" */ './views/FormBuilder.vue'),
    meta: {
      layout: 'DashboardLayout',
      activeMenus: ['forms'],
      mode: 'create-form'
    }
  },
  {
    path: '/forms/:id',
    name: 'form-builder',
    component: () => import(/* webpackChunkName: "forms" */ './views/FormBuilder.vue'),
    meta: {
      layout: 'DashboardLayout',
      activeMenus: ['forms'],
      mode: 'edit'
    }
  },
  {
    path: '/forms/composite/create',
    name: 'create-composite-form',
    component: () => import(/* webpackChunkName: "forms" */ './views/CreateEditCompositeForm.vue'),
    meta: {
      layout: 'DashboardLayout',
      activeMenus: ['forms'],
      mode: 'create-form',
      action: 'create-composite-form',
      noScroll: true
    }
  },
  {
    path: '/forms/composite/:id',
    name: 'edit-composite-form',
    component: () => import(/* webpackChunkName: "forms" */ './views/CreateEditCompositeForm.vue'),
    meta: {
      layout: 'DashboardLayout',
      activeMenus: ['forms'],
      mode: 'edit',
      action: 'edit-composite-form',
      noScroll: true
    }
  }
]

export default routes

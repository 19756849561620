<template>
  <div
    :class="{
      layout: true,
      navClosed,
      navCollapsed,
      navHover,
      dashboard: true,
      noScroll:
        $route &&
        $route.meta &&
        ($route.meta.noScroll !== undefined || $route.meta.noScroll === true)
    }"
    id="dashboard-layout"
  >
    <nav
      class="nav"
      :class="{ navClosed }"
      @mouseenter="navHover = true"
      @mouseleave="navHover = false"
    >
      <div class="home">
        <div class="logo">
          <va-logo class="va-logo" />
        </div>
        VA Insight
      </div>

      <portal-target name="before-nav"></portal-target>

      <nav-menu :items="navItems" />

      <portal-target name="after-nav"></portal-target>
      <a
        :href="releaseNotes"
        target="_blank"
        :aria-label="$LOCAL('ReleaseNotes')"
        class="container footer"
        id="copyright"
      >
        <div class="icon">
          <la-copyright />
        </div>
        <div class="name">VA Insight Software</div>
        <b-tooltip target="copyright" triggers="hover focus">
          Version <strong>{{ userData.version_number }}</strong>
        </b-tooltip>
      </a>
    </nav>
    <main
      class="main"
      ref="mainContent"
      tabindex="0"
      aria-label="main dashboard"
    >
      <div class="top-bar">
        <div
          class="menu-toggle"
          id="toggle-navigator"
          v-b-tooltip:dashboard-layout.html="
            'Turn ' +
              (navCollapsed === true ? 'off' : 'on') +
              '<br>sidebar<br>collapsing'
          "
          @click="toggleNav"
        >
          <button
            class="button-icon"
            ref="hamburger"
            aria-label="toggle navigation menu"
          >
            <la-bars />
          </button>
        </div>
        <portal-target class="portal" name="top-bar"></portal-target>
        <nav-search-bar class="nav-search-bar" :hasAdvancedSearch="false" />
        <span class="unread-message-count">
          {{ this.notificationsObject.unread_messages_count }}
        </span>
        <b-dropdown class="bell-icon-container" size="lg" no-caret right ref="notificationTab">
          <b-dropdown-header class="notifications">{{ this.$INSIGHT('NOTIFICATION').NOTIFICATION_HEADER }}
          </b-dropdown-header>
          <b-dropdown-header v-if="this.haveNotifications" class="mark-all-as-read-header pr-3"> <a @click="markAllAsRead()">
            {{ this.$INSIGHT('NOTIFICATION').MARK_ALL_AS_READ }}</a></b-dropdown-header>
          <template v-slot:button-content>
            <bell-icon class="bell-icon" />
          </template>
          <div class="infinite-scrolling-div" v-if="haveNotifications">
            <div
              class="notifications-cards"
              v-for="(category, key) in modifiedNotificationsObject"
              :key="key">
              <div v-if="category.notifications.length > 0">
                <b-dropdown-header>{{category.label}}</b-dropdown-header>
                <b-dropdown-item
                  v-for="notification in category.notifications"
                  :key="notification.id"
                  class="b-dropdown-item"
                >
                <b-card class="notifications-card" @click.stop="hideNotificationTab">
                  <router-link v-if="notification.can_redirect" :to="{path: `/${notification.type}/${notification.model}/${notification.unique_id}`}">
                    <div class="text-message">
                      <div class="title-and-date">
                        <p class="title mb-2">{{ notification.title }}</p>
                        <p class="time mb-2">{{ notification.time }}</p>
                      </div>
                      <p class="message mb-0">{{ notification.message }}</p>
                    </div>
                  </router-link>
                  <a v-else href="#" @click.stop>
                    <div class="text-message">
                      <div class="title-and-date">
                        <p class="title mb-2">{{ notification.title }}</p>
                        <p class="time mb-2">{{ notification.time }}</p>
                      </div>
                      <p class="message mb-0">{{ notification.message }}</p>
                    </div>
                  </a>
                <div class="card-button" @click.stop="markNotificationAsReadOrUnread(notification)">
                  <div class="mark-as-read-unread-container"
                      v-b-tooltip.hover.left
                       :title="notification.is_unread? $INSIGHT('NOTIFICATION').MARK_AS_READ: $INSIGHT('NOTIFICATION').MARK_AS_UNREAD">
                    <DotSolid v-show="notification.is_unread" class="dot-solid-icon"/>
                  </div>
                </div>
                </b-card>
              </b-dropdown-item>
                <b-dropdown-divider />
              </div>
            </div>
            <div
              v-if="this.haveNotifications"
              v-observe-visibility="handleScrolledToBottom" style="min-height: 0.1rem"
            ></div>
          </div>
          <div v-else>
            <div class="no-notification"><no-notification-graphic/></div>
            <div class="no-notification-msg mt-4 pt-2 mx-3">
              <p class="text-message">{{ this.$INSIGHT('NOTIFICATION').NO_NOTIFICATION_MESSAGE }}</p>
            </div>
          </div>
        </b-dropdown>
        <b-dropdown
          v-data-cy="'user-actions'"
          v-if="userName !== false"
          size="lg"
          variant="secondary"
          no-caret
          right
          class="user-actions-dropdown"
        >
          <template id="avatar" v-slot:button-content>
            <div class="btn-circle shadow">
              <span class="account-char" aria-label="dropdown">{{
                userFirstCharacter()
              }}</span>
            </div>
          </template>
          <b-dropdown-item id="greeting-message" disabled>
            <div>Hi, {{ userName() }}</div>
          </b-dropdown-item>
          <b-dropdown-item
            id="change-password-link"
            @click="$router.push({ path: '/change-password' })"
            v-if="userData.authentication_method !== 'AD'"
          >
            Change Password
          </b-dropdown-item>
          <b-dropdown-item
            id="logout-link"
            @click="$router.push({ path: '/logout' })"
            >Logout</b-dropdown-item
          >
        </b-dropdown>
      </div>

      <div class="body">
        <NoUserGroupModal
          :showModal="showNoUserGroupModal"
          :modalMessage="$LOCAL('NO_USER_GROUP_MODEL').MODEL_MSG"
          :modalTitle="$LOCAL('NO_USER_GROUP_MODEL').MODEL_TITLE"
        />
        <IdleModal v-if="isIdle" />
        <slot />
      </div>
    </main>
  </div>
</template>

<script>
import NavMenu from '@/components/NavMenu'
import LaBars from '@/assets/la-bars.svg'
import VaLogo from '@/assets/va-logo.svg'
import LaHome from '@/assets/la-home.svg'
import LaCog from '@/assets/la-cog.svg'
import SearchIcon from '@/assets/search-icon.svg'
import IconArchive from '@/assets/archive-solid.svg'
import IconEntites from '@/assets/entity.svg'
import IconLinks from '@/assets/link.svg'
import LaQuestionCircle from '@/assets/la-question-circle.svg'
import BellIcon from '@/assets/bell-solid.svg'
import IconForm from '@/assets/forms.svg'
import LaGraph from '@/assets/la-graph.svg'
import LaCopyright from '@/assets/la-copyright-solid.svg'
import ReleaseNotes from '@/assets/release-notes/releasenotes.pdf'
import { mapActions, mapState } from 'vuex'
import IdleModal from '../components/IdleModal.vue'
import NoUserGroupModal from '../components/NoUserGroupModal'
import NavSearchBar from '@/modules/insight/components/search_sort/NavSearchBar'
import { BCard } from 'bootstrap-vue'
import DotSolid from '@/assets/dot-solid.svg'
import NoNotificationGraphic from '@/assets/no_notification_logo.svg'
import InsightConstants from '@/constants/INSIGHT.json'

export default {
  name: 'DashboardLayout',
  components: {
    NavMenu,
    LaBars,
    VaLogo,
    IdleModal,
    NoUserGroupModal,
    LaCopyright,
    NavSearchBar,
    BellIcon,
    BCard,
    DotSolid,
    NoNotificationGraphic
  },
  data: () => ({
    showNoUserGroupModal: false,
    navCollapsed: false,
    navHover: false,
    releaseNotes: ReleaseNotes,
    notificationsObject: {},
    modifiedNotificationsObject: {
      todayNotificationsObject: { notifications: [], label: InsightConstants.NOTIFICATION.NEW },
      yesterdayNotificationsObject: { notifications: [], label: InsightConstants.NOTIFICATION.EARLIER },
      olderNotificationsObject: { notifications: [], label: InsightConstants.NOTIFICATION.OLDER }
    },
    isAppend: true,
    webSocketUrl: '/ws/notification/'
  }),
  computed: {
    ...mapState('auth', ['userDataError', 'userData']),
    ...mapState('forms', { formPermissions: 'userPermissions' }),
    ...mapState('config/insight', ['showAuditInSettings']),
    navClosed () {
      if (this.navCollapsed === false || this.navHover === true) {
        return false
      }
      return true
    },
    navItems () {
      const navAdditional = []
      if (this.userData.is_superuser === true && !this.userDataError) {
        if (this.showAuditInSettings) {
          navAdditional.push({
            icon: IconArchive,
            name: 'Audit',
            router: {
              name: 'audit'
            }
          })
        }
        navAdditional.push({
          icon: LaCog,
          name: 'Settings',
          router: {
            name: 'Settings'
          }
        })
      }
      return [
        {
          icon: LaHome,
          name: 'Home',
          router: {
            name: 'home'
          }
        },
        {
          icon: SearchIcon,
          name: 'Search',
          router: {
            name: 'search'
          }
        },
        {
          icon: IconForm,
          name: 'Forms',
          router: {
            name: 'forms'
          }
        },
        {
          icon: LaGraph,
          name: 'Dashboard',
          router: {
            name: 'dashboard'
          }
        },
        {
          icon: IconEntites,
          name: 'Entities',
          router: {
            name: 'entities'
          }
        },
        {
          icon: IconLinks,
          name: 'Links',
          router: {
            name: 'links'
          }
        },
        {
          icon: LaQuestionCircle,
          name: 'Queries',
          router: {
            name: 'queries'
          }
        },
        ...navAdditional
      ]
    },
    isIdle () {
      return this.$store.state.idleVue.isIdle
    },
    haveNotifications () {
      return this.modifiedNotificationsObject.todayNotificationsObject.notifications.length ||
        this.modifiedNotificationsObject.yesterdayNotificationsObject.notifications.length ||
        this.modifiedNotificationsObject.olderNotificationsObject.notifications.length
    }
  },
  methods: {
    ...mapActions('auth', ['retrieveUserData', 'openWebSocketConnection', 'closeWebSocketConnection'
    ]),
    ...mapActions('insight/notification', [
      'getNotification',
      'markNotificationAsRead',
      'markNotificationAsUnread',
      'markAllNotificationAsRead'
    ]),
    userFirstCharacter () {
      if (this.userData.username && !this.userDataError) {
        return this.userData.username.charAt(0).toUpperCase()
      }
      return ''
    },
    userName () {
      if (this.userData.username && !this.userDataError) {
        return this.userFirstCharacter() + this.userData.username.slice(1)
      }
      return ''
    },
    toggleNav () {
      this.navCollapsed = !this.navCollapsed
      this.$refs.hamburger.blur()
    },
    categorisingNotifications (array) {
      array.forEach(element => {
        if (element.category === InsightConstants.NOTIFICATION.NEW.toUpperCase()) {
          this.modifiedNotificationsObject.todayNotificationsObject.notifications.push(
            element
          )
        } else if (element.category === InsightConstants.NOTIFICATION.EARLIER.toUpperCase()) {
          this.modifiedNotificationsObject.yesterdayNotificationsObject.notifications.push(
            element
          )
        } else if (element.category === InsightConstants.NOTIFICATION.OLDER.toUpperCase()) {
          this.modifiedNotificationsObject.olderNotificationsObject.notifications.push(
            element
          )
        }
      })
    },
    async markNotificationAsReadOrUnread (notification) {
      let response
      if (notification.is_unread) {
        response = await this.markNotificationAsRead(notification.id)
      } else {
        response = await this.markNotificationAsUnread(notification.id)
      }
      this.notificationsObject.unread_messages_count = response.unread_messages_count
      if (response !== false) {
        notification.is_unread = !notification.is_unread
      }
    },
    async markAllAsRead () {
      const response = await this.markAllNotificationAsRead()
      if (response !== false) {
        this.notificationsObject.unread_messages_count = response.unread_messages_count
        for (const notificationCategory in this.modifiedNotificationsObject) {
          this.modifiedNotificationsObject[
            notificationCategory
          ].notifications.forEach(notification => {
            notification.is_unread = false
          })
        }
      }
    },
    async handleScrolledToBottom (isVisible) {
      if (!isVisible || !this.isAppend) {
        return
      }
      let response = false

      const lastIndexOfNotificationsObject =
        this.notificationsObject.results.length - 1
      const lastNotificationId = this.notificationsObject.results[
        lastIndexOfNotificationsObject
      ].id

      response = await this.getNotification({ id: lastNotificationId })

      if (response.results.length > 0) {
        this.categorisingNotifications(response.results)
        this.notificationsObject.results = response.results // resetting the notificationsObject results
      } else {
        this.isAppend = false
      }
    },
    hideNotificationTab () {
      this.$refs.notificationTab.hide()
    },
    onmessage (event) {
      // pushes notification to the front of the array
      this.modifiedNotificationsObject.todayNotificationsObject.notifications.unshift(event.message.notification)
      this.notificationsObject.unread_messages_count = parseInt(event.message.unread_messages_count)
    }
  },
  async mounted () {
    this.retrieveUserData()
    // fetching first page of notifications including relevant details
    this.notificationsObject = await this.getNotification()
    // categorising the notifications via date
    this.categorisingNotifications(this.notificationsObject.results)

    // Opening a websocket connection to receive new notification and unread messages count in real time by calling action openWebSocketConnection
    await this.openWebSocketConnection(
      {
        url: this.webSocketUrl,
        onmessage: this.onmessage,
        encodeMessage: false
      }
    )
    if (!this.userData?.is_superuser && this.userData?.user_groups?.length === 0) {
      this.showNoUserGroupModal = true
    }
  },
  async destroyed () {
  // Gracefully close the websocket connection to prevent further automatic reconnection when connection expires
    await this.closeWebSocketConnection({ url: this.webSocketUrl })
  }
}
</script>

<style lang="scss">
$sidebarWidth: 190px;
$sidebarCollapsed: 58px;
$transitionAnimation: all 250ms ease-in-out;
.layout {
  &.noScroll {
    overflow: hidden;
    max-height: 100vh;
  }
  &.navClosed {
    .nav {
      /deep/ .menu .title {
        height: 0;
      }
    }
  }
  .overlay {
    position: absolute;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto;
    height: 100%;
    width: 100%;
  }
  > .nav {
    position: fixed;
    left: 0;
    top: 0;
    bottom: 0;
    background: #313947;
    width: $sidebarWidth;
    transition: all 200ms ease-in;
    color: #fff;
    overflow: hidden;
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    &.navClosed {
      width: $sidebarCollapsed;
    }
    .home {
      color: #fff;
      padding: 15px 9px;
      align-self: flex-start;
      align-items: center;
      transition: all 200ms ease-in;
      display: flex;
      width: $sidebarWidth;
      font-size: 25px;
      font-weight: 400;
      line-height: 0.9;
      &:hover {
        text-decoration: none;
      }
      .logo {
        display: inline-block;
        height: 30px;
        width: 40px;
        margin: 1px 10px 1px 0;
        .va-logo {
          transition: all 200ms ease-in;
          height: 30px;
          width: 30px;
          display: block;
          margin: auto;
        }
      }
    }
  }
  > .main {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    transition: all 200ms ease-in;
    margin-left: $sidebarWidth;
    position: relative;
    left: 0;
    &:focus {
      outline: none;
    }
  }
  &.navCollapsed {
    .main {
      margin-left: $sidebarCollapsed;
    }
    &.navHover {
      .nav {
        z-index: 2; //set z-index to be higher than .main to overlay the width expansion
        width: $sidebarWidth;
      }
      .main {
        z-index: 1;
      }
    }
  }
  .top-bar {
    background: #fff;
    padding: 15px 25px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .portal {
      flex-grow: 1;
      display: flex;
    }
    .nav-search-bar {
      flex-grow: 3;
      display: flex;
    }
    .menu-toggle {
      margin: 6px 0 6px 0;
      width: 20px;
      height: 20px;
      cursor: pointer;
      .button-icon {
        border: none;
        background-color: #fff;
        padding: 0;
        margin: 0;
        svg {
          display: block;
          margin: auto;
        }
      }
    }
    .body {
      flex-grow: 1;
      background: #f7f7f7;
      padding: 28px;
    }
    .unread-message-count {
      z-index: 5;
      background-color: red;
      color: white;
      border-radius: 50%;
      position: relative;
      left: 60px;
      bottom: 15px;
      font-weight: bolder;
      width: 20px;
    }
    .bell-icon-container {
      margin-right: 20px;
      margin-left: 20px;
      .b-dropdown-item {
        a {
          background-color: white;
        }
      }
      .notifications {
        font-weight: bold;
      }
      .notifications-card {
        a {
          display: flex;
          flex-direction: row;
          white-space: pre-wrap;      /* CSS3 */
          white-space: -moz-pre-wrap; /* Firefox */
          white-space: -pre-wrap;     /* Opera <7 */
          white-space: -o-pre-wrap;   /* Opera 7 */
        }
        .card-body {
          display: flex;
          flex-direction: row;
          .avatar {
            display: flex;
            align-items: center;
            margin-right: 30px;
            .avatar-icon {
              width: 45px;
              height: 45px;
              border-radius: 50%;
            }
            flex-grow: 0;
            padding-right: 10px;
          }
          .text-message {
            display: flex;
            flex-grow: 3;
            flex-direction: column;
            flex-wrap: wrap;
            .title-and-date {
              display: flex;
              flex-direction: row;
              .title {
                padding-left: 0px;
                font-size: 1rem;
                line-height: 1.35rem;
              }
              .time {
                min-width: 60px;
                color: grey;
                font-size: 0.7rem;
                line-height: 1.35rem;
              }
            }
            .message {
              font-size: 0.85rem;
              line-height: 1.2rem;
            }
          }
          .card-button {
            display: flex;
            flex-grow: 1;
            flex-direction: column;
            justify-content: flex-start;
            align-items: flex-end;
            .mark-as-read-unread-container {
              background-color : #b8b8bf;
              border-radius : 50%;
              opacity: 0.5;
              width: 25px;
              height: 25px;
            }
          }
        }
      }
      ul {
        min-width: 30vw;
        .infinite-scrolling-div {
          height: 70vh;
          overflow-y: scroll;
        }
      }
      .bell-icon {
        fill: white;
        width: 25px;
        height: 25px;
        margin-left: -12px;
        margin-top: -15px;
      }
      .notifications {
        display: inline-flex;
        flex-direction: row;
        justify-content: flex-start;
        min-width: 50%;
        .dropdown-header {
          font-size: 1.3rem;
          color: black;
        }
      }
      .mark-all-as-read-header {
        display: inline-flex;
        flex-direction: row;
        justify-content: flex-end;
        min-width: 50%;
        .dropdown-header {
          font-size: 0.8rem;
        }
      }
      .no-notification {
        display: flex;
        flex-direction: row;
        justify-content: center;
      }
      .no-notification-msg {
        display: flex;
        flex-direction: row;
        justify-content: center;
        text-align: center;
      }
    }
  }
  .bell-icon-container, .user-actions-dropdown {
    .dropdown-toggle {
      &.btn-secondary {
        border-radius: 50%;
        width: 35px;
        height: 35px;
        background-color: #313947;
      }
    }
  }
  .btn-circle {
    position: absolute;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
  }
  .body {
    flex-grow: 1;
    background: #f7f7f7;
    padding: 28px;
    border-top: 1px solid #e0e0e0;
  }
  .account-char {
    font-size: 23px;
    color: white;
  }
}
.container.footer {
  position: absolute;
  bottom: 0;
  display: flex;
  margin-bottom: 15px;
  align-items: center;
  width: $sidebarWidth;
  .icon {
    width: 34px;
    text-align: center;
    padding-left: 5px;
    svg {
      fill: #6c757d;
      width: 24px;
      height: auto;
      transition: $transitionAnimation;
    }
  }
  .name {
    color: #6c757d;
    transition: $transitionAnimation;
    flex-grow: 1;
    text-align: left;
    padding-left: 15px;
    display: block;
    width: 100%;
    font-size: 12.5px;
  }
  &:hover {
    .icon {
      svg {
        fill: $orange;
      }
    }
    .name {
      text-decoration: none;
      color: $orange;
      line-height: 1;
    }
  }
}

@media only screen and (max-width: 1024px) {
  .layout .top-bar .bell-icon-container ul {
    width: 40vw;
  }
}

@media only screen and (max-width: 768px) {
  .layout .top-bar .bell-icon-container ul {
    width: 50vw;
  }
}
</style>

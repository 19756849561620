import Vue from 'vue'
import Router from 'vue-router'
import Home from './views/Home.vue'
import TokenService from '@/modules/auth/services/token.service'
import UserService from '@/modules/auth/services/user.service'
import ShowWizardService from '@/modules/config/services/show_wizard.service'
import { routes as moduleRoutesTemp } from '@/modules'

Vue.use(Router)

const moduleRoutes = moduleRoutesTemp || []

const routes = new Router({
  routes: [
    {
      path: '/',
      name: 'home',
      component: Home,
      meta: {
        layout: 'DashboardLayout',
        triggerShowWizard: true
      }
    },
    ...moduleRoutes
  ]
})

const DEFAULT_TITLE = 'VA Insight'
// afterEach is used instead of beforeEach
// because authentication checks go in beforeEach
routes.afterEach((to, from) => {
  Vue.nextTick(() => {
    document.title = (to.meta && to.meta.title) || DEFAULT_TITLE
  })
})

routes.beforeEach(async (to, from, next) => {
  // Route unknown path to common 404 page of reverse proxy
  if (to.name === undefined || to.name === null) {
    window.location.assign('/error/404.html')
    return next(false)
  }
  const isPublic = to.matched.some(record => record.meta.public)
  const onlyWhenLoggedOut = to.matched.some(
    record => record.meta.onlyWhenLoggedOut
  )
  const loggedIn = !!TokenService.getToken()
  const isShowWizard = to.matched.some(record => record.meta.triggerShowWizard)

  if (!isPublic && !loggedIn) {
    return next({
      name: 'login',
      query: { redirect: to.fullPath } // Store the full path to redirect the user to after login
    })
  }

  // Do not allow user to visit login page or register page if they are logged in
  if (loggedIn && onlyWhenLoggedOut && to.name === 'login') {
    return next({ name: 'home' })
  }

  if (loggedIn && isShowWizard) {
    // ShowWizardService is called after OTP submission as the it is logged in and
    // next route is the home page, which has meta attribute triggerShowWizard
    const showWizard = await ShowWizardService.get()
    if (showWizard) {
      const user = await UserService.retrieveUserData()
      if (!user.is_superuser) {
        return next({ name: 'logout', params: { setupRequired: true } })
      }
      return next({ name: 'Wizard Index' })
    }
  }

  // TODO INTEGRATION test non admin cannot access any settings page or wizard page
  // TODO INTEGRATION test admin can access every settings page and wizard page
  // https://lmiller1990.github.io/vue-testing-handbook/vue-router.html#writing-the-test
  // user router.push
  // Do not allow non admin users to visit any settings page or wizard page
  if (
    loggedIn &&
    (to.name.indexOf('Settings') !== -1 || to.name.indexOf('Wizard') !== -1)
  ) {
    const user = await UserService.retrieveUserData()
    if (!user.is_superuser) {
      window.location.assign('/error/403.html')
      return next(false)
    }
  }

  // Check to see if user has permission to access resource
  // TODO: Review whether are there any use cases where the route record being navigated
  // to has a meta object with acl key in it
  if (to.matched.some(record => record.meta.acl) === true) {
    if ((await UserService.hasPermission(to.meta.acl)) === false) {
      next(from)
    }
  }
  next()
})

export default routes

import Vue from 'vue'
import { ModelError } from '@/modules/insight/services/model.service'

function nowISO () {
  return new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000).toISOString()
}

function ISOtoString (source, type) {
  if ([null, '', undefined].indexOf(source) !== -1) return null
  switch (type) {
    case Vue.prototype.$INSIGHT('INPUT_TYPES').DATE:
      return source.slice(0, 10)
    case Vue.prototype.$INSIGHT('INPUT_TYPES').DATETIME:
      return source.slice(0, 19)
    case Vue.prototype.$INSIGHT('INPUT_TYPES').TIME:
      return source.slice(11, 16)
    default:
      return source
  }
}

function stringToISO (source, type) {
  switch (type) {
    case Vue.prototype.$INSIGHT('INPUT_TYPES').DATE:
      // No offset to set time portion as 00:00
      return new Date(source).toISOString().slice(0, 16)
    case Vue.prototype.$INSIGHT('INPUT_TYPES').TIME:
      // As per iBase
      return new Date(`1899-12-30T${source}Z`).toISOString().slice(0, 16)
    // Case datetime is not required as it is already properly formatted
    default:
      return source
  }
}

function setValues (fields, withDefaults = true) {
  // Hopefully fast enough
  // Opposite sign as ISO8601 inverts order of subtraction
  const current = nowISO()
  fields.forEach((field) => {
    // Need to specify as 0 also has a boolean value of false, which is undesired
    if (withDefaults && [undefined, null, ''].indexOf(field.value) !== -1) {
      switch (field.default_value) {
        case Vue.prototype.$INSIGHT('SPECIAL_VALUES').TODAY:
        case Vue.prototype.$INSIGHT('SPECIAL_VALUES').NOW:
          field.value = ISOtoString(current, field.type)
          break
        default:
          // TODO Maybe check this in conjunction with submit, should set or not
          field.value = [undefined, null, ''].indexOf(field.default_value) === -1 ? field.default_value : null
      }
    } else {
      // If slow, do the checking here
      field.value = ISOtoString(field.value, field.type)
    }
  })
  return fields
}

function setFormData (fields, additionalData = {}, isUsingFEValidation = true) {
  const errors = {}
  const formData = new FormData()
  // TODO: eslint 6.8.0 false-positive bug on no-unused-vars. To be resolved when it is fixed in newer version
  // eslint-disable-next-line no-unused-vars
  for (const [key, value] of Object.entries(additionalData)) {
    formData.set(key, value)
  }
  fields.forEach((field) => {
    // trivial values have to be '' for FormData
    if (
      (field.type === Vue.prototype.$INSIGHT('INPUT_TYPES').SELECT) &&
      (
        // Increase specifity if select value can be 0
        (field.value && !field.meta.choices.find((choice) => choice.value === field.value)) ||
        (!field.value && field.validation.required)
      )
    ) {
      errors[field.name] = 'Please select one of the options provided'
    }
    // Cannot do falsy check as value 0 is also falsy
    formData.set(field.name, [null, ''].indexOf(field.value) === -1 ? stringToISO(field.value, field.type) : '')
  })

  if (Object.keys(errors).length && isUsingFEValidation) {
    throw new ModelError({ status: 400, data: { message: errors }, config: { method: 'post' } })
  }
  return formData
}

// Permissions
function hasAnyPermission (
  item,
  permissions = [Vue.prototype.$INSIGHT('PERMISSIONS').READ, Vue.prototype.$INSIGHT('PERMISSIONS').CREATE]
) {
  // Input Checking (Maybe should do in tests? Since this will add on to overhead)
  if (!permissions || !permissions.length) {
    throw SyntaxError('argument permissions is required')
  }
  if (!item.permissions) {
    // TODO Commented console.warn as it is causing build failure
    // console.warn('Item has no permissions property, defaulting to true...')
    return true
  }

  const _permissions = []
  // More scalable
  permissions.forEach((permission) => {
    // TODO JSON Somehow
    _permissions.push(!item.permissions.find(permissionString => permissionString.startsWith(`insight.cannot_${permission}_`)))
  })
  // Scalable, Faster OR operation
  return (_permissions.indexOf(true) !== -1)
}

// Assuming inputs are errorless
function cleanItems (items) {
  // TODO Mutate? Or New?
  const cleanedItems = {}
  // TODO: eslint 6.8.0 false-positive bug on no-unused-vars. To be resolved when it is fixed in newer version
  // eslint-disable-next-line no-unused-vars
  for (const [itemName, item] of Object.entries(items)) {
    if (hasAnyPermission(item)) {
      cleanedItems[itemName] = item
    }
  }
  return cleanedItems
}

export { nowISO, ISOtoString, stringToISO, setValues, setFormData, hasAnyPermission, cleanItems }

<template>
  <!-- Item Cards for Entity/Link/Query -->
  <div class="item-cards" id="item-cards">
    <!-- Individual Item Card -->
    <div
      v-for="(item, itemName) of items" :key="itemName"
      class="item-card fade-in-fast"
      ref="itemCards"
      :id="`${itemName}`"
      data-favourite="unfavourite"
      data-has-icon="false"
    >
      <!-- Top Body -->
      <div class="inner" v-bind:class="{'warning-item-card' : item.hasNoDataFound}" v-b-tooltip:item-cards.html="item.description">
        <!-- TODO Redesign this toggle -->
        <!-- TODO Cache and fetch icon src -->
        <b-img class="icon img-thumbnail rounded-circle fade-in-fast" aria-hidden="true"/>
        <component class="default-icon icon" v-if="!item.hasNoDataFound && item.has_icon" :is="`icon-${itemType}-placeholder`" aria-hidden="true"/>
        <component class="default-icon icon" v-else-if="!item.hasNoDataFound && !item.has_icon" :is="`icon-${itemType}`" aria-hidden="true"/>
        <component class="default-icon icon" v-else :is="`icon-query-error`" aria-hidden="true"/>
        <span class="warning-text">{{ item.label }}</span>
      </div>
      <!-- Bottom Button Group -->
      <b-button-group class="buttons">
        <!-- TODO Should abstract logic away from html -->
        <b-btn class="create-button"
          v-if="checkPermissions(item.permissions, 'create', item.hasNoDataFound)"
          variant="success" type="button" v-b-tooltip:item-cards.hover
          :title="(itemType === 'form') ? formCreateButtonTooltipText(item) : $LOCAL('ToolTipLabelNew')"
          :to="createPage(itemType, itemName, item)"
          :aria-label="`${$LOCAL('ToolTipLabelNew')} ${item.label}`"
        >
          <LaPlus class="btn-img white-svg"/>
        </b-btn>
        <b-btn class="index-button"
          v-if="checkPermissions(item.permissions, 'read', item.hasNoDataFound)"
          variant="primary" type="button" v-b-tooltip:item-cards.hover
          :title="(itemType === 'query') ? $LOCAL('ToolTipLabelResults') : $LOCAL('ToolTipLabelBrowse')"
          :to="indexPage(itemType, itemName)"
          :aria-label="`${$LOCAL('ToolTipLabelBrowse')} ${item.label}`"
        >
          <LaList class="btn-img"/>
        </b-btn>
        <b-btn class=""
          v-if="checkPermissions(item.permissions, 'change', item.hasNoDataFound)"
          :variant="(itemType === 'query') ? 'success' : 'primary'"
          type="button" v-b-tooltip:item-cards.hover
          :title="$LOCAL('ToolTipLabelEdit')"
          :to="editPage(itemType, itemName)"
          :aria-label="`${$LOCAL('ToolTipLabelEdit')} ${item.label}`"
        >
          <LaEdit class="btn-img white-svg"/>
        </b-btn>
        <b-btn class="star-button" variant="secondary" type="button"
          v-b-tooltip:item-cards.hover :title="$LOCAL('ToolTipLabelStar')"
          @click="setFavourite(itemName)"
          :aria-label="`${$LOCAL('ToolTipLabelStar')} ${item.label}`"
        >
          <LaStar class="star-icon btn-img"/>
        </b-btn>
      </b-button-group>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'

import ModelService from '@/modules/insight/services/model.service'

import IconLink from '@/assets/link.svg'
import IconEntity from '@/assets/entity.svg'
import IconQuery from '@/assets/la-question-circle.svg'
import IconForm from '@/assets/forms.svg'
import LaStar from '@/assets/star-solid.svg'
import LaList from '@/assets/la-list.svg'
import LaPlus from '@/assets/la-plus.svg'
import LaEdit from '@/assets/la-edit.svg'
import IconQueryError from '@/assets/la-exclamation-circle.svg'
import IconEntityPlaceholder from '@/assets/entity-placeholder.svg'

export default {
  name: 'item-cards',
  components: {
    IconEntity, IconLink, IconQuery, IconForm, LaPlus, LaList, LaStar, LaEdit, IconQueryError, IconEntityPlaceholder
  },
  props: {
    prefetched: {
      type: Boolean,
      default: false
    },
    itemType: {
      type: String
    },
    items: {
      type: Object
    },
    formPermissions: {
      type: Array,
      default: () => []
    }
  },
  data: () => ({
    plural: {
      entity: 'entities',
      link: 'links',
      query: 'queries',
      form: 'forms'
    },
    rendered: {
      icons: false,
      favourites: false
    },
    invalidButtonTypes: {
      create: ['query'],
      read: ['form'],
      change: ['entity', 'link']
    }
  }),
  computed: {
    ...mapState('insight', { userFavouriteList: 'userFavouriteList' }),
    ...mapState('auth', { userData: 'userData' })
  },
  async mounted () {
    await this.retrieveUserData()
    if (this.itemType === this.$INSIGHT('MODEL_TYPES').ENTITY) {
      this.setIcons()
    }
    if (!this.prefetched) { await this.getFavouriteList({ username: this.userData.username }) }
    this.checkFavourited(this.itemType)
  },
  methods: {
    ...mapActions('insight', [
      'getFavouriteList',
      'makeAsFavourite',
      'removeFromFavourite'
    ]),
    ...mapActions('auth', [
      'retrieveUserData'
    ]),
    ...mapActions('forms', { fetchFormPermissions: 'getPermissions' }),
    checkPermissions (permissions, permissionToCheck, hasNoDataFound) {
      const constantView = this.$INSIGHT('PERMISSIONS').VIEW
      const constantCHNG = this.$INSIGHT('PERMISSIONS').CHNG
      const constantRead = this.$INSIGHT('PERMISSIONS').READ
      const modelPermissionGranted = !permissions.find(
        permission => permission.startsWith(`insight.cannot_${permissionToCheck}_`)
      )
      if (hasNoDataFound) {
        return false
      }
      if (this.invalidButtonTypes[permissionToCheck]?.includes(this.itemType)) {
        return false
      }
      if (this.itemType === this.$INSIGHT('MODEL_TYPES').FORM) {
        const isNotCHNGPermission = permissionToCheck !== constantCHNG
        const isPermittedToView = this.formPermissions.includes(`dynamic_forms.${constantView}_dynamicform`)
        const isPermitted = this.formPermissions.includes(`dynamic_forms.${permissionToCheck}_dynamicform`)
        const grantedAccess = isNotCHNGPermission ? modelPermissionGranted : isPermitted
        return grantedAccess && isPermittedToView
      }
      if (this.itemType === this.$INSIGHT('MODEL_TYPES').QUERY) {
        // in query 'read' is 'view', confusing right? 😥
        const queryPermission = permissionToCheck === constantRead ? constantView : permissionToCheck
        const isPermittedToView = permissions.includes(`insight.${constantView}_ibasedatastore`)
        const isPermitted = permissions.includes(`insight.${queryPermission}_ibasedatastore`)
        return isPermitted && isPermittedToView
      }
      // Maybe if no permissions return true
      return modelPermissionGranted
    },
    createPage (itemType, itemName, itemObject = null) {
      if (itemType === this.$INSIGHT('MODEL_TYPES').FORM) {
        if (this.isCompositeForm(itemType, itemName)) {
          return { path: `/${this.$FORM('FORM_TYPE').COMPOSITE}/${itemObject.type}/create/${itemName}` }
        } else {
          return { path: `/${itemObject.type}/${itemObject.model}/create/${itemName}` }
        }
      }
      const name = `create-${itemType}`
      const params = { model: itemName }
      return { name, params }
    },
    indexPage (itemType, itemName) {
      const name = (itemType === 'query') ? `generate-${itemType}` : `${this.plural[itemType]}-index`
      const params = { [(itemType === 'query') ? 'id' : 'model']: itemName }
      return { name, params }
    },
    editPage (itemType, itemName) {
      if (itemType === 'form') {
        if (!this.isCompositeForm(itemType, itemName)) {
          return { path: `/forms/${itemName}` }
        } else {
          return { path: `/forms/composite/${itemName}` }
        }
      }
      const name = `edit-${itemType}`
      const params = { id: itemName }
      return { name, params }
    },
    async setIcon (model) {
      const response = await ModelService.getIconImage(model)
      if (this.$refs.itemCards) {
        const itemCard = this.$refs.itemCards.find(item => item.id === model)
        if (itemCard) {
          if (response.status === 200) {
            itemCard.dataset.hasIcon = 'true'
            // get img component with icon class. Then set source
            itemCard.querySelector('img.icon').src = window.URL.createObjectURL(
              new Blob([response.data])
            )
          } else {
            itemCard.dataset.hasIcon = 'false'
            // TODO Throw Error if response.status != 204?
          }
        }
      }
    },
    setIcons () {
      for (const model in this.items) {
        const modelObj = this.items[model]
        if (modelObj.has_icon) {
          this.setIcon(model)
        }
      }
    },
    async checkFavourited (itemType) {
      if (this.userFavouriteList.results) {
        this.userFavouriteList.results.forEach((result) => {
          // Assuming data is always clean
          // If user is not null, then is personal, otherwise is mandatory
          if (result.item_type === itemType && this.$refs.itemCards) {
            const starButton = this.$refs.itemCards.find(item => item.id === result.item_name)
            if (starButton) {
              starButton.dataset.favourite = result.user ? 'favourite' : 'mandatory'
            }
          }
        })
      }
    },
    async setFavourite (itemName) {
      const params = {
        username: this.userData.username,
        data: {
          item_type: this.itemType,
          item_name: itemName
        }
      }
      const starButton = this.$refs.itemCards.find(item => item.id === itemName)
      if (starButton.dataset.favourite === 'unfavourite') {
        const created = await this.makeAsFavourite(params)
        if (created === true && starButton) {
          starButton.dataset.favourite = 'favourite'
        }
      } else {
        const deleted = await this.removeFromFavourite(params)
        if (deleted === true && starButton) {
          this.$emit('unfavourite', this.plural[this.itemType], itemName)
          starButton.dataset.favourite = 'unfavourite'
        }
      }
    },
    isCompositeForm (itemType, itemName) {
      if (itemType === 'form') {
        return this.items[itemName].form_type === 'C'
      } else {
        return false
      }
    },
    formCreateButtonTooltipText (item) {
      let modelName = item.model_name
      if (this.isCompositeForm(item.item_type, item.item_name)) {
        modelName = this.$LOCAL('COMMON_WORD').RECORDS
      }
      return `${this.$LOCAL('ToolTipLabelNew')} ${modelName}`
    }
  }
}
</script>

<style lang="scss" scoped>
.item-card[data-favourite='mandatory']{
  .buttons{
    .star-button{
      display: none;
    }
  }
}
.item-card[data-favourite='favourite']{
  .buttons{
    .star-button{
      .btn-img {
        fill: $favourited
      }
    }
  }
}
.item-card[data-favourite='unfavourite']{
  .buttons{
    .star-button{
      .btn-img {
        fill: $unfavourited
      }
    }
  }
}
.item-card[data-has-icon='false']{
  .inner{
    .icon {
      display: none;
    }
    .default-icon{
      display: block;
    }
  }
}
.item-card[data-has-icon='true']{
  .inner{
    .icon {
      display: block;
    }
    .default-icon{
      display: none;
    }
  }
}
</style>

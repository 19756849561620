import FormService from '../services/forms.service'

export default {
  getFormList: async ({ commit }, { params, isAppend }) => {
    commit('clearError')

    try {
      const items = await FormService.getFormList(params)

      if (isAppend) {
        commit('appendFormList', items)
      } else {
        commit('setFormList', items)
      }

      return true
    } catch (error) {
      commit('setError', {
        code: error.errorCode,
        message: error.message
      })

      return false
    }
  },
  getForm: async ({ commit }, { id, params }) => {
    commit('clearError')

    try {
      const formData = await FormService.getForm(id, params)
      if (formData.type === 'C') {
        commit('setCompositeForm', formData)
      }
      return formData
    } catch (error) {
      commit('setError', {
        code: error.errorCode,
        message: error.message
      })

      return false
    }
  },
  getFormFields: async ({ commit }, { modelType, isCreate, isCompositeForm }) => {
    commit('clearError')

    try {
      const formField = await FormService.getFormFields(modelType, isCreate, isCompositeForm)

      return formField
    } catch (error) {
      commit('setError', {
        code: error.errorCode,
        message: error.message
      })

      return false
    }
  },
  updateForm: async ({ commit }, { id, data }) => {
    commit('clearError')
    try {
      await FormService.updateForm(id, data)
      return true
    } catch (error) {
      commit('setError', {
        code: error.errorCode,
        message: error.message
      })
      return false
    }
  },
  createForm: async ({ commit }, data) => {
    commit('clearError')
    try {
      const response = await FormService.createForm(data)

      return response
    } catch (error) {
      commit('setError', {
        code: error.errorCode,
        message: error.message
      })
      return false
    }
  },
  createCompositeForm: async ({ commit }, data) => {
    commit('clearError')
    try {
      const response = await FormService.createCompositeForm(data)

      return response
    } catch (error) {
      commit('setError', {
        code: error.errorCode,
        message: error.message
      })
      return false
    }
  },
  createModel: async ({ commit }, { id, data }) => {
    commit('clearError')
    try {
      const response = await FormService.createModel(id, data)

      return response
    } catch (error) {
      commit('setError', {
        code: error.errorCode,
        message: error.message
      })
      return false
    }
  },
  deleteForm: async ({ commit }, id) => {
    commit('clearError')
    try {
      await FormService.deleteForm(id)
      return true
    } catch (error) {
      commit('setError', {
        code: error.errorCode,
        message: error.message
      })
      return false
    }
  },
  choicesPermittedGroup: async ({ commit }, model) => {
    commit('clearError')
    try {
      const choices = await FormService.choicesPermittedGroup(model)
      return choices
    } catch (error) {
      commit('setError', {
        code: error.errorCode,
        message: error.message
      })
      return false
    }
  },
  getPermissions: async ({ commit }) => {
    commit('clearError')
    try {
      const permissions = await FormService.getPermissions()
      commit('userPermissions', permissions)
      return permissions
    } catch (error) {
      commit('setError', {
        code: error.errorCode,
        message: error.message
      })
      return false
    }
  },
  getCompositeFormStructure: async ({ commit }, formId) => {
    commit('clearError')
    try {
      const compositeFormStructure = await FormService.getCompositeFormStructure(formId)
      return compositeFormStructure
    } catch (error) {
      commit('setError', {
        code: error.errorCode,
        message: error.message
      })
      return false
    }
  }
}

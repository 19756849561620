import ApiService from '@/modules/auth/services/api.service'
import handlers from '../../../utils/error_handler'
import DateTimeUtils from '@/utils/datetime_handler'

const TARGET = 'query'
class QueryError extends Error {
  constructor (errorResponse) {
    super()
    this.name = this.constructor.name
    this.message = handlers.ErrorHandler(TARGET, errorResponse) // Creates custom error messages
    this.errorCode = errorResponse.status
  }
}

const QueryService = {
  getQueries: async function (params) {
    try {
      DateTimeUtils.appendCurDateTimeFn(params)
      const response = await ApiService.get('/insight/query/', params)
      return {
        results: response.data
      }
    } catch (error) {
      throw new QueryError(error.response)
    }
  },

  getQuery: async function (id) {
    try {
      const dateTimeParam = {}
      DateTimeUtils.appendCurDateTimeFn(dateTimeParam)
      const response = await ApiService.get(`/insight/query/${id}/`, dateTimeParam)
      return response.data
    } catch (error) {
      throw new QueryError(error.response)
    }
  },

  createQuery: async function (data) {
    try {
      const response = await ApiService.post('/insight/query/', data)
      return response.data
    } catch (error) {
      throw new QueryError(error.response)
    }
  },

  updateQuery: async function (id, data) {
    try {
      const response = await ApiService.put(`/insight/query/${id}/`, data)
      return response.data
    } catch (error) {
      throw new QueryError(error.response)
    }
  },

  deleteQuery: async function (id) {
    try {
      const response = await ApiService.delete(`/insight/query/${id}/`)
      return response.status
    } catch (error) {
      throw new QueryError(error.response)
    }
  },

  deleteQueryWithCascadeToCharts: async function (id) {
    try {
      const response = await ApiService.delete(`/insight/query/${id}/?isCascadeDelete=True/`)
      return response.status
    } catch (error) {
      throw new QueryError(error.response)
    }
  },

  generateQuery: async function (id, params) {
    try {
      DateTimeUtils.appendCurDateTimeFn(params)
      const response = await ApiService.get(`/insight/query/${id}/generate/`, params)
      return response.data
    } catch (error) {
      throw new QueryError(error.response)
    }
  },

  exportGeneratedDataQuery: async function (id, params) {
    try {
      const response = await ApiService.get(`/insight/query/${id}/generate/`, params)
      return response
    } catch (error) {
      throw new QueryError(error.response)
    }
  },

  createForm: async function () {
    try {
      const response = await ApiService.get('/insight/query/create_form/')
      return response.data
    } catch (error) {
      throw new QueryError(error.response)
    }
  },

  getInputParametersQuery: async function (id) {
    try {
      const dateTimeParam = {}
      DateTimeUtils.appendCurDateTimeFn(dateTimeParam)
      const response = await ApiService.get(`/insight/query/${id}/get_input_parameters/`, dateTimeParam)
      return response.data
    } catch (error) {
      throw new QueryError(error.response)
    }
  },

  getLinkedCharts: async function (id) {
    try {
      const response = await ApiService.get(`/insight/query/${id}/get_linked_charts/`)
      return response.data
    } catch (error) {
      throw new QueryError(error.response)
    }
  },

  getAccessGroupsOfSingleQuery: async function (id) {
    try {
      const response = await ApiService.get(`/insight/query/${id}/get_access_groups_of_single_query/`)
      return response.data
    } catch (error) {
      throw new QueryError(error.response)
    }
  },

  getQueryCRUDPermissions: async function () {
    try {
      const response = await ApiService.get('/insight/query/query_crud_permissions/')
      return response.data
    } catch (error) {
      throw new QueryError(error.response)
    }
  },

  getFavouritedQueryData: async function (id) {
    try {
      const response = await ApiService.get(`/insight/query/${id}/get_query_data/`)
      return response.data
    } catch (error) {
      throw new QueryError(error.response)
    }
  }
}

export { QueryService, QueryError }
export default QueryService

import Vue from 'vue'

export default {
  viewableCharts: state => JSON.parse(JSON.stringify(state.viewableCharts)),
  seriesSelection: (state) => {
    if (!state.selectedQueries || state.selectedQueries.length === 0) {
      return []
    }
    const series = []
    state.selectedQueries.forEach((query) => series.push(...query.outputModels))

    // This portion is used to monitor the number of series selected in Scenario 1 (Can only have 1 series)
    const numberOfSeriesSelected = series.filter(outputModel => outputModel.selected).length
    if (numberOfSeriesSelected === 0) {
      state.oneOutputModelTypeSelected = [] // basically doing action clearOneOutputModelTypeSelected()
    }
    // [{ selected: Boolean, type: String, name: String, queryId: Number }, ... ]
    return series
  },
  hasPermissionForGivenAction: (state) => (permAction) => {
    if (!Object.values(Vue.prototype.$DASHBOARD('PERMISSIONS')).includes(permAction)) {
      return true
    } else {
      return state.userDashboardPermissions.includes(`dashboard.${permAction}_chart`)
    }
  }
}

import dotenv from 'dotenv'
dotenv.config()

export default class Configuration {
  static get CONFIG () {
    return {
      VUE_APP_API_URL: '$VUE_APP_API_URL',
      VUE_APP_INACTIVITY_DURATION_IN_MILLISECONDS: '$VUE_APP_INACTIVITY_DURATION_IN_MILLISECONDS',
      VUE_APP_WEB_SOCKET_HOST: '$VUE_APP_WEB_SOCKET_HOST',
      VUE_APP_WEB_SOCKET_EXPIRY_IN_MILLISECONDS: '$VUE_APP_WEB_SOCKET_EXPIRY_IN_MILLISECONDS'
    }
  }

  static value (name) {
    if (!(name in this.CONFIG)) {
      return
    }

    const value = this.CONFIG[name]

    // if value is empty, it means environment variable is not set. Return empty
    if (!value) {
      return
    }

    if (value.startsWith('$VUE_APP_')) {
      // value was not replaced, it seems we are in development.
      // Remove $ and get current value from process.env
      const envName = value.substr(1)
      const envValue = process.env[envName]
      if (envValue) {
        return envValue
      }
    } else {
      // value was already replaced, it seems we are in production.
      return value
    }
  }
}

import ApiService from '@/modules/auth/services/api.service'
import { ConfigurationError } from '../errors/configuration_error'

const ShowWizardService = {
  get: async function () {
    try {
      const response = await ApiService.get('/config/show_wizard/')
      return response.data.show_wizard
    } catch (error) {
      throw new ConfigurationError(error.response.status, error.response.data)
    }
  }
}

export { ShowWizardService }
export default ShowWizardService

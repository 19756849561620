import ApiService from '@/modules/auth/services/api.service'
import { ConfigurationError } from '../errors/configuration_error'

const AuthenticationSetupService = {
  options: async function () {
    try {
      const response = await ApiService.options('/config/adsetup/')
      return response.data.actions.POST
    } catch (error) {
      throw new ConfigurationError(error.response.status, error.response.data)
    }
  },

  get: async function () {
    try {
      const response = await ApiService.get('/config/adsetup/')
      return response.data
    } catch (error) {
      throw new ConfigurationError(error.response.status, error.response.data)
    }
  },

  check: async function (data) {
    try {
      const response = await ApiService.post('/config/adsetup/check_ad_connection/', data)
      return response.data
    } catch (error) {
      throw new ConfigurationError(error.response.status, error.response.data)
    }
  },

  save: async function (data) {
    try {
      const response = await ApiService.post('/config/adsetup/', data)
      if (response.status === 201 || response.status === 200) {
        await ApiService.get('/config/adsetup/ad_settings_registration/')
      }
      return response
    } catch (error) {
      throw new ConfigurationError(error.response.status, error.response.data)
    }
  }
}

export { AuthenticationSetupService, ConfigurationError }

export default AuthenticationSetupService

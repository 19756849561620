import ApiService from '@/modules/auth/services/api.service'
import handlers from '../../../utils/error_handler'
import DateTimeUtils from '@/utils/datetime_handler'

const TARGET = 'chart'

class DashboardError extends Error {
  constructor (errorResponse) {
    super()
    this.name = this.constructor.name
    // dashboard permissions are slightly more complex considering
    // that they have to also consider user's general perms to access queries + account single query's security groups
    // so the message will be set handled in actions.js for 403 cases
    if (errorResponse.status === 403) {
      this.message = ''
    } else {
      this.message = handlers.ErrorHandler(TARGET, errorResponse) // Creates custom error messages
    }
    this.errorCode = errorResponse.status
    this.errorDetails = errorResponse.data // store entire data response from BE
  }
}

const DashboardService = {
  getDashboardPermissionsForUser: async () => {
    try {
      const response = await ApiService.get('/dashboard/charts/get_dashboard_permissions_for_user/')
      return response.data
    } catch (error) {
      throw new DashboardError(error.response)
    }
  },
  getOrderingFields: async () => {
    try {
      const response = await ApiService.get('/dashboard/charts/get_ordering_fields/')
      return response.data
    } catch (error) {
      throw new DashboardError(error.response)
    }
  },
  createChart: async (data) => {
    try {
      DateTimeUtils.appendCurDateTimeFn(data)
      const response = await ApiService.post('/dashboard/manage/', data)
      return response.data
    } catch (error) {
      throw new DashboardError(error.response)
    }
  },
  getAllOutputModelTypesAndFieldsByQueryId: async (id) => {
    try {
      const params = { query_id: id }
      DateTimeUtils.appendCurDateTimeFn(params)
      const response = await ApiService.get('/dashboard/manage/get_all_output_model_types_and_fields_by_query_id/', params)
      return response.data
    } catch (error) {
      throw new DashboardError(error.response)
    }
  },
  getCreateChartFields: async function () {
    try {
      const response = await ApiService.options('/dashboard/manage/')
      return response.data.actions.POST // returning {id, title, chart_type, label_type, [chart_type: choices: {value: 'PIE', display_name: 'pie'}]}
    } catch (error) {
      throw new DashboardError(error.response)
    }
  },
  getAllViewableChartsOptimised: async (params) => {
    try {
      DateTimeUtils.appendCurDateTimeFn(params)
      const response = await ApiService.get('/dashboard/charts/?isOptimised=True', params)
      return response.data
    } catch (error) {
      throw new DashboardError(error.response)
    }
  },
  getSingleChart: async (chartId, isForEdit = false) => {
    // retrieves full details of a single chart using its id
    try {
      const dateTimeParam = {}
      DateTimeUtils.appendCurDateTimeFn(dateTimeParam)
      let response
      if (isForEdit) {
        response = await ApiService.get(`/dashboard/charts/${chartId}/?isForEdit=True/`, dateTimeParam)
      } else {
        response = await ApiService.get(`/dashboard/charts/${chartId}/`, dateTimeParam)
      }
      return response.data
    } catch (error) {
      throw new DashboardError(error.response)
    }
  },
  getChartPreviewDetails: async (data) => {
    try {
      DateTimeUtils.appendCurDateTimeFn(data)
      const response = await ApiService.post('/dashboard/manage/generate_chart_preview_details/', data)
      return response.data
    } catch (error) {
      throw new DashboardError(error.response)
    }
  },
  deleteChart: async (chartId) => {
    try {
      const response = await ApiService.delete(`/dashboard/manage/${chartId}/`)
      return response.status // 204 if success
    } catch (error) {
      throw new DashboardError(error.response)
    }
  },
  updateChart: async (data) => {
    try {
      DateTimeUtils.appendCurDateTimeFn(data)
      const response = await ApiService.put(`/dashboard/manage/${data.id}/`, data)
      return response.data
    } catch (error) {
      throw new DashboardError(error.response)
    }
  }
}

export { DashboardService, DashboardError }
export default DashboardService

import INSIGHT from '@/constants/INSIGHT.json'
import ToastMessage from '@/utils/toast_message'
import { isEmpty } from 'lodash'

/*
  Ideas to be explored:
  1. Abstract Error Handler as a generic Class (e.g. InsightError)
  2. Use a Vue mixin
*/

// Map HTTP methods to layman terms
const CRUD = {
  get: 'view',
  delete: 'delete',
  post: 'create',
  put: 'update'
}

function ErrorHandler (target, errorResponse) {
  /**
   * @param {String} target - The resource that produced this error (query, link, etc)
   * @param {Object} errorResponse - The error response object generated from backend
   * @returns {Object}
   * An object with the HTTP error code and appropriate error message
  */

  const method = errorResponse.config.method // CRUD
  const code = errorResponse.status // HTTP error code
  const data = errorResponse.data // Error message data
  let message
  switch (code) {
    case 400:
      message = handlers.Handler400(target, method, data)
      break
    case 403:
      message = handlers.Handler403(target, method)
      break
    case 404:
      message = handlers.Handler404(target, method)
      ToastMessage.showErrorDefault({ textMessage: message })
      break
    default:
      message = handlers.HandlerOthers(errorResponse.data)
      break
  }
  return message
}

function Handler404 (target, method) {
  let message, upperCaseFirstLetterTargetText, lowerCaseMessageError
  switch (method) {
    case 'get':
    case 'put':
    case 'post':
    case 'delete':
      upperCaseFirstLetterTargetText = target.charAt(0).toUpperCase() + target.slice(1)
      lowerCaseMessageError = INSIGHT.ERROR.CODE_404.toLowerCase()
      message = `${upperCaseFirstLetterTargetText} ${lowerCaseMessageError}`
      break
    default:
      message = ''
  }
  return message
}

function Handler403 (target, method) {
  const contactAdminMessage = 'Please contact your system administrator to access this functionality.'
  switch (method) {
    case 'get':
    case 'put':
    case 'post':
    case 'delete':
      return `You do not have permission to ${CRUD[method]} ${target}. ${contactAdminMessage}`
    default:
      return ''
  }
}

function Handler400 (target, method, data = {}) {
  // Case when data is custom string or have custom message
  if (typeof data === 'string') {
    return data
  } else if (Object.prototype.hasOwnProperty.call(data, 'message')) {
    return data.message
  }

  const tryAgainMessage = 'Please try again later.'
  const correctMistakesMessage = 'Please correct your mistakes and try again.'
  switch (method) {
    case 'get':
      if (isEmpty(data)) {
        return `There is an error in ${CRUD[method]}ing ${target}. ${tryAgainMessage}`
      } else {
        const msgFromBackend = data.toString()
        return msgFromBackend
      }
    case 'put':
    case 'post':
      // TODO: We need to standardise how we handle errors at the downstream so we can unify these
      if (isEmpty(data)) {
        return `There is an error in ${CRUD[method].slice(0, -1)}ing ${target}. ${correctMistakesMessage}`
      } else if ((target === 'form') || (target === 'chart')) {
        return data
      } else {
        data.message = `There is an error in ${CRUD[method].slice(0, -1)}ing ${target}. ${correctMistakesMessage}`
        return data
      }
    case 'delete':
      return `There is an error in ${CRUD[method].slice(0, -1)}ing ${target}. ${tryAgainMessage}`
    default:
      return ''
  }
}

function HandlerOthers (rawErrorMessage) {
  // TODO: Handle 500 errors
  // Something more meaningful, perhaps?
  return rawErrorMessage
}

const handlers = {
  ErrorHandler,
  Handler400,
  Handler403,
  Handler404,
  HandlerOthers
}

export default handlers

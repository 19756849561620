import ActiveDirectorySyncService from '@/modules/config/services/active_directory_sync.service'
import AuditDatabaseConnectionService from '../../services/audit_database_connection.service'
import AuthenticationMethodService from '../../services/authentication_method.service'
import AuthenticationSetupService from '../../services/authentication_setup.service'
import DatabaseIndexSchedulerService from '../../services/database_index_scheduler.service'
import MaintenanceService from '../../services/maintenance.service'
import Vue from 'vue'
import systemFieldsUtil from '../../../../utils/system_fields_util'
import { ConfigurationError } from '../../errors/configuration_error'
import { DatabaseConnectionService } from '../../services/database_connection.service'
import { DatabaseIndexService } from '../../services/database_index.service'
import { DatabaseMappingService } from '../../services/database_mapping.service'

export default {
  async fetchDbConnectionFields ({ commit, state }) {
    commit('startRequest')
    commit('clearError')
    // Clear system field
    commit('setSystemFields', null)

    try {
      const connectionFields = await DatabaseConnectionService.options()

      for (const key of Object.keys(connectionFields)) {
        connectionFields[key].error = null
      }
      commit(
        'setDbConnectionFields',
        systemFieldsUtil.removeSystemFields(connectionFields)
      )

      const connectionFieldValues = await DatabaseConnectionService.get()
      if (
        Array.isArray(connectionFieldValues) &&
        connectionFieldValues.length
      ) {
        // A password placeholder will be set as backend does not return the password to the frontend
        connectionFieldValues[0].password = Vue.prototype.$CONFIG(
          'WIZARD_PAGE_3'
        ).PASSWORD_PLACEHOLDER
        commit('setDbConnectionIsValid', true)
        commit('setDbConnectionFieldValues', connectionFieldValues[0])
        commit(
          'setSystemFields',
          systemFieldsUtil.getSystemFields(connectionFieldValues[0])
        )
      }

      commit('notDirty')
      commit('endRequest')
      return true
    } catch (error) {
      if (error instanceof ConfigurationError) {
        commit('setError', {
          errorCode: error.errorCode,
          errorMessage: error.message
        })
      } else {
        commit('setError', {
          errorCode: 999,
          errorMessage:
            'Insight Server have encountered an error. Please ensure it is up and running.'
        })
      }
      commit('endRequest')
      return false
    }
  },

  async allowEditDbConnectionField ({ commit, state }) {
    commit('setDbConnectionIsValid', false)
    commit('isDirty')

    const connectionFields = state.dbConnectionFields
    connectionFields.password.value = null
    commit('setDbConnectionFields', connectionFields)
  },

  async checkDbConnectionFields ({ commit, state }) {
    commit('startRequest')
    commit('clearError')
    try {
      const checkFields = {}
      for (const key of Object.keys(state.dbConnectionFields)) {
        checkFields[key] =
          state.dbConnectionFields[key].value === undefined
            ? ''
            : state.dbConnectionFields[key].value
      }
      const connectionFields = await DatabaseConnectionService.check(
        checkFields
      )
      commit('setDbConnectionFieldValues', connectionFields)
      commit('endRequest')
      commit('isDirty')
      commit('setDbConnectionIsValid', true)
      return true
    } catch (error) {
      if (error instanceof ConfigurationError) {
        commit('setError', {
          errorCode: error.errorCode,
          errorMessage: error.message
        })
      } else {
        commit('setError', {
          errorCode: 999,
          errorMessage:
            'Insight Server have encountered an error. Please ensure it is up and running'
        })
      }
      commit('endRequest')
      commit('setDbConnectionIsValid', false)
      return false
    }
  },

  async saveDbConnectionFields ({ commit, state }) {
    commit('startRequest')
    commit('clearError')

    try {
      const inputFields = {}
      for (const key of Object.keys(state.dbConnectionFields)) {
        inputFields[key] = state.dbConnectionFields[key].value
      }

      if (state.dirty) {
        const connectionFields = await DatabaseConnectionService.save(
          inputFields
        )
        commit('setDbConnectionFieldValues', connectionFields)
      } else {
        await DatabaseConnectionService.save_with_no_change()
      }

      commit('endRequest')
      commit('notDirty')
      commit('saved')
      return true
    } catch (error) {
      if (error instanceof ConfigurationError) {
        commit('setError', {
          errorCode: error.errorCode,
          errorMessage: error.message
        })
      } else {
        commit('setError', {
          errorCode: 999,
          errorMessage:
            'Insight Server have encountered an error. Please ensure it is up and running'
        })
      }
      commit('endRequest')
      commit('setDbConnectionIsValid', false)
      return false
    }
  },

  async fetchAuditDbConnectionFields ({ commit, state }) {
    /**
     * @public
     * @async
     * This action will fetch the audit db connection fields as well as any existing values already applied.
     * @return true if no exception. Otherwise, false
     */
    commit('startRequest')
    commit('clearError')
    // Clear system field
    commit('setSystemFields', null)

    try {
      const connectionFields = await AuditDatabaseConnectionService.options()

      for (const key of Object.keys(connectionFields)) {
        connectionFields[key].error = null
      }
      commit(
        'setAuditDbConnectionFields',
        systemFieldsUtil.removeSystemFields(connectionFields)
      )

      const connectionFieldValues = await AuditDatabaseConnectionService.get()
      if (
        Array.isArray(connectionFieldValues) &&
        connectionFieldValues.length
      ) {
        connectionFieldValues[0].password = 'passwordplaceholder'
        commit('setAuditDbConnectionIsValid', true)
        commit('setAuditDbConnectionFieldValues', connectionFieldValues[0])
        commit(
          'setSystemFields',
          systemFieldsUtil.getSystemFields(connectionFieldValues[0])
        )
      }

      commit('notDirty')
      commit('endRequest')
      return true
    } catch (error) {
      if (error instanceof ConfigurationError) {
        commit('setError', {
          errorCode: error.errorCode,
          errorMessage: error.message
        })
      } else {
        commit('setError', {
          errorCode: 999,
          errorMessage:
            'Insight Server have encountered an error. Please ensure it is up and running.'
        })
      }
      commit('endRequest')
      return false
    }
  },

  async allowEditAuditDbConnectionField ({ commit, state }) {
    /**
     * @public
     * @async
     * This action will set dirty to true and clear the password placeholder value to allow editing of the fields.
     */
    commit('setAuditDbConnectionIsValid', false)
    commit('isDirty')

    const connectionFields = state.auditDbConnectionFields
    connectionFields.password.value = null
    commit('setAuditDbConnectionFields', connectionFields)
  },

  async checkAuditEnabled ({ commit }) {
    /**
     * @public
     * @async
     * This action will check if audit is enabled to determine if audit settings should be shown in the menu
     * @return true if audit is enabled. Otherwise, false
     */
    const connectionFieldValues = await AuditDatabaseConnectionService.get()

    if (Array.isArray(connectionFieldValues) && connectionFieldValues.length) {
      commit('setShowAuditInSettings', true)
      return true
    }

    return false
  },

  async checkAuditDbConnectionFields ({ commit, state }) {
    /**
     * @public
     * @async
     * This action will check the audit db values if is valid and able to connect to the database.
     * @return true if success. Otherwise false
     */
    commit('startRequest')
    commit('clearError')

    try {
      const checkFields = {}
      for (const key of Object.keys(state.auditDbConnectionFields)) {
        checkFields[key] = state.auditDbConnectionFields[key].value
      }

      const connectionFields = await AuditDatabaseConnectionService.check(
        checkFields
      )

      commit('setAuditDbConnectionFieldValues', connectionFields)
      commit('endRequest')
      commit('isDirty')
      commit('setAuditDbConnectionIsValid', true)
      return true
    } catch (error) {
      if (error instanceof ConfigurationError) {
        commit('setError', {
          errorCode: error.errorCode,
          errorMessage: error.message
        })
      } else {
        commit('setError', {
          errorCode: 999,
          errorMessage:
            'Insight Server have encountered an error. Please ensure it is up and running'
        })
      }
      commit('endRequest')
      commit('setAuditDbConnectionIsValid', false)
      return false
    }
  },

  async saveAuditDbConnectionFields ({ commit, state }) {
    /**
     * @public
     * @async
     * This action will save the audit db values if is valid and able to connect to the database.
     * @return true if success. Otherwise false
     */
    commit('startRequest')
    commit('clearError')

    try {
      const inputFields = {}
      for (const key of Object.keys(state.auditDbConnectionFields)) {
        inputFields[key] = state.auditDbConnectionFields[key].value
      }

      if (state.dirty) {
        const connectionFields = await AuditDatabaseConnectionService.save(
          inputFields
        )
        commit('setAuditDbConnectionFieldValues', connectionFields)
      } else {
        await AuditDatabaseConnectionService.save_with_no_change()
      }

      commit('setShowAuditInSettings', true)
      commit('endRequest')
      commit('notDirty')
      commit('saved')

      return true
    } catch (error) {
      if (error instanceof ConfigurationError) {
        commit('setError', {
          errorCode: error.errorCode,
          errorMessage: error.message
        })
      } else {
        commit('setError', {
          errorCode: 999,
          errorMessage:
            'Insight Server have encountered an error. Please ensure it is up and running'
        })
      }
      commit('endRequest')
      commit('setAuditDbConnectionIsValid', false)
      return false
    }
  },

  async clearAuditDbConnectionFieldsValues ({ commit, state }) {
    /**
     * @public
     * @async
     * This action will clear the audit db field values
     */
    commit('clearError')
    commit('setAuditDbConnectionIsValid', false)
    commit('clearAuditDbConnectionFieldValues', state.auditDbConnectionFields)
  },

  async disableAudit ({ commit }) {
    /**
     * @public
     * @async
     * This action will call the disable audit api. The audit will also be hidden in the menu as audit is disabled.
     * @return true if success. Otherwise false
     */
    commit('startRequest')
    commit('clearError')

    try {
      await AuditDatabaseConnectionService.disableAudit()
      commit('setShowAuditInSettings', false)

      commit('endRequest')
      commit('saved')
      return true
    } catch (error) {
      if (error instanceof ConfigurationError) {
        commit('setError', {
          errorCode: error.errorCode,
          errorMessage: error.message
        })
      } else {
        commit('setError', {
          errorCode: 999,
          errorMessage:
            'Insight Server have encountered an error. Please ensure it is up and running'
        })
      }
      commit('endRequest')
      return false
    }
  },

  async fetchEntityTypes ({ commit }) {
    commit('startGetEntityRequest')
    commit('clearError')

    try {
      const entityTypes = await DatabaseMappingService.getEntityTypes()

      commit('setEntityTypes', entityTypes)
      commit('endGetEntityRequest')
      commit('notDirty')
      return true
    } catch (error) {
      if (error instanceof ConfigurationError) {
        commit('setError', {
          errorCode: error.errorCode,
          errorMessage: error.message
        })
      } else {
        commit('setError', {
          errorCode: 999,
          errorMessage:
            'Insight Server have encountered an error. Please ensure it is up and running'
        })
      }
      commit('endGetEntityRequest')

      return false
    }
  },

  async fetchIndexDetails ({ commit }) {
    // commit('startGetEntityRequest')
    commit('clearError')

    try {
      const indexDetails = await DatabaseIndexService.getIndexDetailsFromInsightIndexAPI()

      commit('setIndexDetails', indexDetails)
      // commit('endGetEntityRequest')
      commit('notDirty')
      return true
    } catch (error) {
      if (error instanceof ConfigurationError) {
        commit('setError', {
          errorCode: error.errorCode,
          errorMessage: error.message
        })
      } else {
        commit('setError', {
          errorCode: 999,
          errorMessage:
            'Insight Server have encountered an error. Please ensure it is up and running'
        })
      }
      commit('endGetEntityRequest')

      return false
    }
  },

  async fetchLinkTypes ({ commit }) {
    commit('startGetLinkRequest')
    commit('clearError')

    try {
      const linkTypes = await DatabaseMappingService.getLinkTypes()

      commit('setLinkTypes', linkTypes)
      commit('endGetLinkRequest')
      commit('notDirty')
      return true
    } catch (error) {
      if (error instanceof ConfigurationError) {
        commit('setError', {
          errorCode: error.errorCode,
          errorMessage: error.message
        })
      } else {
        commit('setError', {
          errorCode: 999,
          errorMessage:
            'Insight Server have encountered an error. Please ensure it is up and running'
        })
      }
      commit('endGetLinkRequest')
      return false
    }
  },

  async fetchAdSetupFields ({ commit }) {
    commit('startRequest')
    commit('clearError')
    // Clear system field
    commit('setSystemFields', null)

    try {
      // Retrieve Active Directory fields and values
      const activeDirectorySetupFields = await AuthenticationSetupService.options()
      let activeDirectoryValues = await AuthenticationSetupService.get()

      // If there are pre-existing Active Directory settings
      if (Array.isArray(activeDirectoryValues) && activeDirectoryValues.length > 0) {
        activeDirectoryValues = activeDirectoryValues[0] // Reassign

        // Populating activeDirectoryValues to activeDirectorySetupFields
        for (const key of Object.keys(activeDirectorySetupFields)) {
          activeDirectorySetupFields[key].value = activeDirectoryValues[key]
          activeDirectorySetupFields[key].error = null
        }

        commit('setSystemFields', systemFieldsUtil.getSystemFields(activeDirectoryValues))
        commit('setAdSetupIsValid', false)
      } else {
        // Input default choices
        for (const key of Object.keys(activeDirectorySetupFields)) {
          if (activeDirectorySetupFields[key].type === 'choice') {
            activeDirectorySetupFields[key].value = activeDirectorySetupFields[key].choices[0].value
          }
          activeDirectorySetupFields[key].error = null
        }
      }

      commit('setAdSetupFields', systemFieldsUtil.removeSystemFields(activeDirectorySetupFields))

      commit('endRequest')
      commit('notDirty')
      return true
    } catch (error) {
      if (error instanceof ConfigurationError) {
        commit('setError', {
          errorCode: error.errorCode,
          errorMessage: error.message
        })
      } else {
        commit('setError', {
          errorCode: 999,
          errorMessage:
            'Insight Server have encountered an error. Please ensure it is up and running'
        })
      }
      commit('endRequest')
      return false
    }
  },

  async checkAdSetupFields ({ commit, state }) {
    commit('startRequest')
    commit('clearError')

    try {
      const checkFields = {}
      // TODO: eslint 6.8.0 false-positive bug on no-unused-vars. To be resolved when it is fixed in newer version
      // eslint-disable-next-line no-unused-vars
      for (const key of Object.keys(state.adSetupFields)) {
        checkFields[key] = state.adSetupFields[key].value
      }
      const setupFields = await AuthenticationSetupService.check(checkFields)

      commit('setAdSetupFieldValues', setupFields)
      commit('endRequest')
      commit('notDirty')
      commit('setAdSetupIsValid', true)
      return true
    } catch (error) {
      if (error instanceof ConfigurationError) {
        commit('setError', {
          errorCode: error.errorCode,
          errorMessage: error.message
        })
      } else {
        commit('setError', {
          errorCode: 999,
          errorMessage:
            'Insight Server have encountered an error. Please ensure it is up and running'
        })
      }
      commit('endRequest')
      commit('setAdSetupIsValid', false)
      return false
    }
  },

  async saveAdSetupFields ({ commit, state }) {
    commit('startRequest')
    commit('clearError')

    try {
      const inputFields = {}
      // TODO: eslint 6.8.0 false-positive bug on no-unused-vars. To be resolved when it is fixed in newer version
      // eslint-disable-next-line no-unused-vars
      for (const key of Object.keys(state.adSetupFields)) {
        inputFields[key] = state.adSetupFields[key].value
      }

      const setupFields = await AuthenticationSetupService.save(inputFields)

      commit('setAdSetupFieldValues', setupFields)
      commit('endRequest')
      commit('notDirty')
      commit('saved')
      return true
    } catch (error) {
      if (error instanceof ConfigurationError) {
        commit('setError', {
          errorCode: error.errorCode,
          errorMessage: error.message
        })
      } else {
        commit('setError', {
          errorCode: 999,
          errorMessage:
            'Insight Server have encountered an error. Please ensure it is up and running'
        })
      }
      commit('endRequest')
      commit('setAdSetupIsValid', false)
      return false
    }
  },
  async fetchAuthMethodFields ({ commit }) {
    commit('startRequest')
    commit('clearError')
    // Clear system field
    commit('setSystemFields', null)

    try {
      const setupFields = await AuthenticationMethodService.options()

      for (const key of Object.keys(setupFields)) {
        setupFields[key].error = null
        setupFields[key].value = null
        if (setupFields[key].type === 'boolean') {
          setupFields[key].type = 'switch'
          setupFields[key].switchLabel = 'Off'
        }
      }

      commit(
        'setAuthMethodFields',
        systemFieldsUtil.removeSystemFields(setupFields)
      )

      const setupFieldValues = await AuthenticationMethodService.get()
      if (Array.isArray(setupFieldValues) && setupFieldValues.length) {
        commit('setAuthMethodFieldsValues', setupFieldValues[0])
        commit(
          'setSystemFields',
          systemFieldsUtil.getSystemFields(setupFieldValues[0])
        )
      }
      commit('endRequest')
      commit('notDirty')
      return true
    } catch (error) {
      if (error instanceof ConfigurationError) {
        commit('setError', {
          errorCode: error.errorCode,
          errorMessage: error.message
        })
      } else {
        commit('setError', {
          errorCode: 999,
          errorMessage:
            'Insight Server have encountered an error. Please ensure it is up and running'
        })
      }
      commit('endRequest')
      return false
    }
  },
  async saveAuthMethod ({ commit, state }) {
    commit('startRequest')
    commit('clearError')

    try {
      const inputFields = {}
      for (const key of Object.keys(state.auth_options)) {
        inputFields[key] = state.auth_options[key].value

        // If input type is a boolean type and is required, set the value to default false instead of null
        if ((state.auth_options[key].type === 'boolean' || state.auth_options[key].type === 'switch') &&
          state.auth_options[key].required === true && inputFields[key] === null) {
          inputFields[key] = false
        }
      }

      const setupFields = await AuthenticationMethodService.save(inputFields)
      commit('setAuthMethodFieldsValues', setupFields)
      commit('endRequest')
      commit('notDirty')

      if (state.auth_options.options.value !== 'AD') {
        // 'saved' for AD will be committed in saveAdSetupFields to prevent duplicate success toastr messages
        commit('saved')
      }
      return true
    } catch (error) {
      if (error instanceof ConfigurationError) {
        commit('setError', {
          errorCode: error.errorCode,
          errorMessage: error.message
        })
      } else {
        commit('setError', {
          errorCode: 999,
          errorMessage:
            'Insight Server have encountered an error. Please ensure it is up and running'
        })
      }
      commit('endRequest')
      // commit('setAdSetupIsValid', false)
      return false
    }
  },

  async syncActiveDirectory ({ commit }, { username, password }) {
    commit('startRequest')
    commit('clearError')

    try {
      const results = await ActiveDirectorySyncService.sync({
        username: username,
        password: password
      })

      for (const userState in results) {
        Vue.set(results[userState], 'currentPage', 1)
      }

      commit('endRequest')
      return results
    } catch (error) {
      if (error instanceof ConfigurationError) {
        commit('setError', {
          errorCode: error.errorCode,
          errorMessage: 'Please enter a valid set of credentials'
        })
      } else {
        commit('setError', {
          errorCode: 999,
          errorMessage:
            'Insight Server have encountered an error. Please ensure it is up and running'
        })
      }
      commit('endRequest')
      commit('setAdSetupIsValid', false)
      return null
    }
  },

  async generateOneModel ({ commit }, { data, name }) {
    commit('startModelGenerationRequest')
    try {
      await DatabaseMappingService.generateModelWithId(data)
      commit('endModelGenerationRequest')
      return true
    } catch (error) {
      if (error instanceof ConfigurationError) {
        commit('setGeneratingErrors', {
          name: name,
          errorMessage: error.message
        })
      } else {
        commit('setError', {
          errorCode: 999,
          errorMessage:
            'Insight Server have encountered an error. Please ensure it is up and running'
        })
      }
      commit('endModelGenerationRequest')
      return false
    }
  },

  async deleteGeneratedModels ({ commit }) {
    commit('startModelGenerationRequest')
    commit('clearError')

    try {
      await DatabaseMappingService.deleteAllGeneratedModels()
      commit('endModelGenerationRequest')
      return true
    } catch (error) {
      if (error instanceof ConfigurationError) {
        commit('setError', {
          errorCode: error.errorCode,
          errorMessage: error.message
        })
      } else {
        commit('setError', {
          errorCode: 999,
          errorMessage:
            'Insight Server have encountered an error. Please ensure it is up and running'
        })
      }
      commit('endModelGenerationRequest')

      return false
    }
  },
  async restartInsightServer ({ commit }) {
    commit('clearError')
    try {
      const serverStatus = await DatabaseMappingService.restartInsightServer()
      return serverStatus
    } catch (error) {
      commit('setError', {
        errorCode: error.errorCode,
        errorMessage: error.message
      })
    }
  },
  /**
   * Call the generate elastic search required models API which will return the server once done. If there are any
   * errors, the error code and message will be set to the error state. Otherwise, the server status is returned
   * @param commit
   * @return {Promise<*>}
   */
  async generateElasticSearchModelAndRestartInsightServer ({ commit }) {
    commit('clearError')
    try {
      const serverStatus = await DatabaseMappingService.generateElasticSearchModelAndRestartInsightServer()
      return serverStatus
    } catch (error) {
      commit('setError', {
        errorCode: error.errorCode,
        errorMessage: error.message
      })
    }
  },
  clearGeneratingErrors ({ commit }) {
    commit('clearError')
  },
  async dropSourceDBConnection ({ commit }) {
    commit('clearError')
    try {
      const serverStatus = await MaintenanceService.dropSourceDBConnection()
      return serverStatus
    } catch (error) {
      commit('setError', {
        errorCode: error.errorCode,
        errorMessage: error.message
      })
    }
  },
  /**
   * @public
   * @async
   * This action clears error, then calls the service to fetch and store security groups from the server. Returns true on success, and false on failure along with committing the error.
   * @param commit
   * @returns true if successful, else commits error and returns false.
   */
  async fetchSecurityGroups ({ commit }) {
    try {
      await DatabaseMappingService.fetchSecurityGroups()
      return true
    } catch (error) {
      if (error instanceof ConfigurationError) {
        commit('setGeneratingErrors', {
          name: 'Security Groups',
          errorMessage: error.message
        })
      } else {
        commit('setError', {
          errorCode: 999,
          errorMessage:
            'Insight Server have encountered an error. Please ensure it is up and running'
        })
      }
      return false
    }
  },
  /**
   * @public
   * @async
   * This action fetch the both fields and data for the database index scheduler.
   * @param commit
   * @return {Promise<boolean|string|*>}
   */
  async fetchDatabaseIndexSchedulerFields ({ commit }) {
    commit('startRequest')
    commit('clearError')

    try {
      const setupFields = await DatabaseIndexSchedulerService.options()
      const setupFieldValues = await DatabaseIndexSchedulerService.get()

      if (setupFieldValues.schedule_type === 'H') {
        // Hourly schedule values are in a '*/{HOUR}' format. This is to strip the '*/' away from the actual value
        setupFieldValues.hour = setupFieldValues.hour.substring(2)
      }
      for (const key of Object.keys(setupFields)) {
        if (key === 'scheduler_enabled') {
          setupFields[key].type = 'switch'
          setupFields[key].switchLabel = 'On'
        } else if (key === 'schedule_type') {
          setupFields[key].type = 'choice'
          setupFields[key].choices = [
            { value: 'H', display_name: 'Hourly' },
            { value: 'F', display_name: 'Daily At' }
          ]
        } else if (key === 'hour') {
          setupFields[key].type = 'choice'
          // This generates an Array containing objects of number 0 to 23
          setupFields[key].choices = Array(24)
            .fill(1)
            .map((element, index) => {
              return {
                value: index.toString(),
                display_name: index.toString()
              }
            })
        } else if (key === 'minute') {
          setupFields[key].type = 'choice'
          // This generates an Array containing objects of number 0 to 59
          setupFields[key].choices = Array(60)
            .fill(1)
            .map((element, index) => {
              return {
                value: index.toString(),
                display_name: index.toString()
              }
            })
        }
        setupFields[key].error = null
        setupFields[key].value = setupFieldValues[key]
        setupFields[key].visible = true
        setupFields[key].readOnly = false
      }

      commit('endRequest')
      commit('notDirty')
      return setupFields
    } catch (error) {
      if (error instanceof ConfigurationError) {
        commit('setError', {
          errorCode: error.errorCode,
          errorMessage: error.message
        })
      } else {
        commit('setError', {
          errorCode: 999,
          errorMessage:
            'Insight Server have encountered an error. Please ensure it is up and running'
        })
      }
      commit('endRequest')
      return false
    }
  },
  /**
   * @public
   * @async
   * This action formats and call the service save function to update the index scheduler
   * @param commit
   * @param state
   * @param dbIndexSchedulerFields
   * @return {Promise<boolean>}
   */
  async saveDatabaseIndexScheduler ({ commit, state }, dbIndexSchedulerFields) {
    commit('startRequest')
    commit('clearError')

    try {
      const inputFields = {}

      for (const key of Object.keys(dbIndexSchedulerFields)) {
        inputFields[key] = dbIndexSchedulerFields[key].value
      }

      if (inputFields.schedule_type === 'H') {
        // If is hourly interval type, append '*/' to the hour value
        inputFields.hour = '*/' + inputFields.hour
        inputFields.minute = '0'
        inputFields.timezone = 'UTC'
      }

      delete inputFields.schedule_type

      await DatabaseIndexSchedulerService.save(inputFields)

      commit('endRequest')
      commit('notDirty')
      commit('saved')
      return true
    } catch (error) {
      if (error instanceof ConfigurationError) {
        commit('setError', {
          errorCode: error.errorCode,
          errorMessage: error.message
        })
      } else {
        commit('setError', {
          errorCode: 999,
          errorMessage:
            'Insight Server have encountered an error. Please ensure it is up and running'
        })
      }
      commit('endRequest')
      return false
    }
  }
}

import ApiService from '@/modules/auth/services/api.service'
import { values, mapValues } from 'lodash'
import { ConfigurationError } from '../errors/configuration_error'

function arrayToObject (array, key) {
  const output = {}
  // TODO: eslint 6.8.0 false-positive bug on no-unused-vars. To be resolved when it is fixed in newer version
  // eslint-disable-next-line no-unused-vars
  for (const index in array) {
    const item = array[index]
    output[item[key]] = item
  }

  return output
}

const ModuleService = {
  get: async function () {
    try {
      const response = await ApiService.get('/config/licence/')
      if (response.data.length === 0) {
        return {
          companyId: null,
          modules: {},
          systemFields: null
        }
      }

      const modules = []

      if (response.data.licences.length > 0) {
        // TODO: eslint 6.8.0 false-positive bug on no-unused-vars. To be resolved when it is fixed in newer version
        // eslint-disable-next-line no-unused-vars
        for (const index in response.data.licences) {
          modules.push({
            id: response.data.licences[index].module_id,
            name: response.data.licences[index].module_name,
            subModules: response.data.licences[index].sub_modules,
            license: response.data.licences[index].license_key !== '' ? response.data.licences[index].license_key : null,
            active: response.data.licences[index].license_key !== '',
            numOfSeats: response.data.licences[index].num_of_seats,
            endDate: response.data.licences[index].end_date,
            error: null
          })
        }
      }

      return {
        companyId: response.data.company_id === '' ? null : response.data.company_id,
        modules: arrayToObject(modules, 'id'),
        systemFields: !response.data.created_on ? null : {
          created_by: response.data.created_by,
          updated_by: response.data.updated_by,
          created_on: response.data.created_on,
          updated_on: response.data.updated_on
        }
      }
    } catch (error) {
      throw new ConfigurationError(error.response.status, error.response.data)
    }
  },

  check: async function (companyId, moduleId, licenseKey, numOfSeats, endDate) {
    try {
      const response = await ApiService.get('/config/licence/check_single_licence/', {
        company_id: companyId,
        module_id: moduleId,
        license_key: licenseKey,
        num_of_seats: numOfSeats,
        end_date: endDate
      })
      if (response.status === 200) {
        return true
      }
      return false
    } catch (error) {
      throw new ConfigurationError(error.response.status, error.response.data)
    }
  },

  sync: async function (tempCompanyId, tempModules) {
    try {
      const response = await ApiService.post('/config/licence/', {
        company_id: tempCompanyId,
        licences: values(mapValues(tempModules, (m) => {
          return {
            module_id: m.id,
            license_key: m.license === null ? '' : m.license,
            num_of_seats: m.numOfSeats,
            end_date: m.endDate
          }
        }))
      })

      const modules = []

      if (response.data.licences.length > 0) {
        // TODO: eslint 6.8.0 false-positive bug on no-unused-vars. To be resolved when it is fixed in newer version
        // eslint-disable-next-line no-unused-vars
        for (const index in response.data.licences) {
          modules.push({
            id: response.data.licences[index].module_id,
            name: response.data.licences[index].module_name,
            subModules: response.data.licences[index].sub_modules,
            license: response.data.licences[index].license_key !== '' ? response.data.licences[index].license_key : null,
            active: response.data.licences[index].license_key !== '',
            numOfSeats: response.data.licences[index].num_of_seats,
            endDate: response.data.licences[index].end_date,
            error: false
          })
        }
      }

      return {
        companyId: response.data.company_id === '' ? null : response.data.company_id,
        modules: arrayToObject(modules, 'id'),
        systemFields: !response.data.created_on ? null : {
          created_by: response.data.created_by,
          updated_by: response.data.updated_by,
          created_on: response.data.created_on,
          updated_on: response.data.updated_on
        }
      }
    } catch (error) {
      let message = error.response.data
      if (message.licences) {
        message = message.licences.filter(m => m.non_field_errors).map(m => m.non_field_errors[0]).join(' and ')
      }

      throw new ConfigurationError(error.response.status, message)
    }
  }
}

export { ModuleService, ConfigurationError }

export default ModuleService

import { GroupServices } from '@/modules/config/services/group_service.js'
import { UserServices } from '@/modules/config/services/user_service.js'

export default {
  /**
   * getUsers action makes use of the getUsers declared in UserServices fetch data from the BE.
   * getUsers action's logioc flow is determined by the isAppend param, when isAppend is true, users information would be fetched from the BE, and users would be fetched and set into the vuex state. If isAppend is false, the information would be fetched from the backend and would be set to the vuex state 'usersInformation' without appending extra users.
   * @param {{context object},{params,isAppend}} - context object is injected automatically by vueJS | params include configuration such as 'perPage', 'searchTerm' and sorting | isAppend is used to determine whether extra users would be appended to the vuex state 'usersInformation'
   * @return Boolean
   */
  getUsers: async ({ commit, state }, { params, isAppend }) => {
    commit('clearError')
    try {
      const userData = await UserServices.getUsers(params)
      if (isAppend === true) {
        //  appending the users to the existing userslist in vuex state
        for (const user of userData.results) {
          state.usersInformation.results.push(user)
        }
        commit('appendUsers', userData)
      } else {
        commit('setUsers', userData)
      }
      return true
    } catch (error) {
      commit('setError', {
        code: error.errorCode,
        message: error.message
      })
      return false
    }
  },
  /**
   * createUsers is a vuex action that makes a service call to create users. If an exception is
   * thrown, the mutation 'setError' will update the vuex state error.
   * @param {context object}
   * @param {object} - data object that has been formatted with key value pairs corresponding to
   * the fields defined in the backend
   * @returns {boolean}
   */
  createUsers: async ({ commit }, data) => {
    commit('clearError')
    try {
      await UserServices.createUsers(data)
      return true
    } catch (error) {
      commit('setError', {
        code: error.errorCode,
        message: error.message,
        showToast: error.showToast
      })
      return false
    }
  },
  /**
   * getUserFieldOptions makes an options call using UserServices.getUserFieldOptions() and formats the
   * return object as well as add in new key value pairs to populate the user fields. It then set
   * the vuex state 'userFieldOptions' to the formatted data. If an exception is thrown is userservice,
   * it will be caught by the catch block and a setError mutation will be triggered populate the
   * vuex state for error.
   * @param {context object}
   * @returns {boolean}
   */
  getUserFieldOptions: async ({ commit }, route) => {
    commit('clearError')
    try {
      const response = await UserServices.getUserFieldOptions()
      const formattedData = response.data.actions.POST
      const userStatusOption = []

      delete formattedData.username.help_text
      delete formattedData.id
      for (const [key, field] of Object.entries(formattedData)) {
        if (field.type === 'boolean') {
          userStatusOption.push({ text: field.label, value: key })
          delete formattedData[key]
        } else {
          field.data = '' // adding in empty additional attribute 'data'
        }
      }
      formattedData.userStatus = {
        id: 'userStatus',
        data: [],
        type: 'checkbox',
        options: userStatusOption
      }

      // Sort in ascending alphabetical order
      formattedData.userStatus.options.sort((option1, option2) => {
        return option1.text.localeCompare(option2.text)
      })

      if (route === 'edit') {
        formattedData.password.data = 'Fake Password'
      }

      commit('setUserFieldsAndInformation', formattedData)

      return true
    } catch (error) {
      commit('setError', {
        code: error.errorCode,
        message: error.message
      })
      return false
    }
  },
  /**
   *  getUserRecord fetches a specific user record based on the private key id
   *  getUserRecord pushes data into the vuex state, userFieldOptions based on the information in response.
   *  userFieldOptions object that is used to store information would display the existing user information accordingly on edit page
   * @param {object} - context object being injected by vueJS
   * @param {integer} - private key id
   * @returns {boolean}
   */
  getUserRecord: async ({ commit, state }, id) => {
    commit('clearError')
    try {
      const userFieldOptions = state.userFieldOptions
      const response = await UserServices.getUserRecord(id)
      for (const field in response) {
        if (field === 'is_active' && response[field] === true) {
          userFieldOptions.userStatus.data.push('is_active')
        } else if (field === 'is_superuser' && response[field] === true) {
          userFieldOptions.userStatus.data.push('is_superuser')
        } else if (field === 'is_staff' && response[field] === true) {
          userFieldOptions.userStatus.data.push('is_staff')
        } else if (
          field === 'email' ||
          field === 'username' ||
          field === 'first_name' ||
          field === 'last_name'
        ) {
          userFieldOptions[field].data = response[field]
        }
      }
      return true
    } catch (error) {
      commit('setError', {
        code: error.errorCode,
        message: error.message
      })
      return false
    }
  },
  /**
   *  updateUserRecord is used to update the user information via a PUT request.
   *  user is identified based on its id.
   * @param {object} - context object injected by vueJS
   * @param {integer, object} - object with id and data
   * @returns {boolean}
   */
  updateUserRecord: async ({ commit }, { id, data, passwordDisabled }) => {
    commit('clearError')
    try {
      if (passwordDisabled) {
        delete data.password
        delete data.confirm_new_password // deleting confirm_new_password and password because they are both empty and untouched
      }
      await UserServices.updateUserRecord(id, data)
      return true
    } catch (error) {
      commit('setError', {
        code: error.errorCode,
        message: error.message,
        showToast: error.showToast
      })
      return false
    }
  },
  clearFakePassword: async ({ commit }) => {
    commit('clearError')
    commit('clearFakePassword')
  },
  insertFakePassword: async ({ commit }) => {
    commit('clearError')
    commit('insertFakePassword')
  },
  /**
   * softDeleteUser updates the user's attribute, is_deleted to become true via a PUT request.
   * If it fails valudation, error 422 will be caught in the catch block.
   *
   * @param {objec} - context object injected by vueJS
   * @param {integer} = user id that is used retrieving specific user based on its unique ID
   * @returns {boolean}
   */
  softDeleteUser: async ({ commit }, id) => {
    commit('clearError')
    try {
      await UserServices.softDeleteUser(id)
      return true
    } catch (error) {
      commit('setError', {
        code: error.errorCode,
        message: error.message
      })
      return false
    }
  },

  /**
   * GroupServices will call the api and returns an objects of keys and data to items. For this case of getGroupQueries when isAppend is true, groupQueries of Vuex state will push
   * results of the items in and commit it via mutations appendGroupQueries to set the state. If false, commit via mutations setGroupQueries.
   * Any issues with the syntax will moved to the catch block
   * @param {Function, Object}
   * @param {Object, boolean}
   * @returns Boolean
   */

  getGroupQueries: async ({ commit, state }, { params, isAppend }) => {
    commit('clearError')
    try {
      const items = await GroupServices.getQueries(params)
      if (isAppend === true) {
        for (const query of items.results.results) {
          state.groupQueries.results.push(query)
        }
        commit('appendGroupQueries', items.results)
      } else {
        commit('setGroupQueries', items.results)
      }
      return true
    } catch (error) {
      commit('setError', {
        code: error.errorCode,
        message: error.message
      })
      return false
    }
  },
  /**
   * For this case of getUserOptions, the function will call the api from the GroupServices file of
   * function userOptions to call the api to populate the response from the backend. Additional steps is
   * taken to only map out the username from the response
   * @param {Function}
   * @returns {Array, boolean}
   */

  getUserOptions: async ({ commit }) => {
    commit('clearError')
    try {
      const resultsUsers = []
      const response = await GroupServices.userOptions()
      response.forEach(obj => {
        resultsUsers.push({ id: obj.id, username: obj.username })
      })
      commit('setUserOptions', resultsUsers)
      return true
    } catch (error) {
      commit('setError', {
        code: error.errorCode,
        message: error.message
      })
      return false
    }
  },

  getSelectedUsersPermissions: async ({ commit }, id) => {
    commit('clearError')
    try {
      const response = await GroupServices.getSelectedUsersPermissions(id)
      return response
    } catch (error) {
      commit('setError', {
        code: error.errorCode,
        message: error.message
      })
      return false
    }
  },

  /**
   * For this case of postCreateGroups, the function will call the api from the GroupServices file of
   * createGroups to post the data to the backend. Any issues experienced in the backend will be caught
   * by the catch block and will then mutate the setError variable with the data received from the bakend
   * @param {Function, Object}
   * @returns {Object}
   */

  postCreateGroups: async ({ commit }, data) => {
    commit('clearError')
    try {
      await GroupServices.createGroups(data)
      return true
    } catch (error) {
      commit('setError', {
        code: error.errorCode,
        message: error.message
      })
      return false
    }
  },

  updateGroupPermission: async ({ commit }, { id, data }) => {
    commit('clearError')
    try {
      await GroupServices.updateGroupPermission(id, data)
      return true
    } catch (error) {
      commit('setError', {
        code: error.errorCode,
        message: error.message
      })
      return false
    }
  },

  updateGroup: async ({ commit }, { id, data }) => {
    commit('clearError')
    try {
      await GroupServices.updateGroup(id, data)
      return true
    } catch (error) {
      commit('setError', {
        code: error.errorCode,
        message: error.message
      })
      return false
    }
  },

  getPermissions: async ({ commit }, id) => {
    commit('clearError')
    try {
      const response = await GroupServices.getPermissionData(id)
      commit('setGroupPermission', response)
      return true
    } catch (error) {
      commit('setError', {
        code: error.errorCode,
        message: error.message
      })
      return false
    }
  },

  deleteGroup: async ({ commit }, id) => {
    commit('clearError')
    try {
      await GroupServices.deleteGroup(id)
      return true
    } catch (error) {
      commit('setError', {
        code: error.errorCode,
        message: error.message
      })
      return false
    }
  },

  getGroupInformation: async ({ commit }, id) => {
    commit('clearError')
    try {
      const response = await GroupServices.getGroupInformation(id)
      return response
    } catch (error) {
      commit('setError', {
        code: error.errorCode,
        message: error.message
      })
      return false
    }
  }
}

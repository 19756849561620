import AuditService from '../services/audit.service'
import utils from '../utils'

export default {

  fetchDataOnMount: async ({ commit }) => {
    commit('clearError')
    commit('setLoading')

    try {
      // Fetch users
      const users = await AuditService.getUsers()
      commit('setUsers', users)

      // Fetch record types
      const records = await AuditService.getRecordTypes()
      commit('setRecordTypes', records)

      // Audit is initialised and ready to proceed!
      commit('setIsInitialised')

      return true
    } catch (error) {
      commit('setError', {
        code: error.errorCode,
        message: error.message
      })

      return false
    } finally {
      commit('clearLoading')
    }
  },

  getFilterResults: async ({ commit }, { filterParams, append }) => {
    commit('clearError')
    commit('setLoading')
    commit('clearAuditCache')

    try {
      // Parse filterParams into proper urlParams
      const urlParams = utils.createUrlParams(filterParams)
      const response = await AuditService.getFilterResults(urlParams)
      const parsedFilterResults = response.results.map(filterResult => (utils.parseFilterResult(filterResult)))

      if (append) {
        // Add paginated data
        commit('appendFilterResults', parsedFilterResults)
      } else {
        // Set new data
        commit('setFilterResults', parsedFilterResults)
      }

      // Update total number of results
      commit('setTotalNumberOfResults', response.count)

      return true
    } catch (error) {
      commit('setError', {
        code: error.errorCode,
        message: error.message
      })

      return false
    } finally {
      commit('clearLoading')
    }
  },

  getAuditLog: async ({ commit }, logId) => {
    commit('clearModalError')

    try {
      const response = await AuditService.getAuditLog(logId)
      const parsedResult = utils.parseFilterResult(response)
      commit('addIntoAuditCache', parsedResult)

      return parsedResult
    } catch (error) {
      commit('setModalError', {
        code: error.errorCode,
        message: error.message
      })

      return false
    }
  },

  downloadFilterResultsAsCSV: async ({ commit }, filterParams) => {
    commit('clearModalError')

    try {
      const urlParams = utils.createUrlParams(filterParams)
      const response = await AuditService.downloadFilterResultsAsCSV(urlParams)

      return response
    } catch (error) {
      commit('setModalError', {
        code: error.errorCode,
        message: error.message
      })

      return false
    }
  },

  cleanUpComponent: ({ commit }) => {
    /**
     * Ensure audit is properly cleaned up when unmounted
     */
    commit('clearError')
    commit('clearLoading')
    commit('resetFilterResults')
    commit('resetTotalNumberOfResults')
  },

  clearError: ({ commit }) => {
    commit('clearError')
  },

  clearModalError: ({ commit }) => {
    commit('clearModalError')
  }

}

<template>
  <b-modal v-model="showModal" :title="modalTitle" hide-footer centered>

    <div class="idlemodalclass">
      <p class="my-1"><WarningIcon /></p>
      <p class="my-4">Your session is about to expire in {{ time }} seconds due to inactivity. Move mouse cursor or press key to continue the session.</p>
    </div>
  </b-modal>
</template>

<script>
import WarningIcon from '@/assets/la-warning-triangle.svg'
export default {
  name: 'IdleModal',
  components: {
    WarningIcon
  },
  props: {
    modalShow: {
      type: Boolean,
      required: false
    }
  },
  data: () => ({
    modalTitle: 'Session Expiring',
    showModal: true,
    time: 10
  }),
  created () {
    const timerId = setInterval(() => {
      this.time -= 1
      if (!this.$store.state.idleVue.isIdle) clearInterval(timerId)
      if (this.time < 1) {
        clearInterval(timerId)
        // Your logout function should be over here
        this.$router.push({ name: 'logout' })
        this.$store.state.inactivityLoggedOut = true
      }
    }, 1000)
  }
}
</script>

<style lang="scss" scoped>
  .idlemodalclass {
    display: flex;
    flex-direction: row;
    p {
      flex-grow: 0;
    }
  }
</style>

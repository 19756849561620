const routes = [
  {
    path: '/wizard',
    name: 'Wizard Index',
    // route level code-splitting
    // this generates a separate chunk ([webpackChunkName].[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "wizard" */ './views/Wizard')
  },
  {
    path: '/wizard/step-1',
    name: 'Wizard Step 1',
    // route level code-splitting
    // this generates a separate chunk ([webpackChunkName].[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "wizard" */ './views/Modules'),
    meta: {
      wizard: true
    }
  },
  {
    path: '/wizard/step-2',
    name: 'Wizard Step 2',
    // route level code-splitting
    // this generates a separate chunk ([webpackChunkName].[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "wizard" */ './views/insight/ADSetup'),
    meta: {
      wizard: true
    }
  },
  {
    path: '/wizard/step-3',
    name: 'Wizard Step 3',
    // route level code-splitting
    // this generates a separate chunk ([webpackChunkName].[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "wizard" */ './views/insight/Connection'),
    meta: {
      wizard: true
    }
  },
  {
    path: '/wizard/step-4',
    name: 'Wizard Step 4',
    // route level code-splitting
    // this generates a separate chunk ([webpackChunkName].[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(
        /* webpackChunkName: "wizard" */ './views/insight/SourceDBMapping'
      ),
    meta: {
      wizard: true
    }
  },
  {
    path: '/wizard/step-5',
    name: 'Wizard Step 5',
    // route level code-splitting
    // this generates a separate chunk ([webpackChunkName].[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(
        /* webpackChunkName: "wizard" */ './views/insight/AuditConnection'
      ),
    meta: {
      wizard: true
    }
  },
  {
    path: '/wizard/step-6',
    name: 'Wizard Step 6',
    // route level code-splitting
    // this generates a separate chunk ([webpackChunkName].[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "wizard" */ './views/WizardComplete'),
    meta: {
      wizard: true
    }
  },
  {
    path: '/settings',
    name: 'Settings',
    // route level code-splitting
    // this generates a separate chunk ([webpackChunkName].[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "settings" */ './views/settings/Index'),
    meta: {
      layout: 'DashboardLayout'
    }
  },
  {
    path: '/settings/modules',
    name: 'Settings Modules',
    // route level code-splitting
    // this generates a separate chunk ([webpackChunkName].[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "settings" */ './views/Modules'),
    meta: {
      layout: 'DashboardLayout',
      activeMenus: ['Settings']
    },
    // TODO use this to inject title bar
    // Can use !wizard
    props: {
      settings: true
    }
  },
  {
    path: '/settings/authentication',
    name: 'Settings Authentication',
    // route level code-splitting
    // this generates a separate chunk ([webpackChunkName].[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "settings" */ './views/insight/ADSetup'),
    meta: {
      layout: 'DashboardLayout',
      activeMenus: ['Settings']
    }
  },
  {
    path: '/settings/database-connection',
    name: 'Settings Database Connection',
    // route level code-splitting
    // this generates a separate chunk ([webpackChunkName].[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "settings" */ './views/insight/Connection'),
    meta: {
      layout: 'DashboardLayout',
      activeMenus: ['Settings']
    }
  },
  {
    path: '/settings/database-mappings',
    name: 'Settings Database Mappings',
    // route level code-splitting
    // this generates a separate chunk ([webpackChunkName].[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(
        /* webpackChunkName: "settings" */ './views/insight/SourceDBMapping'
      ),
    meta: {
      layout: 'DashboardLayout',
      activeMenus: ['Settings']
    }
  },
  {
    path: '/settings/drop-source-db-connection',
    name: 'Settings Drop Source Database Connection',
    // route level code-splitting
    // this generates a separate chunk ([webpackChunkName].[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(
        /* webpackChunkName: "settings" */ './views/insight/DropSourceDBConnection'
      ),
    meta: {
      layout: 'DashboardLayout',
      activeMenus: ['Settings']
    }
  },
  {
    path: '/settings/audit-database-connection',
    name: 'Settings Audit Database Connection',
    // route level code-splitting
    // this generates a separate chunk ([webpackChunkName].[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(
        /* webpackChunkName: "settings" */ './views/insight/AuditConnection'
      ),
    meta: {
      layout: 'DashboardLayout',
      activeMenus: ['Settings']
    }
  },
  {
    path: '/settings/manage-user',
    name: 'Settings Manage User',
    // route level code-splitting
    // this generates a separate chunk ([webpackChunkName].[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "settings" */ './views/admin/Index'),
    meta: {
      layout: 'DashboardLayout',
      activeMenus: ['Settings'],
      action: 'users'
    }
  },
  {
    path: '/settings/manage-group',
    name: 'Settings Manage Group',
    // route level code-splitting
    // this generates a separate chunk ([webpackChunkName].[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "settings" */ './views/admin/Index'),
    meta: {
      layout: 'DashboardLayout',
      activeMenus: ['Settings'],
      action: 'groups'
    }
  },
  {
    path: '/settings/source-database-index-status',
    name: 'Settings Source Database Index Status',
    // route level code-splitting
    // this generates a separate chunk ([webpackChunkName].[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(
        /* webpackChunkName: "settings" */ './views/insight/SourceDBIndex'
      ),
    meta: {
      layout: 'DashboardLayout',
      activeMenus: ['Settings']
    }
  },
  {
    path: '/settings/source-database-index-scheduler',
    name: 'Settings Source Database Index Scheduler',
    // route level code-splitting
    // this generates a separate chunk ([webpackChunkName].[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "settings" */ './views/insight/SourceDBIndexScheduler'),
    meta: {
      layout: 'DashboardLayout',
      activeMenus: ['Settings']
    }
  },
  {
    path: '/settings/manage-user/createuser',
    name: 'Settings Create User',
    // route level code-splitting
    // this generates a separate chunk ([webpackChunkName].[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "settings" */ './views/admin/CreateEditUser'),
    meta: {
      layout: 'DashboardLayout',
      activeMenus: ['Settings'],
      action: 'create'
    }
  },
  {
    path: '/settings/manage-user/edit/:id',
    name: 'Settings Edit User',
    // route level code-splitting
    // this generates a separate chunk ([webpackChunkName].[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "settings" */ './views/admin/CreateEditUser'),
    meta: {
      layout: 'DashboardLayout',
      activeMenus: ['Settings'],
      action: 'edit'
    }
  },
  {
    path: '/settings/manage-group/creategroup',
    name: 'Settings Create Group',
    // route level code-splitting
    // this generates a separate chunk ([webpackChunkName].[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "settings" */ './views/admin/CreateEditGroup'),
    meta: {
      layout: 'DashboardLayout',
      activeMenus: ['Settings'],
      action: 'create'
    }
  },
  {
    path: '/settings/manage-group/editgroup/:id',
    name: 'Settings Edit Group',
    // route level code-splitting
    // this generates a separate chunk ([webpackChunkName].[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "settings" */ './views/admin/CreateEditGroup'),
    meta: {
      layout: 'DashboardLayout',
      activeMenus: ['Settings'],
      action: 'edit'
    }
  }
]

export default routes

export default {
  clearError (state) {
    state.error = false
    state.errorCode = 0
  },

  setError (state, { code, message, showToast }) {
    state.error = message
    state.errorCode = code
    state.showToast = showToast
  },

  appendGroupQueries (state, queries) {
    state.groupQueries.next = queries.next
  },

  setGroupQueries (state, queries) {
    state.groupQueries = queries
  },

  appendUsers (state, users) {
    state.usersInformation.next = users.next
  },

  setUsers (state, users) {
    state.usersInformation = users
  },

  setUserOptions (state, data) {
    state.userOptions = data
  },

  clearFakePassword (state) {
    state.userFieldOptions.password.data = ''
  },

  insertFakePassword (state) {
    state.userFieldOptions.password.data = 'FakePassword'
  },

  setUserFieldsAndInformation (state, userFieldOptions) {
    state.userFieldOptions = userFieldOptions
  },

  setGroupPermission (state, queries) {
    state.groupPermissionList = queries
  }
}

import ApiService from '@/modules/auth/services/api.service'

class FavouriteError extends Error {
  constructor (errorCode, message) {
    super(message)
    this.name = this.constructor.name
    this.message = message
    this.errorCode = errorCode
  }
}

const FavouriteService = {
  getFavouriteList: async function (username, itemType = '') {
    try {
      let response
      if (itemType) {
        response = await ApiService.get(`/insight/personalisation/${username}/get_starred/?item_type=${itemType}`)
      } else {
        response = await ApiService.get(`/insight/personalisation/${username}/get_starred/`)
      }
      return response.data
    } catch (error) {
      throw new FavouriteError(error.response.status, error.response.data)
    }
  },

  makeAsFavourite: async function (username, data) {
    try {
      await ApiService.post(`/insight/personalisation/${username}/create_starred/`, data)
      return true
    } catch (error) {
      throw new FavouriteError(error.response.status, error.response.data)
    }
  },

  removeFromFavourite: async function (username, data) {
    try {
      await ApiService.post(`/insight/personalisation/${username}/delete_starred/`, data)
      return true
    } catch (error) {
      throw new FavouriteError(error.response.status, error.response.data)
    }
  }
}

export { FavouriteService, FavouriteError }
export default FavouriteService

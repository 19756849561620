export default {
  modules: {},
  companyId: null,
  numOfSeats: null,
  errorCode: 0,
  error: '',
  loading: false,
  saved: false,
  dirty: false,
  restarting: false,
  systemFields: null
}

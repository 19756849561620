import ApiService from '@/modules/auth/services/api.service'
import handlers from '../../../utils/error_handler'

const TARGET = 'search'

class SearchError extends Error {
  constructor (errorResponse) {
    super()
    this.name = this.constructor.name
    this.message = handlers.ErrorHandler(TARGET, errorResponse)
    this.errorCode = errorResponse.status
  }
}
const SearchService = {
  getSearchResultList: async function (params) {
    try {
      const response = await ApiService.get('/insight/search/', params)
      return response.data
    } catch (error) {
      throw new SearchError(error.response)
    }
  }
}

export { SearchService, SearchError }
export default SearchService

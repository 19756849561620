import ApiService from '@/modules/auth/services/api.service'
import handlers from '../../../utils/error_handler'

const TARGET = 'form'

class FormError extends Error {
  constructor (errorResponse) {
    super()
    this.name = this.constructor.name
    this.message = handlers.ErrorHandler(TARGET, errorResponse)
    this.errorCode = errorResponse.status
  }
}

const FormService = {
  getFormList: async function (params) {
    try {
      const response = await ApiService.get('/dynamic_forms/', params)
      return response.data
    } catch (error) {
      throw new FormError(error.response)
    }
  },
  getForm: async function (id, params) {
    try {
      const response = await ApiService.get(`/dynamic_forms/${id}/`, params)
      return response.data
    } catch (error) {
      throw new FormError(error.response)
    }
  },
  getFormFields: async function (modelType, isCreate = true, isCompositeForm = false) {
    try {
      const model = modelType ? `&model=${modelType}` : ''
      const mode = isCreate ? 'create' : 'edit'
      const formType = isCompositeForm ? 'composite' : 'single'
      const response = await ApiService.get(`/dynamic_forms/get_form_fields/?mode=${mode}${model}&type=${formType}`)
      return response.data
    } catch (error) {
      throw new FormError(error.response)
    }
  },
  createModel: async (id, data) => {
    try {
      const response = await ApiService.post(`/dynamic_forms/${id}/create_or_update_model/`, data)

      return response.data
    } catch (error) {
      throw new FormError(error.response)
    }
  },
  createForm: async (data) => {
    try {
      const response = await ApiService.post('/dynamic_forms/', data)

      return response.data
    } catch (error) {
      throw new FormError(error.response)
    }
  },
  createCompositeForm: async (data) => {
    try {
      const response = await ApiService.post('/dynamic_forms/create_composite_form/', data)

      return response.data
    } catch (error) {
      throw new FormError(error.response)
    }
  },
  updateForm: async (id, data) => {
    try {
      const response = await ApiService.put(`/dynamic_forms/${id}/`, data)
      return response.data
    } catch (error) {
      throw new FormError(error.response)
    }
  },
  deleteForm: async (id) => {
    try {
      const response = await ApiService.delete(`/dynamic_forms/${id}/`)
      return response.status
    } catch (error) {
      throw new FormError(error.response)
    }
  },
  choicesPermittedGroup: async (model) => {
    try {
      const response = await ApiService.get(`/dynamic_forms/choices/?model=${model}`)
      return response.data
    } catch (error) {
      throw new FormError(error.response)
    }
  },
  getPermissions: async () => {
    try {
      const response = await ApiService.get('/dynamic_forms/build_permissions/')
      return response.data
    } catch (error) {
      throw new FormError(error.response)
    }
  },
  getFavouritedFormData: async (id) => {
    try {
      const response = await ApiService.get(`/dynamic_forms/${id}/get_form_data/`)
      return response.data
    } catch (error) {
      throw new FormError(error.response)
    }
  },
  getCompositeFormStructure: async (formId) => {
    try {
      const response = await ApiService.get(`/dynamic_forms/${formId}/?structure_only=1`)
      return response.data
    } catch (error) {
      throw new FormError(error.response)
    }
  }

}

export { FormService, FormError }
export default FormService

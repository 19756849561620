import ApiService from '@/modules/auth/services/api.service'
import { ConfigurationError } from '../errors/configuration_error'

const AuthenticationMethodService = {
  options: async function () {
    try {
      const response = await ApiService.options('/config/auth_method/')
      return response.data.actions.POST
    } catch (error) {
      throw new ConfigurationError(error.response.status, error.response.data)
    }
  },
  get: async function () {
    try {
      const response = await ApiService.get('/config/auth_method/')
      return response.data
    } catch (error) {
      throw new ConfigurationError(error.response.status, error.response.data)
    }
  },
  save: async function (data) {
    try {
      const response = await ApiService.post('/config/auth_method/', data)
      if (response.data.options === 'LA') {
        await ApiService.get('/config/adsetup/ad_settings_registration/')
      }
      return response.data
    } catch (error) {
      throw new ConfigurationError(error.response.status, error.response.data)
    }
  }
}

export { AuthenticationMethodService, ConfigurationError }

export default AuthenticationMethodService

import Vue from 'vue'

import ModelService from '../services/model.service'
import FavouriteService from '../services/favourite.service'
import { nowISO, ISOtoString, setValues, setFormData, cleanItems } from '@/modules/insight/utils'

export default {
  getEntities: async ({ commit }, cleanTheItems = true) => {
    commit('clearError')
    try {
      const items = await ModelService.getEntities()
      const cleanedItems = cleanTheItems ? cleanItems(items) : items
      commit('setEntities', cleanedItems)

      return true
    } catch (error) {
      commit('setError', {
        code: error.errorCode,
        message: error.message
      })
      return false
    }
  },
  getUnfilteredEntities: async ({ commit }) => {
    commit('clearError')
    try {
      const items = await ModelService.getEntities()
      commit('setEntities', items)

      return true
    } catch (error) {
      commit('setError', {
        code: error.errorCode,
        message: error.message
      })
      return false
    }
  },
  getLinks: async ({ commit }, cleanTheItems = true) => {
    commit('clearError')
    try {
      const items = await ModelService.getLinks()
      const cleanedItems = cleanTheItems ? cleanItems(items) : items
      commit('setLinks', cleanedItems)
      return true
    } catch (error) {
      commit('setError', {
        code: error.errorCode,
        message: error.message
      })

      return false
    }
  },
  getRecords: async ({ commit }, { model, params, append }) => {
    commit('clearSearchError')
    try {
      const items = await ModelService.getRecords(model, params)
      if (append === true) {
        commit('appendRecords', items)
      } else {
        commit('setRecords', items)
      }

      return true
    } catch (error) {
      commit('setSearchError', {
        code: error.errorCode,
        message: error.message
      })

      return false
    }
  },
  deleteRecord: async ({ commit }, { model, id }) => {
    commit('clearError')
    try {
      await ModelService.deleteRecord(model, id)

      return true
    } catch (error) {
      commit('setError', {
        code: error.errorCode,
        message: error.message
      })

      return false
    }
  },
  // Depreciated? Nothing is using this
  getFields: async ({ commit }, model) => {
    commit('clearError')
    try {
      const items = await ModelService.getModelsFields(model)
      commit('setFields', items)
      return true
    } catch (error) {
      commit('setError', {
        code: error.errorCode,
        message: error.message
      })
      return false
    }
  },
  getCreateFields: async ({ commit }, { model, isReturningData = false }) => {
    commit('clearError')
    try {
      const items = await ModelService.getModelCreateFields(model)
      items.fields = setValues(items.fields)
      commit('setFields', items)
      return isReturningData ? items : true
    } catch (error) {
      commit('setError', {
        code: error.errorCode,
        message: error.message
      })
      return false
    }
  },
  createRecord: async ({ commit }, {
    model, fields, additionalData = {}, isUsingFEValidation = true, isReturningError = false
  }) => {
    commit('clearError')
    try {
      // create_date is placed here as all created records will have this field.
      // However, maybe should abstract the string?
      additionalData.create_date = ISOtoString(nowISO(), Vue.prototype.$INSIGHT('INPUT_TYPES').DATETIME)
      const data = setFormData(fields, additionalData, isUsingFEValidation)
      const response = await ModelService.createRecord(model, data)
      return response
    } catch (error) {
      commit('setError', {
        code: error.errorCode,
        message: error.message
      })
      return isReturningError ? error : isReturningError
    }
  },
  getRecord: async ({ commit }, { model, id }) => {
    commit('clearError')
    try {
      const item = await ModelService.getRecord(model, id)
      item.fields = setValues(item.fields, false)
      commit('setRecord', item)
      return true
    } catch (error) {
      commit('setError', {
        code: error.errorCode,
        message: error.message
      })

      return false
    }
  },
  getRecordForDynamicForm: async ({ commit }, { modelId, formId }) => {
    commit('clearError')
    try {
      const item = await ModelService.getRecordForDynamicForm(modelId, formId)
      const linkend = item.linkend
      const model = {
        id: modelId,
        name: item.model_label,
        type: item.model_label
      }
      const customItem = { fields: [], linkend, model }
      customItem.fields = setValues(item.model_fields, false)
      commit('setRecord', customItem)

      return true
    } catch (error) {
      commit('setError', {
        code: error.errorCode,
        message: error.message
      })

      return false
    }
  },
  updateRecord: async ({ commit }, { model, id, fields, additionalData = {} }) => {
    commit('clearError')
    try {
      additionalData.unique_id = id
      // last_upd_date is placed here as all update records will have this field.
      // However, maybe should abstract the string?
      additionalData.last_upd_date = ISOtoString(nowISO(), Vue.prototype.$INSIGHT('INPUT_TYPES').DATETIME)
      const data = setFormData(fields, additionalData)
      await ModelService.updateRecord(model, id, data)
      return true
    } catch (error) {
      commit('setError', {
        code: error.errorCode,
        message: error.message
      })
      return false
    }
  },
  setSearchTerm: async ({ commit }, { searchTerm }) => {
    commit('setSearchTerm', searchTerm)
  },
  resetFields: async ({ commit }) => {
    commit('resetFields')
  },
  resetRecords: async ({ commit }) => {
    commit('resetRecords')
    commit('clearSearchError')
  },
  deleteAttachmentByFieldName: async ({ commit }, { model, id, fieldName }) => {
    commit('clearError')
    try {
      await ModelService.deleteAttachmentByFieldName(model, id, fieldName)
      return true
    } catch (error) {
      commit('setError', {
        code: error.errorCode,
        message: error.message
      })
      return false
    }
  },
  getAdvancedSearchFields: async ({ commit }, model) => {
    try {
      const response = await ModelService.getAdvancedSearchFields(model)
      commit('setAdvancedSearchFields', response)
    } catch (error) {
      commit('setError', {
        code: error.errorCode,
        message: error.message
      })
      return false
    }
  },

  clearError: async ({ commit }) => {
    commit('clearError')
  },

  getFavouriteList: async ({ commit }, { username, itemType = '' }) => {
    try {
      const response = await FavouriteService.getFavouriteList(username, itemType)
      commit('setUserFavouriteList', response)
    } catch (error) {
      return error.message
    }
  },

  makeAsFavourite: async ({ commit, dispatch }, { username, data }) => {
    try {
      await FavouriteService.makeAsFavourite(username, data)
      dispatch('getFavouriteList', { username: username, itemType: data.item_type })
      return true
    } catch (error) {
      // commit('setError', {
      //   code: error.errorCode,
      //   message: error.message
      // })
      return error.message
    }
  },

  removeFromFavourite: async ({ commit, dispatch }, { username, data }) => {
    try {
      await FavouriteService.removeFromFavourite(username, data)
      dispatch('getFavouriteList', { username: username, itemType: data.item_type })
      return true
    } catch (error) {
      commit('setError', {
        code: error.errorCode,
        message: error.message
      })
      return error.message
    }
  },

  getAttachment: async ({ commit }, { model, id, field }) => {
    // try {
    //   return await ModelService.getAttachment(model, id, field)
    // } catch (error) {
    //   commit('setError', {
    //     code: error.errorCode,
    //     message: error.message
    //   })
    // }
    try {
      return await ModelService.getAttachment(model, id, field)
    } catch (error) {
      return error
    }
  },

  getRecordConnections: async ({ commit }, { model, id }) => {
    try {
      return await ModelService.getRecordConnections(model, id)
    } catch (error) {
      commit('setError', {
        code: error.errorCode,
        message: error.message
      })
    }
  },

  getRelevantLinks: async ({ commit }, { model }) => {
    try {
      return await ModelService.getRelevantLinks(model)
    } catch (error) {
      commit('setError', {
        code: error.errorCode,
        message: error.message
      })
    }
  },

  getDynamicForms: async ({ commit }, { model }) => {
    try {
      return await ModelService.getDynamicForms(model)
    } catch (error) {
      commit('setError', {
        code: error.errorCode,
        message: error.message
      })
    }
  },

  getSegregatedEntities: async ({ commit }, { model, linkId, targetType }) => {
    try {
      return await ModelService.getSegregatedEntities(model, linkId, targetType)
    } catch (error) {
      commit('setError', {
        code: error.errorCode,
        message: error.message
      })
    }
  },

  toggleHideLinkLabel: async ({ commit }, { isHidden }) => {
    commit('toggleHideLinkLabel', isHidden)
  },

  getIndexDisplay: async ({ commit }, { model, displayIcon, displayFields }) => {
    commit('clearError')
    try {
      return await ModelService.getIndexDisplay(model, displayIcon, displayFields)
    } catch (error) {
      commit('setError', {
        code: error.errorCode,
        message: error.message
      })
    }
  },
  getBlacklistedFileExt: async ({ commit }) => {
    commit('clearError')
    try {
      return await ModelService.getBlacklistedFileExt()
    } catch (error) {
      commit('setError', {
        code: error.errorCode,
        message: error.message
      })
    }
  },
  getMaxFileSize: async ({ commit }) => {
    commit('clearError')
    try {
      return await ModelService.getMaxFileSize()
    } catch (error) {
      commit('setError', {
        code: error.errorCode,
        message: error.message
      })
    }
  }
}

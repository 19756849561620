import Vue from 'vue'
import {
  CollapsePlugin,
  LayoutPlugin,
  CardPlugin,
  FormGroupPlugin,
  FormInputPlugin,
  InputGroupPlugin,
  ButtonPlugin,
  VBTooltipPlugin,
  TooltipPlugin,
  SpinnerPlugin,
  DropdownPlugin,
  FormPlugin,
  ModalPlugin,
  FormRadioPlugin,
  OverlayPlugin,
  ButtonGroupPlugin,
  NavPlugin,
  FormCheckboxPlugin,
  AlertPlugin,
  ImagePlugin,
  FormSelectPlugin,
  TabsPlugin,
  ButtonToolbarPlugin,
  ListGroupPlugin,
  BadgePlugin,
  LinkPlugin,
  FormTextareaPlugin,
  FormDatepickerPlugin,
  FormTimepickerPlugin,
  TablePlugin,
  ToastPlugin,
  FormTagsPlugin,
  FormFilePlugin,
  PopoverPlugin,
  PaginationPlugin
} from 'bootstrap-vue'

Vue.use(CollapsePlugin)
Vue.use(LayoutPlugin)
Vue.use(CardPlugin)

Vue.use(FormGroupPlugin)
Vue.use(FormInputPlugin)
Vue.use(FormCheckboxPlugin)
Vue.use(FormSelectPlugin)
Vue.use(InputGroupPlugin)
Vue.use(FormPlugin)
Vue.use(ModalPlugin)
Vue.use(FormTextareaPlugin)

Vue.use(ButtonPlugin)
Vue.use(ButtonGroupPlugin)

Vue.use(VBTooltipPlugin)
Vue.use(TooltipPlugin)

Vue.use(SpinnerPlugin)

Vue.use(DropdownPlugin)
Vue.use(FormRadioPlugin)
Vue.use(OverlayPlugin)
Vue.use(NavPlugin)
Vue.use(AlertPlugin)
Vue.use(ImagePlugin)
Vue.use(TabsPlugin)
Vue.use(ButtonToolbarPlugin)
Vue.use(ListGroupPlugin)
Vue.use(BadgePlugin)
Vue.use(LinkPlugin)
Vue.use(FormDatepickerPlugin)
Vue.use(FormTimepickerPlugin)
Vue.use(TablePlugin)
Vue.use(ToastPlugin)
Vue.use(FormTagsPlugin)
Vue.use(FormFilePlugin)
Vue.use(PopoverPlugin)
Vue.use(PaginationPlugin)

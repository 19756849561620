import { cloneDeep } from 'lodash'

function getSystemFields (fields) {
  let systemFields = null

  if (fields.created_by) {
    systemFields = {
      created_by: fields.created_by,
      created_on: fields.created_on,
      updated_by: fields.updated_by,
      updated_on: fields.updated_on
    }
  }

  return systemFields
}

function removeSystemFields (fields) {
  const newFields = cloneDeep(fields)
  delete newFields.created_by
  delete newFields.created_on
  delete newFields.updated_by
  delete newFields.updated_on

  return newFields
}

const systemFieldsUtil = {
  getSystemFields,
  removeSystemFields
}

export default systemFieldsUtil

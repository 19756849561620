const routes = [
  {
    // TODO Maybe test has props?
    path: '/entities/',
    name: 'entities',
    component: () => import(/* webpackChunkName: "insight" */ './views/Items'),
    meta: {
      layout: 'DashboardLayout',
      activeMenus: ['entities']
    },
    props: {
      itemType: 'entity'
    }
  },
  {
    path: '/entities/:model',
    name: 'entities-index',
    component: () => import(/* webpackChunkName: "insight" */ './views/Index'),
    meta: {
      layout: 'DashboardLayout',
      activeMenus: ['entities']
    },
    props: {
      itemType: 'entity'
    }
  },
  {
    path: '/entities/:model/create',
    name: 'create-entity',
    component: () => import(/* webpackChunkName: "insight" */ './views/entities/CreateEdit'),
    meta: {
      layout: 'DashboardLayout',
      activeMenus: ['entities'],
      action: 'create'
    }
  },
  {
    path: '/entities/:model/create/:formId',
    name: 'create-entity-dynamic-form',
    component: () => import(/* webpackChunkName: "insight" */ './views/entities/CreateEdit'),
    meta: {
      layout: 'DashboardLayout',
      activeMenus: ['entities'],
      action: 'create'
    },
    props: true
  },
  {
    path: '/entities/:model/:id/edit',
    name: 'edit-entity',
    component: () => import(/* webpackChunkName: "insight" */ './views/entities/CreateEdit'),
    meta: {
      layout: 'DashboardLayout',
      activeMenus: ['entities'],
      action: 'edit'
    }
  },
  {
    path: '/entities/:model/:id/edit/:formId',
    name: 'edit-entity-dynamic-form',
    component: () => import(/* webpackChunkName: "insight" */ './views/entities/CreateEdit'),
    meta: {
      layout: 'DashboardLayout',
      activeMenus: ['entities'],
      action: 'edit'
    }
  },
  {
    path: '/entities/:model/:id',
    name: 'entity',
    component: () => import(/* webpackChunkName: "insight" */ './views/Show'),
    meta: {
      layout: 'DashboardLayout',
      activeMenus: ['entities'],
      noScroll: true
    },
    props: {
      itemType: 'entity'
    }
  },
  {
    path: '/links/',
    name: 'links',
    component: () => import(/* webpackChunkName: "insight" */ './views/Items'),
    meta: {
      layout: 'DashboardLayout',
      activeMenus: ['links']
    },
    props: {
      itemType: 'link'
    }
  },
  {
    path: '/links/:model',
    name: 'links-index',
    component: () => import(/* webpackChunkName: "insight" */ './views/Index.vue'),
    meta: {
      layout: 'DashboardLayout',
      activeMenus: ['links']
    },
    props: {
      itemType: 'link'
    }
  },
  {
    path: '/links/:model/create',
    name: 'create-link',
    component: () => import(/* webpackChunkName: "insight" */ './views/links/CreateEdit'),
    meta: {
      layout: 'DashboardLayout',
      activeMenus: ['links'],
      action: 'create'
    },
    props: true
  },
  {
    path: '/links/:model/create/:formId',
    name: 'create-link-dynamic-form',
    component: () => import(/* webpackChunkName: "insight" */ './views/links/CreateEdit'),
    meta: {
      layout: 'DashboardLayout',
      activeMenus: ['links'],
      action: 'create'
    },
    props: true
  },
  {
    path: '/links/:model/:id/edit',
    name: 'edit-link',
    component: () => import(/* webpackChunkName: "insight" */ './views/links/CreateEdit'),
    meta: {
      layout: 'DashboardLayout',
      activeMenus: ['links'],
      action: 'edit'
    }
  },
  {
    path: '/links/:model/:id/edit/:formId',
    name: 'edit-link-dynamic-form',
    component: () => import(/* webpackChunkName: "insight" */ './views/links/CreateEdit'),
    meta: {
      layout: 'DashboardLayout',
      activeMenus: ['links'],
      action: 'edit'
    }
  },
  {
    path: '/links/:model/:id',
    name: 'link',
    component: () => import(/* webpackChunkName: "insight" */ './views/Show.vue'),
    meta: {
      layout: 'DashboardLayout',
      activeMenus: ['links'],
      noScroll: true
    },
    props: {
      itemType: 'link'
    }
  },
  {
    path: '/queries',
    name: 'queries',
    component: () => import(/* webpackChunkName: "insight" */ './views/queries/Index.vue'),
    meta: {
      layout: 'DashboardLayout',
      activeMenus: ['queries']
    }
  },
  {
    path: '/queries/create',
    name: 'create-query',
    component: () => import(/* webpackChunkName: "insight" */ './views/queries/CreateEdit.vue'),
    meta: {
      layout: 'DashboardLayout',
      activeMenus: ['queries'],
      action: 'create',
      noScroll: true
    }
  },
  {
    path: '/queries/:id/edit',
    name: 'edit-query',
    component: () => import(/* webpackChunkName: "insight" */ './views/queries/CreateEdit.vue'),
    meta: {
      layout: 'DashboardLayout',
      activeMenus: ['queries'],
      action: 'edit',
      noScroll: true
    }
  },
  {
    path: '/queries/:id/generate',
    name: 'generate-query',
    component: () => import(/* webpackChunkName: "insight" */ './views/queries/Generate.vue'),
    meta: {
      layout: 'DashboardLayout',
      activeMenus: ['queries']
    }
  },
  {
    path: '/composite/entities/create/:formId',
    name: 'create-entity-composite-form',
    component: () => import(/* webpackChunkName: "insight" */ './views/composite/CreateEdit'),
    meta: {
      layout: 'DashboardLayout',
      activeMenus: ['entities'],
      noScroll: true,
      action: 'create'
    },
    props: true
  }
]

export default routes

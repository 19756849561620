import Vue from 'vue'

export default {
  loggedIn: (state) => {
    return state.accessToken !== null
  },

  authenticationErrorCode: ({ authenticationErrorCode }) => authenticationErrorCode,

  authenticationError: ({ authenticationError }) => authenticationError,

  authenticating: ({ authenticating }) => authenticating,

  // used for dashboard groups
  consolidatedTabs: (state) => {
    if (!state.userData || typeof state.userData !== 'object' || typeof state.userData.user_groups !== 'object') return []
    const constGroups = Object.values(Vue.prototype.$DASHBOARD('GROUPS'))
    return [...constGroups, ...state.userData.user_groups]
  }
}

<template>
  <div class="home">
    <portal to="top-bar">
      <div class="title">
        <h1>Home</h1>
      </div>
    </portal>

    <b-container class="mt-2" v-if="displayNoUserGroupAssignedWarningMessage">
      <b-row>
        <b-col>
            <b-alert show variant="warning">{{ $LOCAL('HOME_MESSAGES').NO_USER_GROUP_ASSIGNED_MSG }}</b-alert>
        </b-col>
      </b-row>
    </b-container>

    <b-container v-if="!validFavouritesCount">
      <b-row class="justify-content-center fade-in-fast">
        <b-col cols="12" sm="10" md="6" lg="5" xl="4">
          <b-card>
            <b-card-text>
              Welcome to VA Insight
            </b-card-text>
          </b-card>
        </b-col>
      </b-row>
    </b-container>

    <!-- TODO Change to a filtered list -->
    <div class="sub-favourite"
         v-for="(items, itemType) of { form: forms, entity: entities, link: links, query: queries }"
         :key="`${itemType}-container`">
      <h2 class="ml-1 d-flex justify-content-start align-items-center" v-if="Object.keys(items).length !== 0">
        <component class="mr-1 header-icon" :is="`icon-${itemType}`"/>
        {{ label[itemType] }}
      </h2>
      <item-cards :ref="`${itemType}Cards`"
                  :prefetched="true" :itemType="itemType" :items="items" :formPermissions="formPermissions"
                  @unfavourite="unfavourite"
      />
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import { isEmpty } from 'lodash'

import QueryService from '@/modules/insight/services/query.service.js'
import FormService from '@/modules/forms/services/forms.service.js'
import ModelService from '@/modules/insight/services/model.service'

import ItemCards from '@/modules/insight/components/ItemCards'

import IconEntity from '@/assets/entity.svg'
import IconLink from '@/assets/link.svg'
import IconQuery from '@/assets/la-question-circle.svg'
import IconForm from '@/assets/forms.svg'

import INSIGHT from '@/constants/INSIGHT.json'

export default {
  name: 'home',
  components: {
    ItemCards, IconEntity, IconLink, IconQuery, IconForm
  },
  data: () => ({
    entities: {},
    links: {},
    queries: {},
    forms: {},
    label: {
      [INSIGHT.MODEL_TYPES.ENTITY]: 'Entities',
      [INSIGHT.MODEL_TYPES.LINK]: 'Links',
      [INSIGHT.MODEL_TYPES.QUERY]: 'Queries',
      [INSIGHT.MODEL_TYPES.FORM]: 'Forms'
    }
  }),
  async mounted () {
    await this.retrieveUserData()
    await this.getFavouriteList({ username: this.userData.username })
    await this.processFavouriteItems()
    this.setSearchTerm(false)
  },
  computed: {
    ...mapState('insight', {
      rawEntities: 'entities',
      rawLinks: 'links',
      error: 'error',
      userFavouriteList: 'userFavouriteList'
    }),
    ...mapState('auth', {
      userData: 'userData'
    }),
    ...mapState('forms', { formPermissions: 'userPermissions' }),
    validFavouritesCount () {
      let count = 0;
      [this.entities, this.links, this.queries, this.forms].forEach((item) => {
        count += Object.keys(item).length
      })
      return count
    },
    displayNoUserGroupAssignedWarningMessage () {
      return this.userData?.user_groups?.length === 0
    }
  },
  methods: {
    ...mapActions('insight', [
      'getLinks',
      'getEntities',
      'getFavouriteList',
      'setSearchTerm'
    ]),
    ...mapActions('insight/queries', [
      'getQueryCRUDPermissions'
    ]),
    ...mapActions('auth', [
      'retrieveUserData'
    ]),
    ...mapActions('forms', { fetchFormPermissions: 'getPermissions' }),
    unfavourite (itemType, itemName) {
      this.$delete(this[itemType], itemName)
      this.getFavouriteList({ username: this.userData.username })

      // Was entity originally
      if (itemType === 'entities') {
        this.$refs.entityCards[0].setIcons()
      }
    },
    async setFavouriteQueriesToQueriesData (item, itemName) {
      try {
        const queryData = await QueryService.getFavouritedQueryData(itemName)
        const queryPermissions = await this.getQueryCRUDPermissions()
        if (isEmpty(queryPermissions)) {
          throw new Error('no permission')
        } else {
          Object.assign(item, {
            label: queryData.name,
            permissions: queryPermissions
          })
          await this.$set(this.queries, itemName, item)
        }
      } catch (error) {
        Object.assign(item, {
          label: this.$INSIGHT('INDEX_QUERIES').ERROR.QUERY_NOT_EXISTS,
          permissions: [],
          hasNoDataFound: true,
          description: this.$INSIGHT('INDEX_QUERIES').ERROR.QUERY_NOT_EXISTS_TOOLTIP
        })
        await this.$set(this.queries, itemName, item)
      }
    },
    async setFavouriteFormsToFormsData (item, itemName) {
      try {
        const formData = await FormService.getFavouritedFormData(itemName)
        const rawEntities = await ModelService.getEntities()
        const rawLinks = await ModelService.getLinks()
        await this.fetchFormPermissions()
        if (isEmpty(this.formPermissions)) {
          throw new Error('not permitted')
        } else {
          Object.assign(item, {
            label: formData.title,
            permissions: rawEntities[formData.model] ? rawEntities[formData.model].permissions : rawLinks[formData.model].permissions,
            model_name: formData.model_label,
            model: formData.model,
            type: rawEntities[formData.model] ? 'entities' : 'links',
            form_type: formData.type
          })
          await this.$set(this.forms, itemName, item)
        }
      } catch (error) {
        Object.assign(item, {
          label: this.$FORM('ERROR').FORM_NOT_EXISTS,
          permissions: [],
          hasNoDataFound: true,
          description: this.$FORM('ADVISORY_TEXT').FORM_NOT_EXISTS_TOOLTIP
        })
        await this.$set(this.forms, itemName, item)
      }
    },
    async processFavouriteItems () {
      await this.getEntities()
      await this.getLinks()
      for (const item of this.userFavouriteList.results) {
        const itemType = item.item_type
        const itemName = item.item_name
        if (itemType) {
          if (itemType === this.$INSIGHT('MODEL_TYPES').ENTITY && this.rawEntities[itemName]) {
            await this.$set(this.entities, itemName, this.rawEntities[itemName])
            this.$refs.entityCards[0].setIcon(itemName)
          } else if (itemType === this.$INSIGHT('MODEL_TYPES').LINK && this.rawLinks[itemName]) {
            await this.$set(this.links, itemName, this.rawLinks[itemName])
          } else if (itemType === this.$INSIGHT('MODEL_TYPES').QUERY) {
            await this.setFavouriteQueriesToQueriesData(item, itemName)
          } else if (itemType === this.$INSIGHT('MODEL_TYPES').FORM) {
            await this.setFavouriteFormsToFormsData(item, itemName)
          }
        }
        this.$refs.entityCards[0].checkFavourited(this.$INSIGHT('MODEL_TYPES').ENTITY)
        this.$refs.linkCards[0].checkFavourited(this.$INSIGHT('MODEL_TYPES').LINK)
        this.$refs.queryCards[0].checkFavourited(this.$INSIGHT('MODEL_TYPES').QUERY)
        this.$refs.formCards[0].checkFavourited(this.$INSIGHT('MODEL_TYPES').FORM)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.home {
  margin: -10px;

  //Children
  display: flex;
  flex-direction: column;

  .sub-favourite {
    margin-bottom: 10px;
    h2 {
      font-size: 1.5rem;
    }
  }
}
</style>

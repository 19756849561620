const routes = [
  {
    path: '/audit/',
    name: 'audit',
    component: () => import(/* webpackChunkName: "audit" */ './views/Index'),
    meta: {
      layout: 'DashboardLayout',
      activeMenus: ['audit']
    }
  }
]

export default routes

import ApiService from '@/modules/auth/services/api.service'

const ServerService = {
  status: async function () {
    ApiService.mountServerRebootInterceptor()

    // Wait 1.5 seconds before checking if server is back up, this allows it to shutdown
    await new Promise(resolve => setTimeout(resolve, 1500))

    await ApiService.get('/config/online/')

    ApiService.unmountServerRebootInterceptor()
  }
}

export { ServerService }
export default ServerService

import ApiService from './api.service'
import TokenService from './token.service'
import AUTH from '@/constants/AUTH.json'

class AuthenticationError extends Error {
  constructor (errorCode, message) {
    super(message)
    this.name = this.constructor.name
    this.message = message
    this.errorCode = errorCode
  }
}

const UserService = {
  /**
   * Login the user and store the access token to TokenService.
   *
   * @returns access_token
   * @throws AuthenticationError
  **/
  login: async function (username, password) {
    try {
      const response = await ApiService.post('/auth/login/', {
        username: username,
        password: password
      })

      TokenService.saveRefreshTokenRotationPeriodInMilliseconds(response.data.refreshTokenRotationPeriodInMilliseconds)
      TokenService.saveToken(response.data.access)
      TokenService.saveRefreshToken(response.data.refresh)
      ApiService.setHeader()

      ApiService.mount401Interceptor()
      return response.data.access
    } catch (error) {
      if (error.response.status === 401) {
        throw new AuthenticationError(error.response.status, { detail: AUTH.LOG_IN_FORM.INVALID_FEEDBACK.INVALID_USERNAME_PASSWORD })
      }
      throw new AuthenticationError(error.response.status, error.response.data)
    }
  },

  /**
   * Changes user password
   *
   * @returns response object
   * @throws AuthenticationError
  **/
  changePassword: async function (oldPassword, newPassword, confirmPassword) {
    try {
      await ApiService.put('/auth/password-change/', {
        old_password: oldPassword,
        new_password1: newPassword,
        new_password2: confirmPassword
      })
      return true
    } catch (error) {
      throw new AuthenticationError(error.response.status, error.response.data)
    }
  },

  getChangePasswordOptions: async function () {
    try {
      const passwordOptions = await ApiService.options('auth/password-change/')
      return passwordOptions
    } catch (error) {
      return new AuthenticationError(error.response.status, error.response.message)
    }
  },

  /**
   * Refresh the access token.
  **/
  refreshToken: async function () {
    const refreshToken = TokenService.getRefreshToken()

    try {
      const response = await ApiService.post('/auth/refresh/', {
        refresh: refreshToken
      })

      TokenService.saveToken(response.data.access)
      TokenService.saveRefreshToken(response.data.refresh)

      // Update the header in ApiService
      ApiService.setHeader()

      return response.data.access
    } catch (error) {
      throw new AuthenticationError(error.response.status, error.response.data.detail)
    }
  },

  /**
   * Notify the server that the user has logged out for auditing
   */

  async sendLogoutSignal () {
    try {
      await ApiService.post('/auth/logout/')
      return true
    } catch (error) {
      throw new AuthenticationError(error.response.status, error.response.data)
    }
  },

  /**
   * Logout the current user by removing the tokens from storage.
   *
   * Remove `Authorization Bearer <token>` header from future requests.
   *
   * Remove 401 interceptor
  **/

  logout () {
    TokenService.removeToken()
    TokenService.removeRefreshToken()
    ApiService.removeHeader()
    ApiService.unmount401Interceptor()
  },

  /**
   * Check if a user has permission to access resource   *
   */
  async hasPermission (acl) {
    ApiService.mount403Interceptor()
    try {
      const response = await ApiService.get('/auth/check-permission/', {
        acl
      })

      return response.status === 200
    } catch (error) {
      return new AuthenticationError(error.response.status, error.response.message)
    } finally {
      ApiService.unmount403Interceptor()
    }
  },

  retrieveUserData: async function () {
    try {
      const response = await ApiService.get('/auth/current-user/')
      return response.data
    } catch (error) {
      return new AuthenticationError(error.response.status, error.response.message)
    }
  },

  async getBackEnd2FaEnabledForUserStatus () {
    try {
      const response = await ApiService.get('/auth/otp/get_2fa_status/')
      return response.data
    } catch (error) {
      throw new AuthenticationError(error.response.status, error.response.message)
    }
  },

  async getUser2FaDeviceRegisteredStatus () {
    try {
      const response = await ApiService.get('/auth/otp/get_otp_device_status/')
      return response.data
    } catch (error) {
      throw new AuthenticationError(error.response.status, error.response.message)
    }
  },

  async getUser2FaDeviceQrCodeBase64String () {
    try {
      const response = await ApiService.get('/auth/otp/get_otp_device_code/')
      return response.data
    } catch (error) {
      throw new AuthenticationError(error.response, error.response.message)
    }
  },

  async postOtpCodeToRegisterDeviceAsTotpToken (otpCode) {
    try {
      const response = await ApiService.post('/auth/otp/confirm_otp_token/', {
        token: otpCode
      })
      return response.data
    } catch (error) {
      throw new AuthenticationError(error.response.status, error.response.data) // need to thorw...
    }
  },

  async postOtpCodeToVerifyUserService (otpCode) {
    try {
      const response = await ApiService.post('/auth/otp/verify_user_otp_code/', {
        token: otpCode
      })

      TokenService.saveToken(response.data.access)
      TokenService.saveRefreshToken(response.data.refresh)
      ApiService.setHeader()

      return response.data
    } catch (error) {
      throw new AuthenticationError(error.response.status, error.response.data)
    }
  }
}

export default UserService

export { UserService, AuthenticationError }

export default {
  clearError (state) {
    state.error = false
    state.errorCode = 0
  },
  setError (state, { code, message }) {
    state.error = message
    state.errorCode = code
  },
  setGlobalSearchInput (state, globalSearchInput) {
    state.globalSearchInput = globalSearchInput
  },
  clearSearchResultList (state) {
    state.searchResultList.totalCount = 0
    state.searchResultList.results = []
  },
  setSearchResultList (state, items) {
    state.searchResultList.totalCount = items.hits.total.value
    state.searchResultList.results = items.hits.hits
  }
}

export default {
  setToken (state, token) {
    state.token = token
  },

  authenticationClearErrors (state) {
    state.authenticationError = ''
    state.authenticationErrorCode = 0
    state.logoutError = ''
    state.logoutErrorCode = 0
  },

  authenticationRequest (state) {
    state.authenticating = true
    state.authenticationError = ''
    state.authenticationErrorCode = 0
  },

  loginSuccess (state, accessToken) {
    state.accessToken = accessToken
    state.authenticating = false
  },

  logoutRequest (state) {
    state.authenticating = true
    state.logoutError = ''
    state.logoutErrorCode = 0
    state.otpErrorCode = 0
  },

  logoutError (state, { errorCode, errorMessage }) {
    state.authenticating = false
    state.logoutError = errorMessage
    state.logoutErrorCode = errorCode
  },

  logoutSuccess (state) {
    state.accessToken = null
    state.authenticating = false
  },

  loginError (state, { errorCode, errorMessage }) {
    state.authenticating = false
    state.authenticationErrorCode = errorCode
    state.authenticationError = errorMessage
  },

  changePasswordSuccess (state) {
    state.authenticating = false
    state.authenticationErrorCode = 0
    state.authenticationError = ''
  },

  changePasswordError (state, { errorCode, errorMessage }) {
    state.authenticating = false
    state.authenticationErrorCode = errorCode
    state.authenticationError = errorMessage
  },

  refreshTokenPromise (state, promise) {
    state.refreshTokenPromise = promise
  },

  userDataError (state, { errorCode, errorMessage }) {
    state.userDataErrorCode = errorCode
    state.userDataError = errorMessage
  },

  setUserData (state, userData) {
    state.userData = userData
  },

  resetUserData (state) {
    state.userData = {}
    state.userDataErrorCode = 0
    state.userDataError = ''
  },

  /**
   * Mutation to set that the interceptor toast has been shown to user
   * @param state Vuex state object
   */
  interceptorToastShown (state) {
    state.interceptorToastShown = true
  },

  /**
   * Mutation to set that the interceptor toast has been hidden from user
   * @param state Vuex state object
   */
  interceptorToastHidden (state) {
    state.interceptorToastShown = false
  },

  /**
   * Set a websocket connection in to the vuex state identified by the websocket url
   * @param state
   * @param connection websocket connection object
   * @param url url of the websocket connection
   */
  setWebSocketConnectionCache (state, { connection, url }) {
    state.websocketConnectionCache[url] = connection
  },

  otpDeviceRegisteredWithUserSuccess (state) {
    state.otpDeviceRegisteredWithUser = true
    state.authenticating = false
    state.otpErrorCode = 0
  },

  otpDeviceRegisteredWithUserFailed (state, { errorMessage, errorCode }) {
    state.authenticating = false
    state.otpDeviceRegisteredWithUser = false
    state.otpErrorMessage = errorMessage
    state.otpErrorCode = errorCode
  },

  otpCodeVerificationFailed (state, { errorMessage, errorCode }) {
    state.verifying = false
    state.otpErrorMessage = errorMessage
    state.otpErrorCode = errorCode
  },

  otpCodeVerificationSuccess (state) {
    state.verifying = false
    state.otpErrorCode = 0
  },

  otpVerificationRequest (state) {
    state.verifying = true
  },

  setPasswordFieldOptions (state, passwordFieldOptions) {
    state.passwordFieldOptions = passwordFieldOptions
  }
}

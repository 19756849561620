export default {
  clearError (state) {
    state.error = false
  },

  setError (state, error) {
    state.error = error
  },

  setQueries (state, queries) {
    state.queries = queries
  },

  appendQueries (state, queries) {
    // TODO: eslint 6.8.0 false-positive bug on no-unused-vars. To be resolved when it is fixed in newer version
    // eslint-disable-next-line no-unused-vars
    for (const query of queries.results) {
      state.queries.results.push(query)
    }
    state.queries.next = queries.next
  },

  setQuery (state, query) {
    state.query = query
  },

  setGeneratedQueries (state, queries) {
    const queriesMap = {}
    // TODO: eslint 6.8.0 false-positive bug on no-unused-vars. To be resolved when it is fixed in newer version
    // eslint-disable-next-line no-unused-vars
    for (const query of queries.items) {
      queriesMap[query.item_name] = query
    }
    state.queries = queriesMap
  },

  appendRecordsQueryResults (state, { records, itemId }) {
    // TODO: eslint 6.8.0 false-positive bug on no-unused-vars. To be resolved when it is fixed in newer version
    // eslint-disable-next-line no-unused-vars
    for (const record of records.results) {
      state.queries[itemId].results.push(record)
    }
  },

  setGeneratedQueriesByModel (state, { result, itemId }) {
    state.queries[itemId] = result
  },

  setQueryCRUDPermissions (state, permission) {
    state.queryCRUDPermissions = permission
  }
}

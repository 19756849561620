import { ConfigurationError } from '../errors/configuration_error'
import ApiService from '@/modules/auth/services/api.service'

const GroupServices = {
  /**
   * This getQueries method calls the api with the params passed to the backend and
   * expects a return of an object with keys and values
   * This api links to api/v1/insight_admin/ from the base url backend
   * @param {Object}
   * @returns {Object}
   */

  getQueries: async function (params) {
    try {
      const response = await ApiService.get('/insight_admin/group/', params)
      return {
        results: response.data
      }
    } catch (error) {
      throw new ConfigurationError(error.response.status, error.response.data)
    }
  },

  /**
   * This userOptions method calls the api with no arguments as the purpose for this
   * method/function is to get back the repsonse from the backend of user called UserViewSet
   * @returns {Object}
   */

  userOptions: async function () {
    try {
      const response = await ApiService.get('/insight_admin/user/?all=1')
      return response.data
    } catch (error) {
      throw new ConfigurationError(error.response.status, error.response.data)
    }
  },

  getSelectedUsersPermissions: async function (id) {
    try {
      const response = await ApiService.get(
        `/insight_admin/group/${id}/get_selected_user_permissions/`
      )
      return response.data
    } catch (error) {
      throw new ConfigurationError(error.response.status, error.response.data)
    }
  },

  /**
   * This createGroups methods calls the api with argument of data which consist of an object
   * key of name type string and users type array of numbers (if of users)
   * @param {Object}
   * @returns {Object}
   */

  createGroups: async function (data) {
    try {
      const response = await ApiService.post('/insight_admin/group/', data)
      return response.data
    } catch (error) {
      throw new ConfigurationError(error.response.status, error.response.data)
    }
  },

  updateGroupPermission: async function (id, data) {
    try {
      await ApiService.post(`/insight_admin/group/${id}/updating_user_permissions/`, data)
      return true
    } catch (error) {
      throw new ConfigurationError(error.response.status, error.response.data)
    }
  },

  updateGroup: async function (id, data) {
    try {
      await ApiService.put(`/insight_admin/group/${id}/`, data)
      return true
    } catch (error) {
      throw new ConfigurationError(error.response.status, error.response.data)
    }
  },

  getPermissionData: async function (id) {
    try {
      id = id || ''
      const response = await ApiService.get(`/insight_admin/group/get_group_permissions/?id=${id}`)
      return response.data
    } catch (error) {
      throw new ConfigurationError(error.response.status, error.response.data)
    }
  },

  deleteGroup: async function (id) {
    try {
      const response = await ApiService.delete(`/insight_admin/group/${id}/`)
      return response.data
    } catch (error) {
      throw new ConfigurationError(error.response.status, error.response.data)
    }
  },

  getGroupInformation: async function (id) {
    try {
      const response = await ApiService.get(`/insight_admin/group/${id}/`)
      return response.data
    } catch (error) {
      throw new ConfigurationError(error.response.status, error.response.data)
    }
  }
}

export { GroupServices }
export default { GroupServices }

<template>
  <div class="backdrop" v-if="open">
    <b-card v-data-cy="'server-restart-card'" class="va-card" header="Server Restarting">
      <div class="message">
        The server is currently restarting, this won't take too long.
      </div>
      <div class="spinner">
        <b-spinner style="width: 3rem; height: 3rem;" />
      </div>
    </b-card>
  </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
  name: 'ServerRestartModal',
  computed: {
    ...mapState('config', {
      open: 'restarting'
    })
  }
}
</script>

<style lang="scss" scoped>
.backdrop {
  position: absolute;
  height: 100vh;
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  padding-top: 20vh;
  background: rgba(0,0,0,0.6);
  z-index: 99998;

  .message {
    text-align: center;
    padding: 10px 25px 25px 25px;
  }
}
</style>

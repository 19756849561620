export default {
  startRequest (state) {
    state.loading = true
    state.saved = false
  },

  startGetEntityRequest (state) {
    state.entityTableLoading = true
    state.saved = false
  },

  startGetLinkRequest (state) {
    state.linkTableLoading = true
    state.saved = false
  },

  endRequest (state) {
    state.loading = false
  },

  endGetEntityRequest (state) {
    state.entityTableLoading = false
  },

  endGetLinkRequest (state) {
    state.linkTableLoading = false
  },

  clearError (state) {
    state.error = ''
    state.errorCode = 0
    state.generatingErrors = {}
  },

  setError (state, { errorCode, errorMessage }) {
    state.error = errorMessage
    state.errorCode = errorCode
  },

  saved (state) {
    state.saved = true
    state.dirty = false
  },

  notDirty (state) {
    state.dirty = false
  },

  isDirty (state) {
    state.dirty = true
  },

  setDbConnectionFields (state, data) {
    state.dbConnectionFields = data
  },

  setDbConnectionIsValid (state, bool) {
    state.dbConnectionIsValid = bool
  },

  setDbConnectionFieldValues (state, data) {
    for (const key of Object.keys(data)) {
      if (Object.prototype.hasOwnProperty.call(state.dbConnectionFields, key) && Object.prototype.hasOwnProperty.call(data, key)) {
        state.dbConnectionFields[key].value = data[key]
      }
    }
  },

  setAuditDbConnectionFields (state, data) {
    state.auditDbConnectionFields = data
  },

  setAuditDbConnectionIsValid (state, bool) {
    state.auditDbConnectionIsValid = bool
  },

  setShowAuditInSettings (state, bool) {
    state.showAuditInSettings = bool
  },

  setAuditDbConnectionFieldValues (state, data) {
    for (const key of Object.keys(data)) {
      if (Object.prototype.hasOwnProperty.call(state.auditDbConnectionFields, key) && Object.prototype.hasOwnProperty.call(data, key)) {
        state.auditDbConnectionFields[key].value = data[key]
      }
    }
  },

  clearAuditDbConnectionFieldValues (state, data) {
    for (const key of Object.keys(data)) {
      state.auditDbConnectionFields[key].value = null
    }
  },

  setEntityTypes (state, data) {
    state.entityTypes = data
  },

  setIndexDetails (state, data) {
    state.indexDetails = data
  },

  setLinkTypes (state, data) {
    state.linkTypes = data
  },

  setAdSetupFields (state, data) {
    state.adSetupFields = data
  },

  setAdSetupIsValid (state, bool) {
    state.adSetupIsValid = bool
  },

  setAdSetupFieldValues (state, data) {
    // TODO: eslint 6.8.0 false-positive bug on no-unused-vars. To be resolved when it is fixed in newer version
    // eslint-disable-next-line no-unused-vars
    for (const key of Object.keys(data)) {
      if (Object.prototype.hasOwnProperty.call(state.adSetupFields, key) && Object.prototype.hasOwnProperty.call(data, key)) {
        state.adSetupFields[key].value = data[key]
      }
    }
  },
  setAuthMethodFields (state, data) {
    state.auth_options = data
  },
  setAuthMethodFieldsValues (state, data) {
    // TODO: eslint 6.8.0 false-positive bug on no-unused-vars. To be resolved when it is fixed in newer version
    // eslint-disable-next-line no-unused-vars
    for (const key of Object.keys(data)) {
      if (Object.prototype.hasOwnProperty.call(state.auth_options, key) && Object.prototype.hasOwnProperty.call(data, key)) {
        state.auth_options[key].value = data[key]
      }
    }
  },

  startModelGenerationRequest (state) {
    state.generatingModels = true
  },

  endModelGenerationRequest (state) {
    state.generatingModels = false
  },

  setGeneratingErrors (state, { name, errorMessage }) {
    state.generatingErrors = { ...state.generatingErrors, [name]: errorMessage }
  },

  setSystemFields (state, systemFields) {
    state.systemFields = systemFields
  }
}

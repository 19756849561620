import handlers from '../../../utils/error_handler'
import ApiService from '../../auth/services/api.service'
import utils from '../utils'

const TARGET = 'audit log'

class AuditError extends Error {
  constructor (errorResponse) {
    super()
    this.name = this.constructor.name
    this.message = handlers.ErrorHandler(TARGET, errorResponse)
    this.errorCode = errorResponse.status
  }
}

const AuditService = {

  /**
   * Fetch the usernames of Insight users
   * @param {None}
   * @returns {Array} An array of usernames as strings
   * @throws {AuditError}
   */
  getUsers: async function () {
    try {
      const response = await ApiService.get('/auth/all-usernames/')
      const usernames = response.data.map(user => user.username)

      return usernames
    } catch (error) {
      throw new AuditError(error.response)
    }
  },

  /**
   * Fetch the Insight record types that are audited.
   * @param {None}
   * @returns {Array} An array of record types as strings
   * @throws {AuditError}
   */
  getRecordTypes: async function () {
    try {
      const response = await ApiService.get('/audit/record_types/')

      return response.data
    } catch (error) {
      throw new AuditError(error.response)
    }
  },

  /**
   * Fetch the filter results based on a set of filter parameters.
   * @param {Object} urlParams The values of filter parameters
   * @returns {Object} An object that describes the response. The array of results is available in the 'results' field
   * @throws {AuditError}
   */
  getFilterResults: async function (urlParams) {
    try {
      // If pass in urlParams and paramsSerializer to axios, will cause problems with 401Interceptor
      const tailUrl = utils.paramsSerializer(urlParams)
      const response = await ApiService.get(`/audit/?${tailUrl}`)

      return response.data
    } catch (error) {
      throw new AuditError(error.response)
    }
  },

  /**
   * Fetch the CSV string representation of filter results to be downloaded.
   * @param {Object} urlParams The values of filter parameters
   * @returns {String} The entire CSV string to be downloaded
   * @throws {AuditError}
   */
  downloadFilterResultsAsCSV: async function (urlParams) {
    try {
      const tailUrl = utils.paramsSerializer(urlParams)
      const response = await ApiService.get(`/audit/download/?${tailUrl}`)

      return response.data
    } catch (error) {
      throw new AuditError(error.response)
    }
  },

  /**
   * Fetch a specific audit log that is viewed by user.
   * @param {String} logId The Id of audit log viewed by user
   * @returns {Object} An object that contains the information of an audit log
   * @throws {AuditError}
   */
  getAuditLog: async function (logId) {
    try {
      const response = await ApiService.get(`/audit/${logId}/`)

      return response.data
    } catch (error) {
      throw new AuditError(error.response)
    }
  }

}

export default AuditService

export { AuditError }

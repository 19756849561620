import ApiService from '@/modules/auth/services/api.service'
import { ConfigurationError } from '../errors/configuration_error'

const MaintenanceService = {
  dropSourceDBConnection: async function () {
    try {
      const response = await ApiService.get('config/maintenance/drop_ibase_database_connection/')
      return response.data
    } catch (error) {
      throw new ConfigurationError(error.response.status, error.response.data)
    }
  }
}

export default MaintenanceService

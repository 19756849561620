import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import 'core-js/stable'
import 'regenerator-runtime/runtime'
import '@/dependencies/bootstrapvue_dependencies'
// import VueMoment from 'vue-moment'
import PortalVue from 'portal-vue'
import './styles.scss'
import AuthApiService from '@/modules/auth/services/api.service'
import AuthTokenService from '@/modules/auth/services/token.service'

import Configuration from '../Configuration'
import IdleVue from 'idle-vue'
import Constants from './constants/constants'
import VueObserveVisibility from 'vue-observe-visibility'
import Directives from '@/directives'
import VueSanitize from 'vue-sanitize'

Vue.use(VueObserveVisibility)
Vue.use(PortalVue)
Vue.use(Constants)
Vue.use(VueSanitize)
Vue.use(Directives)
// Vue.use(VueMoment)
Vue.config.productionTip = false

// AuthApiService.init(process.env.VUE_APP_API_URL)
AuthApiService.init(Configuration.value('VUE_APP_API_URL') || process.env.VUE_APP_API_URL)
// If token exists set header
if (AuthTokenService.getToken()) {
  AuthApiService.setHeader()
  AuthApiService.mount401Interceptor()
}

Vue.filter('snakeToTitle', function (str) {
  return str.split('_').map(function (item) {
    return item.charAt(0).toUpperCase() + item.substring(1)
  }).join(' ')
})

Vue.directive('data-cy', {
  // When the directive is bounded to the element
  bind: function (el, binding, vnode) {
    // Only register add attribute when the environment is e2e_test
    if (process.env.NODE_ENV === 'e2e_test') {
      // if eval argument is provided, evaluate the expression instead
      if (binding.arg === 'eval') {
        el.setAttribute('data-cy', binding.value)
      } else {
        // Added the data-cy attribute with the provided expression as string
        el.setAttribute('data-cy', binding.expression)
      }
    }
  }
})

Vue.use(IdleVue, {
  eventEmitter: new Vue(),
  store,
  idleTime: Configuration.value('VUE_APP_INACTIVITY_DURATION_IN_MILLISECONDS'),
  startAtIdle: false
})

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')

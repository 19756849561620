import Vue from 'vue'

const EncodeHtml = {
  // Encodes all HTML elements
  encode (value) {
    return Vue.prototype.$sanitize(value, {
      allowedTags: [],
      disallowedTagsMode: 'escape'
    })
  },
  // When the directive is first bounded to the element
  bind: function (el, binding) {
    el.innerHTML = EncodeHtml.encode(binding.value)
  },
  // When the directive's containing component is updated
  update: function (el, binding) {
    el.innerHTML = EncodeHtml.encode(binding.value)
  }
}

const EncodeHighlightedHtml = {
  // Encodes all HTML elements within and outside the highlighted elements
  encode (value) {
    return Vue.prototype.$sanitize(value, {
      allowedTags: ['span'],
      allowedAttributes: {
        span: ['style']
      },
      allowedStyles: {
        span: {
          'background-color': [/^#FF0$/]
        }
      },
      disallowedTagsMode: 'escape'
    })
  },
  // When the directive is first bounded to the element
  bind: function (el, binding) {
    el.innerHTML = EncodeHighlightedHtml.encode(binding.value)
  },
  // When the directive's containing component is updated
  update: function (el, binding) {
    el.innerHTML = EncodeHighlightedHtml.encode(binding.value)
  }
}

const EncodeStyledHtml = {
  // Encodes all HTML elements within and outside the styled elements
  encode (value) {
    return Vue.prototype.$sanitize(value, {
      allowedTags: ['strong', 'i', 'b', 'br'],
      disallowedTagsMode: 'escape'
    })
  },
  // When the directive is first bounded to the element
  bind: function (el, binding) {
    el.innerHTML = EncodeStyledHtml.encode(binding.value)
  },
  // When the directive's containing component is updated
  update: function (el, binding) {
    el.innerHTML = EncodeStyledHtml.encode(binding.value)
  }
}

const EncodeHyperlinkHtml = {
  // Encodes all HTML elements outside the hyperlink element
  encode (value) {
    return Vue.prototype.$sanitize(value, {
      allowedTags: ['a'],
      allowedAttributes: {
        a: ['href', 'target', 'name', 'class', 'style']
      },
      disallowedTagsMode: 'escape'
    })
  },
  // When the directive is first bounded to the element
  bind: function (el, binding) {
    el.innerHTML = EncodeHyperlinkHtml.encode(binding.value)
  },
  // When the directive's containing component is updated
  update: function (el, binding) {
    el.innerHTML = EncodeHyperlinkHtml.encode(binding.value)
  }
}

export default {
  EncodeHtml,
  EncodeHighlightedHtml,
  EncodeStyledHtml,
  EncodeHyperlinkHtml
}

Vue.directive('encode-html', EncodeHtml)
Vue.directive('encode-highlighted-html', EncodeHighlightedHtml)
Vue.directive('encode-styled-html', EncodeStyledHtml)
Vue.directive('encode-hyperlink-html', EncodeHyperlinkHtml)

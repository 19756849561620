export default {
  userDashboardPermissions: [],
  selectedQueries: [], // populated state is array. [{queryId, queryName, outputModels, securityGroups}]
  // where outputModels = [{ selected, type, name, queryId, color }]
  storedOutputModelsAndFields: {},
  isQueryModalOpen: false,
  error: false, // { code: {int}, message: {string}, organicDetails: {object} }
  errorCode: 0,
  chartIndexError: [],
  formFields: {},
  orderingFields: {},
  chartAPIDetails: false, // { count: {int}, next: { string or null }, previous: { string or null }  }
  viewableCharts: false,
  previewButtonStatus: false, // false -> should not be disabled
  isAddQueryLoading: false,
  oneOutputModelTypeSelected: [],
  indexState: {
    isManage: false,
    activeTabIdx: 0, // Favourite's tab_idx
    lastSortText: 'Sort', // Only stores last sort text before user leaves index page, is NOT modelling after current sort text in index page.
    params: {
      page: 1,
      perPage: 4,
      ordering: '-created_on,id',
      search: '',
      group_id: -1 // Favourite's group_id
    }
  }
}

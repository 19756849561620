import ApiService from '@/modules/auth/services/api.service'
import { ConfigurationError } from '../errors/configuration_error'

const DatabaseIndexSchedulerService = {
  /**
   * This API calls the options method to get the fields required for POST request
   * @return {Promise<string|*>}
   */
  options: async function () {
    try {
      const response = await ApiService.options('/config/insight_scheduler/')
      return response.data.actions.POST
    } catch (error) {
      throw new ConfigurationError(error.response.status, error.response.data)
    }
  },

  /**
   * This API call gets the scheduler data
   * @return {Promise<*>}
   */
  get: async function () {
    try {
      const response = await ApiService.get('/config/insight_scheduler/')
      return response.data
    } catch (error) {
      throw new ConfigurationError(error.response.status, error.response.data)
    }
  },

  /**
   * This API call saves the updated scheduler data
   * @param data Data to be updated
   * @return {Promise<void>}
   */
  save: async function (data) {
    try {
      await ApiService.post('/config/insight_scheduler/', data)
    } catch (error) {
      throw new ConfigurationError(error.response.status, error.response.data)
    }
  }
}

export { DatabaseIndexSchedulerService, ConfigurationError }

export default DatabaseIndexSchedulerService

import { isEqual } from 'lodash'

export default {

  startRequest (state) {
    state.loading = true
    state.saved = false
  },

  endRequest (state) {
    state.loading = false
  },

  setModules (state, modules) {
    if (isEqual(state.modules, modules) === false) state.dirty = true
    state.modules = modules
  },

  clearError (state) {
    state.error = ''
    state.errorCode = 0
  },

  setError (state, { errorCode, errorMessage }) {
    state.error = errorMessage
    state.errorCode = errorCode
  },

  setCompanyId (state, id) {
    if (state.companyId !== id) state.dirty = true
    state.companyId = id
  },

  saved (state) {
    state.saved = true
    state.dirty = false
  },

  notDirty (state) {
    state.dirty = false
  },

  restartServer (state) {
    state.restarting = true
  },

  serverRestarted (state) {
    state.restarting = false
  },

  setSystemFields (state, systemFields) {
    state.systemFields = systemFields
  }
}

import Vue from 'vue'
import INSIGHT from '@/constants/INSIGHT.json'

/**
 * generateToastText() return a formatted toast text
 * methodKey is type of method that triggered (eg. CREATED, EDITED, DELETED, SELECTED)
 * variant is type of the toast (eg. SUCCESS, DEFAULT)
 * config = { vueInstance, prefixName, name, postfixText, textMessage }
 */
function generateToastText ({ variant, methodKey, ...config }) {
  const { prefixName, name, postfixText, textMessage } = config
  const vueInstance = config.vueInstance || new Vue()
  const defaultText = INSIGHT.TOAST_MESSAGE.TEXT[variant][methodKey].DEFAULT_TEXT

  const h = vueInstance.$createElement
  const prefixNameTemplate = prefixName ? `${prefixName} ` : ''
  const postfixTextTemplate = postfixText ? ` ${postfixText}` : ''
  const defaultTextTemplate = defaultText || ''
  const textMessageTemplate = textMessage || ''
  const toastText = h(
    'p',
    { class: ['mb-0'] },
    [prefixNameTemplate, h('strong', {}, name), ' ', defaultTextTemplate, postfixTextTemplate, textMessageTemplate]
  )

  return toastText
}

/**
 * showToastMessage() is method to show the toast message
 * methodKey is type of method that triggered (eg. CREATED, EDITED, DELETED, SELECTED)
 * variant is type of the toast (eg. SUCCESS, DEFAULT)
 * config = { vueInstance, prefixName, name, postfixText, textMessage }
 */
function showToastMessage ({ variant, methodKey, ...config }) {
  const toastTitle = INSIGHT.TOAST_MESSAGE.TEXT[variant][methodKey].TITLE
  const toastText = generateToastText({ methodKey, variant, ...config })
  renderToastMessage(config.vueInstance, variant, toastTitle, toastText)
}

/**
 * renderToastMessage() is method to render the toast message
 * vueInstance is vue instance the toast that will be rendered
 * variant is type of the toast (eg. SUCCESS, DEFAULT)
 * toastTitle is title of the toast
 * toastText is text message of the toast
 */
function renderToastMessage (vueInstance, variant, toastTitle, toastText) {
  vueInstance = vueInstance || new Vue()
  vueInstance.$root.$bvToast?.toast(toastText, {
    title: toastTitle,
    autoHideDelay: 5000,
    appendToast: true,
    variant: variant.toLowerCase()
  })
}

const ToastMessageHelper = {
  generateToastText,
  showToastMessage,
  renderToastMessage
}

export default ToastMessageHelper

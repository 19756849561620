<template>
  <div class="layout">
    <div class="bg">
      <div class="overlay">
        <slot />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'DefaultLayout'
}
</script>

<style lang="scss">
@import '@/styles.scss';
.layout {
  .bg {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: url('../../public/bg-logo.png') top left no-repeat, url('../../public/bg-spash.png') bottom right no-repeat;
    background-color: #252E5A;
    background-size: 100% auto;
    overflow: hidden;
  }

  .overlay {
    position: absolute;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto;
    height: 100%;
    width: 100%;
    overflow: auto;
  }
}
</style>

<template>
  <div class="menu">
    <div class="title" v-if="title !== null">
      {{ title }}
    </div>
    <div class="items">
      <template v-for="(item, index) of items">
        <router-link
          @click.native="shiftFocusToMain"
          @keyup.enter="shiftFocusToMain"
          :class="{ item: true, active: isActive(item) }"
          :id="item.name"
          :to="item.router"
          :key="index"
          v-if="isNavMenuVisible(item.name)"
        >
          <div class="icon">
            <component :is="item.icon" />
          </div>
          <div class="name">
            {{ item.name }}
          </div>
        </router-link>
      </template>
      <div class="no-items" v-if="items.length === 0">
        No menu items
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'
export default {
  props: {
    title: {
      type: String,
      default: null
    },
    items: {
      type: Array,
      default: () => {
        return []
      }
    }
  },
  computed: {
    ...mapState('forms', {
      formsPermissions: 'userPermissions'
    }),
    ...mapState('insight/queries', {
      queriesPermissions: 'queryCRUDPermissions'
    }),
    ...mapState('dashboard', {
      dashboardPermissions: 'userDashboardPermissions'
    }),
    ...mapState('config/insight', ['showAuditInSettings']),
    ...mapState('auth', ['userData'])
  },
  async mounted () {
    /*
      The following permissions retrieval method are also called in their respective modules when mounted.
      Perhaps can rewrite the permission implementation for elegancy.
    */
    await this.getDashboardPermissions()
    await this.getPermissions()
    await this.getQueryCRUDPermissions()

    if (this.userData.is_superuser === true) {
      await this.checkAuditEnabled()
    }
  },
  methods: {
    ...mapActions('forms', [
      'getPermissions'
    ]),
    ...mapActions('insight/queries', [
      'getQueryCRUDPermissions'
    ]),
    ...mapActions('dashboard', [
      'getDashboardPermissions'
    ]),
    ...mapActions('config/insight', [
      'checkAuditEnabled'
    ]),
    isActive (item) {
      if (this.$route.name === item.router.name) return true
      if (this.$route.meta && this.$route.meta.activeMenus && this.$route.meta.activeMenus.includes(item.router.name)) return true
      return false
    },
    shiftFocusToMain () {
      this.$parent.$refs.mainContent.focus()
    },
    isNavMenuVisible (navMenuName) {
      const permissionsName = `${navMenuName.toLowerCase()}Permissions` // formsPermissions, dashboardPermissions, etc
      if (this[permissionsName] && this[permissionsName].length === 0) {
        return false
      } else {
        return true
      }
    }
  }
}
</script>

<style lang="scss" scoped>
  .menu {
    margin: 15px 12px;
    color: #FFF;
    font-weight: 300;

    a {
      color: #FFF;
    }

    .title {
      margin-bottom: 10px;
      transition: all 300ms ease-in;
      height: 25px;
      overflow: hidden;
    }

    .items {
      width: 100%;
      .item {
        display: flex;
        margin-bottom: 15px;
        align-items: center;

        .icon {
          width: 34px;
          text-align: center;
          padding-left: 5px;
          svg {
            fill: #FFF;
            width: 24px;
            height: auto;
            transition: all 250ms ease-in-out;
          }
        }

        .name {
          transition: all 250ms ease-in-out;
          flex-grow: 1;
          text-align: left;
          padding-left: 17px;
          display: block;
          width: 100%;
        }

        &.active,
        &:hover {
          .icon {
            svg {
              fill: #F08521;
            }
          }

          .name {
            text-decoration: none;
            color: #F08521;
          }
        }
      }
    }
  }
</style>

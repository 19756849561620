export default {
  entities: false,
  record: false,
  links: false,
  records: false,
  fields: false,
  searchTerm: false,
  error: false,
  errorCode: 0,
  advancedSearchFields: [],
  userFavouriteList: false,
  searchError: false,
  hideLinkLabel: false
}

export default {
  authenticating: false,
  accessToken: null,
  authenticationErrorCode: 0,
  authenticationError: '',
  refreshTokenPromise: null, // Holds the promise of the refresh token
  userDataErrorCode: 0,
  userDataError: '',
  userData: false,
  inactivityLoggedOut: false,
  logoutError: '',
  logoutErrorCode: 0,
  interceptorToastShown: false,
  websocketConnectionCache: {},
  otpDeviceRegisteredWithUser: false,
  otpCorrectCode: false,
  otpErrorMessage: '',
  otpErrorCode: 0,
  verifying: false,
  passwordFieldOptions: {}
}

/* istanbul ignore file */
import AUTH from '@/constants/AUTH.json'

const routes = [
  {
    path: '/login',
    name: 'login',
    component: () => import(/* webpackChunkName: "auth" */ '@/modules/auth/views/Login.vue'),
    meta: {
      title: `${AUTH.LOG_IN_FORM.TITLE} | VA Insight`,
      public: true, // Allow access to even if not logged in
      onlyWhenLoggedOut: true
    },
    props: true
  },
  {
    path: '/logout',
    name: 'logout',
    component: () => import(/* webpackChunkName: "auth" */ '@/modules/auth/views/Logout.vue'),
    props: true
  },
  {
    path: '/change-password',
    name: 'change-password',
    component: () => import(/* webpackChunkName: "auth" */ '@/modules/auth/views/ChangePassword.vue'),
    meta: {
      title: `${AUTH.CHANGE_PASSWORD_FORM.TITLE} | VA Insight`
    }
  },
  {
    path: '/otp',
    name: 'otp',
    component: () => import(/* webpackChunkName: "auth" */ '@/modules/auth/views/OTP.vue')
  },
  {
    path: '/device-auth',
    name: 'device-auth',
    component: () => import(/* webpackChunkName: "auth" */ '@/modules/auth/views/DeviceAuth.vue')
  }
]

export default routes

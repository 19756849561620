export default {
  isInitialised: false, // Check if app is initialised
  isLoading: false, // Check if an API call is loading in audit main page
  error: false, // Contains error in the audit main page
  modalError: false, // Contains error in audit modal
  users: [],
  filterResults: [],
  auditLogCache: [], // Cache audit log with details and changes so that they do not have to be retrieved again
  recordTypes: [],
  totalNumberOfResults: 0
}

export default {
  clearError (state) {
    state.errorCode = 0
    state.message = ''
  },
  setError (state, error) {
    state.message = error.message
    state.errorCode = error.code
  }
}

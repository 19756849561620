import ModuleService from '../services/modules.service'
import ServerService from '../services/server.service'

import { ConfigurationError } from '../errors/configuration_error'

export default {
  async fetch ({ commit, state }) {
    commit('startRequest')
    commit('clearError')

    try {
      const { companyId, modules, systemFields } = await ModuleService.get()

      commit('setCompanyId', companyId)
      commit('setModules', modules)
      commit('setSystemFields', systemFields)
      commit('endRequest')
      commit('notDirty')
      return true
    } catch (error) {
      if (error instanceof ConfigurationError) {
        commit('setError', { errorCode: error.errorCode, errorMessage: error.message })
      } else {
        commit('setError', { errorCode: 999, errorMessage: 'Insight Server have encountered an error. Please ensure it is up and running.' })
      }
      commit('endRequest')
      return false
    }
  },

  async sync ({ commit, state }) {
    commit('startRequest')
    commit('clearError')

    try {
      const { companyId, modules, systemFields } = await ModuleService.sync(state.companyId, state.modules)

      commit('setCompanyId', companyId)
      commit('setModules', modules)
      commit('setSystemFields', systemFields)
      commit('saved')
    } catch (error) {
      if (error instanceof ConfigurationError) {
        commit('setError', { errorCode: error.errorCode, errorMessage: error.message })
      } else {
        commit('setError', { errorCode: 999, errorMessage: error.message })
      }
    } finally {
      commit('endRequest')
    }
  },

  async updateModules ({ commit }, modules) {
    commit('setModules', modules)
  },

  async updateCompany ({ commit }, id) {
    commit('setCompanyId', id)
  },

  async restartServer ({ commit }, emitServerRestarted = true) {
    commit('restartServer')

    await ServerService.status()

    if (emitServerRestarted) {
      commit('serverRestarted')
    }
  },

  /**
   * This function commits the serverRestarted mutation which will dismiss the server restart model
   * @param commit
   * @return {Promise<void>}
   */
  async serverRestarted ({ commit }) {
    commit('serverRestarted')
  }
}

import { DashboardService } from '@/modules/dashboard/services/dashboard.service'

// All paths should start with /dashboard/
const routes = [
  {
    path: '/dashboard/',
    name: 'dashboard',
    component: () => import(/* webpackChunkName: "dashboard" */ './views/Index'),
    meta: {
      layout: 'DashboardLayout',
      activeMenus: ['dashboard']
    }
  },
  {
    path: '/dashboard/create/new',
    name: 'create-chart-builder',
    component: () => import(/* webpackChunkName: "dashboard" */ './views/ChartBuilder'),
    async beforeEnter (to, from, next) {
      const dashboardPermissions = await DashboardService.getDashboardPermissionsForUser()
      if (dashboardPermissions.includes('dashboard.add_chart')) {
        next()
      } else {
        next(false)
      }
    },
    meta: {
      layout: 'DashboardLayout',
      activeMenus: ['dashboard'],
      action: 'create'
    }
  },
  {
    path: '/dashboard/:id/edit',
    name: 'edit-chart-builder',
    component: () => import(/* webpackChunkName: "dashboard" */ './views/ChartBuilder'),
    async beforeEnter (to, from, next) {
      const dashboardPermissions = await DashboardService.getDashboardPermissionsForUser()
      if (dashboardPermissions.includes('dashboard.change_chart')) {
        next()
      } else {
        next(false)
      }
    },
    meta: {
      layout: 'DashboardLayout',
      activeMenus: ['dashboard'],
      action: 'edit'
    }
  }
]

export default routes

export default {
  error: false,
  errorCode: 0,
  groupQueries: {},
  usersInformation: {},
  userOptions: [],
  userFieldOptions: {},
  showToast: false,
  groupPermissionList: {}
}

import QueryService from '../../services/query.service'

export default {
  getQueries: async function ({ commit }, { params, isAppend }) {
    commit('clearError')
    try {
      const items = await QueryService.getQueries(params)
      if (isAppend === true) {
        commit('appendQueries', items.results)
      } else {
        commit('setQueries', items.results)
      }

      return true
    } catch (error) {
      commit('setError', {
        code: error.errorCode,
        message: error.message
      })
      return false
    }
  },
  getQuery: async ({ commit }, id) => {
    commit('clearError')
    try {
      const response = await QueryService.getQuery(id)
      commit('setQuery', response)
    } catch (error) {
      commit('setError', {
        code: error.errorCode,
        message: error.message
      })
      return false
    }
  },
  createQuery: async ({ commit }, { data }) => {
    commit('clearError')
    try {
      const response = await QueryService.createQuery(data)
      return response
    } catch (error) {
      commit('setError', {
        code: error.errorCode,
        message: error.message
      })
      return false
    }
  },
  updateQuery: async ({ commit }, { id, data }) => {
    commit('clearError')
    try {
      await QueryService.updateQuery(id, data)
      return true
    } catch (error) {
      commit('setError', {
        code: error.errorCode,
        message: error.message
      })
      return false
    }
  },
  getLinkedCharts: async function ({ commit }, id) {
    commit('clearError')
    try {
      const linkedCharts = await QueryService.getLinkedCharts(id)
      return linkedCharts
    } catch (error) {
      commit('setError', {
        code: error.errorCode,
        message: error.message
      })
      return false
    }
  },
  deleteQuery: async function ({ commit }, { id, cascadeDelete = false }) {
    try {
      if (cascadeDelete === true) {
        await QueryService.deleteQueryWithCascadeToCharts(id)
      } else {
        await QueryService.deleteQuery(id)
      }
      return true
    } catch (error) {
      commit('setError', {
        code: error.errorCode,
        message: error.message
      })
      return false
    }
  },
  generateQuery: async function ({ commit }, { id, params, isAppend, itemId }) {
    try {
      const result = await QueryService.generateQuery(id, params)
      if (isAppend) {
        commit('appendRecordsQueryResults', { records: result, itemId })
      } else {
        commit('setGeneratedQueries', result)
      }
      return result
    } catch (error) {
      commit('setError', {
        code: error.errorCode,
        message: error.message
      })
      return false
    }
  },
  generateQueryByModel: async function ({ commit }, { id, params, isAppend, itemId }) {
    try {
      const result = await QueryService.generateQuery(id, params)
      if (isAppend) {
        commit('appendRecordsQueryResults', { records: result, itemId })
      } else {
        commit('setGeneratedQueriesByModel', { result, itemId })
      }
      return true
    } catch (error) {
      commit('setError', {
        code: error.errorCode,
        message: error.message
      })
      return false
    }
  },
  exportGeneratedDataQuery: async function ({ commit }, { id, params }) {
    try {
      const response = await QueryService.exportGeneratedDataQuery(id, params)
      return response
    } catch (error) {
      commit('setError', {
        code: error.errorCode,
        message: error.message
      })
      return false
    }
  },
  getQueryCreateForm: async ({ commit }) => {
    commit('clearError')
    try {
      const response = await QueryService.createForm()
      return response
    } catch (error) {
      commit('setError', {
        code: error.errorCode,
        message: error.message
      })
      return false
    }
  },
  clearQuery: async function ({ commit }) {
    commit('clearError')
    try {
      commit('setQuery', {})

      return true
    } catch (error) {
      commit('setError', {
        code: error.errorCode,
        message: error.message
      })
      return false
    }
  },
  getAccessGroupsOfSingleQuery: async ({ commit }, { queryId }) => {
    commit('clearError')
    try {
      const response = await QueryService.getAccessGroupsOfSingleQuery(queryId)
      return response
    } catch (error) {
      commit('setError', {
        code: error.errorCode,
        message: error.message
      })
      return false
    }
  },
  getQueryCRUDPermissions: async ({ commit }) => {
    try {
      const response = await QueryService.getQueryCRUDPermissions()
      commit('setQueryCRUDPermissions', response)
      return response
    } catch (error) {
      commit('setError', {
        code: error.errorCode,
        message: error.message
      })
      return false
    }
  },
  getInputParametersQuery: async ({ commit }, { id }) => {
    commit('clearError')
    try {
      const response = await QueryService.getInputParametersQuery(id)
      return response
    } catch (error) {
      commit('setError', {
        code: error.errorCode,
        message: error.message
      })
      return false
    }
  }
}

import Vue from 'vue'
import DashboardService from '../services/dashboard.service'
import { cloneDeep, isEqual, get } from 'lodash'

export default {
  getDashboardPermissions: async ({ commit }) => {
    try {
      const permissions = await DashboardService.getDashboardPermissionsForUser()
      commit('setUserDashboardPermissions', permissions)
      return permissions
    } catch (error) {
      commit('setError', {
        code: error.errorCode,
        message: error.message,
        organicDetails: error.errorDetails
      })
      return false
    }
  },
  getOrderingFields: async ({ commit }) => {
    try {
      const fields = await DashboardService.getOrderingFields()
      commit('setOrderingFields', fields)
      return true
    } catch (error) {
      commit('setError', {
        code: error.errorCode,
        message: error.message,
        organicDetails: error.errorDetails
      })
      return false
    }
  },
  /**
   * @public
   * setUnauthorisedDashboardErrorForGivenAction () is Dashboard's custom error handler for ONLY unauthorised (403) action. Other errors would have already been handled by ErrorHandler in dashboard.service.js.
   * @param {String} action - defaults to an empty string if nothing is passed in
   * @param {String} model - defaults to 'charts' if nothing is passed in. Only accepts strings 'charts' or 'queries'
   * @param {Object} errorDetails - default to empty object {} if nothing is passed in. Stores the organic data of error passed from BE. If this method is called from FE e.g. Index.vue, this should be left empty.
   */
  setUnauthorisedDashboardErrorForGivenAction: ({ commit }, { action = '', model = 'charts', errorDetails = {} }) => {
    if (!Object.values(Vue.prototype.$DASHBOARD('PERMISSIONS')).includes(action) ||
      !Object.values(Vue.prototype.$DASHBOARD('MODELS')).includes(model)) {
      return false
    } else {
      const modelKey = Object.keys(Vue.prototype.$DASHBOARD('MODELS')).find(key => Vue.prototype.$DASHBOARD('MODELS')[key] === model)
      const actionKey = Object.keys(Vue.prototype.$DASHBOARD('PERMISSIONS')).find(key => Vue.prototype.$DASHBOARD('PERMISSIONS')[key] === action)

      commit('clearError')
      commit('setError', {
        code: 403, // unauthorised code
        message: get(Vue.prototype.$DASHBOARD('NO_PERMS_ERROR_MESSAGE'), `${modelKey}.${actionKey}`),
        organicDetails: errorDetails // passed in
      })
      return true
    }
  },
  appendSelectedQuery: ({ commit }, { selectedQuery, allOutputModelTypesandFields }) => {
    // selectedQuery comes from Insight api (not Dashboard)
    // This action will not work if selectedQuery comes from Dashboard Api
    const outputModels = []
    if (selectedQuery.output !== '-') { // has output model
      // populate outputModels
      allOutputModelTypesandFields[selectedQuery.record_id]
        .forEach(function (outputModel) {
          outputModels.push({
            selected: false,
            type: outputModel.om_type,
            name: outputModel.om_name,
            queryId: parseInt(selectedQuery.record_id)
          })
        })
    }
    commit('appendSelectedQuery', {
      queryId: parseInt(selectedQuery.record_id),
      queryName: selectedQuery.name,
      securityGroups: selectedQuery.access_group,
      outputModels: outputModels
    })
  },
  toggleOutputModelStatusInSelectedQueries: ({ commit, state }, { outputModel, value = undefined }) => {
    // Match exact outputModel object in selectedQueries
    const cloneSelectedQueries = cloneDeep(state.selectedQueries)
    for (const query of cloneSelectedQueries) {
      if (query.queryId === outputModel.queryId) {
        for (const opmObj of query.outputModels) {
          // isEqual lodash function for object equality
          if (isEqual(opmObj, outputModel)) {
            if (value === undefined) { // toggle
              opmObj.selected = !opmObj.selected
            } else { // set
              opmObj.selected = value
            }
            commit('updateSelectedQueries', cloneSelectedQueries)
            return true // short circuit. Assumption here is that only 1 can be found.
          }
        }
      }
    }
    return false
  },
  removeSelectedQuery: ({ commit }, { queryId }) => {
    commit('removeSelectedQuery', { queryId: queryId })
    commit('removeStoredOutputModelTypesAndFields', { queryId: queryId })
  },
  createChart: async ({ commit, dispatch }, { fields }) => {
    commit('clearError')
    try {
      const response = await DashboardService.createChart(fields)
      return response
    } catch (error) {
      if (error.errorCode === 403) {
        dispatch('setUnauthorisedDashboardErrorForGivenAction', {
          action: Vue.prototype.$DASHBOARD('PERMISSIONS').ADD,
          model: 'charts',
          errorDetails: error.errorDetails
        })
      } else {
        commit('setError', {
          code: error.errorCode,
          message: error.message,
          organicDetails: error.errorDetails
        })
      }
      return false
    }
  },
  getAllOutputModelTypesAndFieldsByQueryId: async ({ commit }, { queryId }) => {
    commit('clearError')
    try {
      const response = await DashboardService.getAllOutputModelTypesAndFieldsByQueryId(queryId)
      commit('setStoredOutputModelTypesAndFields', response)
      return response
    } catch (error) {
      commit('setError', {
        code: error.errorCode,
        message: error.message,
        organicDetails: error.errorDetails
      })
      return false
    }
  },
  updateQueryModalOpen: ({ commit }, value = undefined) => {
    if (value === undefined) {
      commit('toggleIsQueryModalOpenStatus')
      return true
    } else if (typeof value === 'boolean') {
      commit('setIsQueryModalOpenStatus', value)
      return true
    } else {
      // do nothing if its not a boolean type
      return false
    }
  },
  clearChartBuilder: ({ commit }) => {
    commit('resetChartBuilder')
  },
  getCreateFields: async ({ commit }) => {
    commit('clearError')
    try {
      const items = await DashboardService.getCreateChartFields()
      commit('setFields', items)
      return true
    } catch (error) {
      commit('setError', {
        code: error.errorCode,
        message: error.message,
        organicDetails: error.errorDetails
      })
      return false
    }
  },
  getChartPreviewDetails: async ({ commit }, { data }) => {
    commit('clearError')
    try {
      const response = await DashboardService.getChartPreviewDetails(data)
      return response
    } catch (error) {
      commit('setError', {
        code: error.errorCode,
        message: error.message,
        organicDetails: error.errorDetails
      })
      return false
    }
  },
  updatePreviewButtonFalse: ({ commit }) => {
    commit('updatePreviewButtonFalse')
    return true
  },
  updatePreviewButtonTrue: ({ commit }) => {
    commit('updatePreviewButtonTrue')
    return true
  },
  updateIsAddQueryLoading: ({ commit }, value = undefined) => {
    if (value === undefined) {
      commit('toggleIsAddQueryLoading')
      return true
    } else if (typeof value === 'boolean') {
      commit('setIsAddQueryLoading', value)
      return true
    } else {
      return false
    }
  },
  addOneOutputModelTypeSelected: ({ commit }, { outputModel }) => {
    commit('addOneOutputModelTypeSelected', outputModel)
    return true
  },
  clearOneOutputModelTypeSelected: ({ commit }) => {
    commit('clearOneOutputModelTypeSelected')
    return true
  },
  getAllViewableChartsOptimised: async ({ commit }, { params, append = false }) => {
    commit('clearError')
    try {
      const response = await DashboardService.getAllViewableChartsOptimised(params)
      const viewableCharts = response.results
      if (append) {
        commit('appendViewableCharts', viewableCharts)
      } else {
        commit('setViewableCharts', viewableCharts)
      }

      delete response.results
      commit('setChartAPIDetails', response)

      return true
    } catch (error) {
      commit('setError', {
        code: error.errorCode,
        message: error.message,
        organicDetails: error.errorDetails
      })
      return false
    }
  },
  // Index.vue
  resetViewableCharts: ({ commit }) => {
    commit('clearError')
    commit('resetViewableCharts')
    commit('resetChartAPIDetails')
    return true
  },
  updateViewableChartsWithSingleChart: async ({ commit }, { chartId }) => {
    commit('clearError')
    try {
      const chart = await DashboardService.getSingleChart(chartId)
      commit('updateViewableChartsWithSingleChart', chart)
      return true
    } catch (error) {
      commit('setError', {
        code: error.errorCode,
        message: error.message,
        organicDetails: error.errorDetails
      })
      commit('setChartIndexError', {
        code: error.errorCode,
        message: error.message,
        organicDetails: error.errorDetails,
        id: chartId
      })
      return false
    }
  },
  deleteChart: async ({ commit }, { chartId }) => {
    commit('clearError')
    try {
      await DashboardService.deleteChart(chartId)
      return true
    } catch (error) {
      commit('setError', {
        code: error.errorCode,
        message: error.message,
        organicDetails: error.errorDetails
      })
      return false
    }
  },
  getSingleChartForEdit: async ({ commit }, { chartId }) => {
    commit('clearError')
    try {
      const chart = await DashboardService.getSingleChart(chartId, true)
      return chart
    } catch (error) {
      commit('setError', {
        code: error.errorCode,
        message: error.message,
        organicDetails: error.errorDetails
      })
      return false
    }
  },
  updateChart: async ({ commit, dispatch }, { fields }) => {
    commit('clearError')
    try {
      const response = await DashboardService.updateChart(fields)
      return response
    } catch (error) {
      if (error.errorCode === 403) {
        dispatch('setUnauthorisedDashboardErrorForGivenAction', { action: Vue.prototype.$DASHBOARD('PERMISSIONS').CHNG, model: 'charts', errorDetails: error.errorDetails })
      } else {
        commit('setError', {
          code: error.errorCode,
          message: error.message,
          organicDetails: error.errorDetails
        })
      }
      return false
    }
  },
  clearChartIndexError: async ({ commit }) => {
    commit('clearChartIndexError')
  }
}

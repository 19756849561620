import ApiService from '@/modules/auth/services/api.service'
import { ConfigurationError } from '../errors/configuration_error'

const AuditDatabaseConnectionService = {
  options: async function () {
    try {
      const response = await ApiService.options('/config/audit_db_connection/')
      return response.data.actions.POST
    } catch (error) {
      throw new ConfigurationError(error.response.status, error.response.data)
    }
  },

  get: async function () {
    try {
      const response = await ApiService.get('/config/audit_db_connection/')
      return response.data
    } catch (error) {
      throw new ConfigurationError(error.response.status, error.response.data)
    }
  },

  check: async function (data) {
    try {
      const response = await ApiService.post('/config/audit_db_connection/check_database_connection/', data)
      return response.data
    } catch (error) {
      throw new ConfigurationError(error.response.status, error.response.data)
    }
  },

  save: async function (data) {
    try {
      await ApiService.post('/config/audit_db_connection/', data)
      const registrationResponse = await ApiService.get('/config/audit_db_connection/database_registration/')
      return registrationResponse.data
    } catch (error) {
      throw new ConfigurationError(error.response.status, error.response.data)
    }
  },

  save_with_no_change: async function () {
    try {
      await ApiService.post('/config/audit_db_connection/check_database_connection/')
      const registrationResponse = await ApiService.get('/config/audit_db_connection/database_registration/')
      return registrationResponse.data
    } catch (error) {
      throw new ConfigurationError(error.response.status, error.response.data)
    }
  },

  disableAudit: async function () {
    try {
      await ApiService.get('/config/audit_db_connection/disable_audit/')
      const registrationResponse = await ApiService.get('/config/audit_db_connection/database_registration/')
      return registrationResponse.data
    } catch (error) {
      throw new ConfigurationError(error.response.status, error.response.data)
    }
  }
}

export { AuditDatabaseConnectionService, ConfigurationError }

export default AuditDatabaseConnectionService

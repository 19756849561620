import moment from 'moment'

const DateTimeUtils = {
  /**
   * @public
   * adds the current datetime into the provided object
   * @param {Object} obj - http request parameters
   */
  appendCurDateTimeFn: (obj) => {
    obj.dateTime = moment().format()
  }
}

export { DateTimeUtils }
export default DateTimeUtils

import NotificationService from '@/modules/insight/services/notification.service.js'

export default {
  getNotification: async ({ commit }, params) => {
    commit('clearError')
    try {
      const response = await NotificationService.getNotification(params)
      return response.data
    } catch (error) {
      commit('setError', {
        code: error.errorCode,
        message: error.message
      })
      return false
    }
  },
  getSubscriptionStatus: async ({ commit }, { objectId }) => {
    commit('clearError')
    try {
      const response = await NotificationService.getSubscriptionStatus(objectId)
      return response.data
    } catch (error) {
      commit('setError', {
        code: error.errorCode,
        message: error.message
      })
      return false
    }
  },
  subscribe: async ({ commit }, data) => {
    commit('clearError')
    try {
      const response = await NotificationService.subscribe(data)
      return response
    } catch (error) {
      commit('setError', {
        code: error.errorCode,
        message: error.message
      })
      return false
    }
  },
  unsubscribe: async ({ commit }, data) => {
    commit('clearError')
    try {
      const response = await NotificationService.unsubscribe(data)
      return response
    } catch (error) {
      commit('setError', {
        code: error.errorCode,
        message: error.message
      })
      return false
    }
  },
  markNotificationAsRead: async ({ commit }, id) => {
    commit('clearError')
    try {
      const response = await NotificationService.markNotificationAsRead(id)
      return response
    } catch (error) {
      commit('setError', {
        code: error.errorCode,
        message: error.message
      })
      return false
    }
  },
  markAllNotificationAsRead: async ({ commit }) => {
    commit('clearError')
    try {
      const response = await NotificationService.markAllNotificationAsRead()
      return response
    } catch (error) {
      commit('setError', {
        code: error.errorCode,
        message: error.message
      })
      return false
    }
  },
  markNotificationAsUnread: async ({ commit }, id) => {
    commit('clearError')
    try {
      const response = await NotificationService.markNotificationAsUnread(id)
      return response
    } catch (error) {
      commit('setError', {
        code: error.errorCode,
        message: error.message
      })
      return false
    }
  }
}

<template>
  <div id="app">
    <ServerRestartModal />
    <component :is="layout">
      <!-- TODO Test has key -->
      <router-view :key="$route.fullPath"/>
      <!-- <router-view/> -->
    </component>
  </div>
</template>

<script>
import DefaultLayout from '@/layouts/DefaultLayout'
import DashboardLayout from '@/layouts/DashboardLayout'
import ServerRestartModal from '@/components/ServerRestartModal.vue'

export default {
  components: {
    DefaultLayout,
    DashboardLayout,
    ServerRestartModal
  },
  computed: {
    layout () {
      return this.$route.meta.layout || 'DefaultLayout'
    }
  }
}
</script>

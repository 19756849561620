<template>
  <div>
    <div class="nav-search">
      <b-form v-on:submit.prevent="searchNow" class="search-form" inline>
        <b-input-group v-if="hasSearchBar">
          <b-input
            v-model="searchInput"
            class="shadow-none nav-input"
            placeholder="Search"
            ref="searchTextInput"
            trim
          />
          <span
            v-if="hasClearSearchCross"
            v-bind:style="{ display: clearSearchCrossDisplayed }"
            class="img-btn delete-button"
            @click="clearInput"
          >
            <LaTimes/>
          </span>

          <template v-slot:append>
            <b-button
              id="nav-search-bar-search-button"
              type="submit"
              variant="primary"
              :aria-label="$LOCAL('AriaLabelSearch')"
            >
              <b-spinner v-if="loading" class="search-icon"/>
              <span v-else><SearchIcon class="search-icon"/></span>
            </b-button>
          </template>
        </b-input-group>
      </b-form>
    </div>

  </div>
</template>

<script>
import LaTimes from '@/assets/la-times.svg'
import SearchIcon from '@/assets/search-icon.svg'
import { mapActions } from 'vuex'

export default {
  name: 'search-sort-controls',
  components: {
    LaTimes,
    SearchIcon
  },
  props: {
    hasSearchBar: {
      type: Boolean,
      default: true
    },
    hasClearSearchCross: {
      type: Boolean,
      default: true
    }
  },
  data: () => ({
    searchInput: '',
    loading: false
  }),
  computed: {
    /**
     * To configure display to clear search input value
     * @returns {string} - the style to show or not show search cross
     */
    clearSearchCrossDisplayed () {
      return this.searchInput === '' ? 'none' : 'unset'
    }
  },
  methods: {
    ...mapActions('search', [
      'setGlobalSearchInput'
    ]),
    /**
     * To clear search input
     */
    clearInput () {
      this.searchInput = ''
      this.$refs.searchTextInput.focus()
    },
    /**
     * To route to search result page
     */
    searchNow () {
      if (this.searchInput.length > 0) {
        this.loading = true
        this.setGlobalSearchInput(this.searchInput)
        this.searchInput = ''
        if (this.$route.name !== 'search') {
          this.$router.push({ name: 'search' })
        }
        this.$refs.searchTextInput.blur()
        this.loading = false
      }
    }
  }
}
</script>

<style lang="scss" scoped>
  $transitionAnimation: all 250ms ease-in-out;

.nav-search {
  display: flex;
  flex-grow: inherit;
  justify-content: flex-end;
  align-items: center;
  margin-right: 15px;
  margin-left: auto;

  .form-inline{
    flex-grow: inherit;
    justify-content: inherit;
  }

  .search-form {
    .input-group {
      // nowrap to always ensure that search icon is beside search bar
      flex-wrap: nowrap;
      flex-grow: inherit;

      .input-group-prepend {
        .select-model {
          border-top-right-radius: 0;
          border-bottom-right-radius: 0;
        }
      }

      .search-icon {
        display: block;
        margin: auto;
        width: 20px;
        height: 20px;
        fill: white;
      }

      .delete-button {
        position: relative;
        margin: 7px 7px 7px -31px;

        svg {
          margin: 0 auto;
          display: block;
        }
      }
    }
  }
}

.nav-input {
  width: 300px !important;
  flex: none !important;
  margin-left: auto;
  transition: $transitionAnimation;
}

.nav-input:focus {
  flex: auto !important;
}

</style>

import ApiService from '@/modules/auth/services/api.service'
import { ConfigurationError } from '../errors/configuration_error'

const DatabaseMappingService = {
  getEntityTypes: async function () {
    try {
      const response = await ApiService.get('/config/entity_link_types/entity_list/')
      return response.data
    } catch (error) {
      throw new ConfigurationError(error.response.status, error.response.data)
    }
  },
  getLinkTypes: async function () {
    try {
      const response = await ApiService.get('/config/entity_link_types/link_list/')
      return response.data
    } catch (error) {
      throw new ConfigurationError(error.response.status, error.response.data)
    }
  },
  generateModelWithId: async function (data) {
    try {
      const response = await ApiService.post('/config/entity_link_types/generate_model_with_id/', data)
      return response.data
    } catch (error) {
      throw new ConfigurationError(error.response.status, error.response.data)
    }
  },
  deleteAllGeneratedModels: async function () {
    try {
      const response = await ApiService.get('/config/entity_link_types/delete_all_generated_models/')
      return response.status
    } catch (error) {
      throw new ConfigurationError(error.response.status, error.response.data)
    }
  },
  restartInsightServer: async function () {
    try {
      const response = await ApiService.get('/config/entity_link_types/restart_insight_server/')
      return response
    } catch (error) {
      throw new ConfigurationError(error.response.status, error.response.data)
    }
  },
  /**
   * Call the generate elastic search required models API which will return the server once done.
   * @return {Promise<*>}
   */
  generateElasticSearchModelAndRestartInsightServer: async function () {
    try {
      const response = await ApiService.get('/config/entity_link_types/generate_elastic_search_models_and_restart_insight_server/')
      return response
    } catch (error) {
      throw new ConfigurationError(error.response.status, error.response.data)
    }
  },
  /**
   * @public
   * @async
   * This service calls the backend API to fetch and store security groups.
   * @returns HTTP response if success, else throw ConfigurationError
   */
  fetchSecurityGroups: async function () {
    try {
      const response = await ApiService.get('/config/entity_link_types/fetch_security_groups/')
      return response
    } catch (error) {
      throw new ConfigurationError(error.response.status, error.response.data)
    }
  }
}
export { DatabaseMappingService, ConfigurationError }

export default DatabaseMappingService

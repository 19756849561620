export default {
  clearError (state) {
    state.error = false
  },

  setError (state, error) {
    state.error = error
  },

  clearModalError (state) {
    state.modalError = false
  },

  setModalError (state, error) {
    state.modalError = error
  },

  setIsInitialised (state) {
    state.isInitialised = true
  },

  setLoading (state) {
    state.isLoading = true
  },

  clearLoading (state) {
    state.isLoading = false
  },

  setUsers (state, users) {
    state.users = users
  },

  setFilterResults (state, filterResults) {
    state.filterResults = filterResults
  },

  appendFilterResults (state, filterResults) {
    state.filterResults = [...state.filterResults, ...filterResults]
  },

  setTotalNumberOfResults (state, num) {
    state.totalNumberOfResults = num
  },

  addIntoAuditCache (state, auditLog) {
    state.auditLogCache = [...state.auditLogCache, auditLog]
  },

  clearAuditCache (state) {
    state.auditLogCache = []
  },

  setRecordTypes (state, recordTypes) {
    state.recordTypes = recordTypes
  },

  resetTotalNumberOfResults (state) {
    state.totalNumberOfResults = 0
  },

  resetFilterResults (state) {
    state.filterResults = []
  }
}

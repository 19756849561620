import ApiService from '@/modules/auth/services/api.service'

class NotificationError extends Error {
  constructor (errorCode, message) {
    super(message)
    this.name = this.constructor.name
    this.message = message
    this.errorCode = errorCode
  }
}
const NotificationService = {
  getNotification: async function (params) {
    try {
      const response = await ApiService.get('/notification/get_notification/', params)
      return response
    } catch (error) {
      throw new NotificationError(error.response.status, error.response.data)
    }
  },
  getSubscriptionStatus: async function (objectId) {
    try {
      const response = await ApiService.get(
        `/notification/check_subscription/?object_id=${objectId}`
      )
      return response
    } catch (error) {
      throw new NotificationError(error.response.status, error.response.data)
    }
  },
  subscribe: async function (data) {
    try {
      const response = await ApiService.post('/notification/subscribe/', data)
      return response
    } catch (error) {
      throw new NotificationError(error.response.status, error.response.data)
    }
  },
  unsubscribe: async function (data) {
    try {
      const response = await ApiService.post('/notification/unsubscribe/', data)
      return response
    } catch (error) {
      throw new NotificationError(error.response.status, error.response.data)
    }
  },
  markNotificationAsRead: async function (id) {
    try {
      const response = await ApiService.post(
        `/notification/${id}/mark_as_read/`
      )
      return response.data
    } catch (error) {
      throw new NotificationError(error.response.status, error.response.data)
    }
  },
  markAllNotificationAsRead: async function () {
    try {
      const response = await ApiService.post('/notification/mark_all_as_read/')
      return response.data
    } catch (error) {
      throw new NotificationError(error.response.status, error.response.data)
    }
  },
  markNotificationAsUnread: async function (id) {
    try {
      const response = await ApiService.post(
        `/notification/${id}/mark_as_unread/`
      )
      return response.data
    } catch (error) {
      throw new NotificationError(error.response.status, error.response.data)
    }
  }
}

export { NotificationService, NotificationError }
export default NotificationService

import qs from 'qs'
import moment from 'moment'

// The datetime format returned by Django backend
const NON_UTC_FORMAT = 'YYYY-MM-DD HH:mm:ss'
const UTC_FORMAT = 'YYYY-MM-DD HH:mm:ss+00:00'

/**
 * Capitalize a string.
 * @param {String} string The input string to be capitalized
 * @returns {String} The capitalized string
 */
function capitalize (string) {
  return string.charAt(0).toUpperCase() + string.slice(1)
}

/**
 * Capitalize substrings in a string.
 * The substrings can be separated by space (' '), dash ('-') or underscore ('_').
 * @param {String} words A string of words delimited by space, dash or underscore
 * @returns {String} A string of capitalized substrings delimited by space
 *
 * TODO: Retain the old delimiters.
 */
function capitalizeAllWords (words) {
  const individualWord = words.split(/[\s-_]+/) // Split by space or dash or underscore
  const capitalizedWords = individualWord.map(word => utils.capitalize(word))
  return capitalizedWords.join(' ')
}

/**
 * Convert a number to a string representation with at least 2 digits.
 * @param {Number} n The number to be converted
 * @returns {String} A string representation of the number with at least 2 digits
 */
function convertToTwoDigitString (n) {
  return n < 10 ? `0${n}` : `${n}`
}

/**
 * Convert a Date object to a format that is used in the default file name.
 * @param {Date | String} dateString A Date object or string acceptable by the moment library
 * @returns {String} A datetime format in 'YYYY-MM-DD_HHmmss'
 */
function formatDateTimeFileName (dateString) {
  return moment(dateString).format('YYYY-MM-DD_HHmmss')
}

/**
 * Formats data to a proper datetime representation if data is in valid ISO format.
 * Used to parse the 'additional_data' field of a filter result.
 * @param {String} data
 * @returns {Any} String if data is in valid ISO format, else the original type of data
 */
function formatDateTimeIfValidDateTime (data) {
  if (moment(data, [NON_UTC_FORMAT, UTC_FORMAT], true).isValid()) {
    const date = moment(data).format('DD/MM/YYYY')
    const time = moment(data).format('HH:mm:ss')
    return `${date} ${time}`
  }
  return data
}

/**
 * Extract the 'changes' data of an audit log into an appropriate format.
 *
 * The 'changes' object from backend has the following format
 * {
 *   field: [before, after]
 * }
 * 'field' is the field that is changed
 * 'before' is the value of 'field' before
 * 'after' is the value of 'field' after
 *
 * This function converts to another object representation
 * {
 *  field: field, before: before, after: after
 * }
 *
 * @param {Array} changes An array of 'changes' object from the backend
 * @returns {Array} An array of formatted 'changes' object
 */
function extractChanges (changes) {
  if (changes) {
    const results = []
    const changesObj = JSON.parse(changes)
    const changedFields = Object.keys(changesObj)
    changedFields.forEach(field => {
      results.push({
        field: field,
        before: utils.formatDateTimeIfValidDateTime(changesObj[field][0]),
        after: utils.formatDateTimeIfValidDateTime(changesObj[field][1])
      })
    })
    return results
  }
  return [{ field: '', before: '', after: '' }]
}

/**
 * Parse a filter result into an appropriate format.
 * @param {Object} filterResult A raw filter result
 * @returns {Object} A formatted filter result that can be displayed in the frontend
 *
 * Note: 'details' and 'changes' are only applicable when user clicks on a specific AuditLog
 */
function parseFilterResult (filterResult) {
  const parsedFilterResult = {
    id: filterResult.id,
    date: moment(filterResult.timestamp).format('DD/MM/YYYY'),
    time: moment(filterResult.timestamp).format('HH:mm:ss'),
    record_id: filterResult.object_pk,
    record_type: filterResult.resource_type, // Already capitalized from backend
    user: filterResult.user,
    action: utils.capitalizeAllWords(filterResult.action),
    details: utils.formatDateTimeIfValidDateTime(filterResult.additional_data),
    changes: utils.extractChanges(filterResult.changes)
  }

  return parsedFilterResult
}

function processDateTimeUpperAndLowerBound (datetime) {
  const dateLowerBound = datetime.datetime_gte // Lower bound date requested by user
  const dateUpperBound = datetime.datetime_lte // Upper bound date requested by user
  let datetimeGte = ''
  let datetimeLte = ''

  if (dateLowerBound) {
    datetimeGte = moment(`${dateLowerBound}`).toISOString()
  }
  if (dateUpperBound) {
    datetimeLte = moment(`${dateUpperBound}`).toISOString()
  }

  return [datetimeGte, datetimeLte]
}

/**
 * Parses the object of filter values into correct representations for accurate filtering.
 * @param {Object} filterBody The raw filter values provided by user from the frontend
 * @returns {Object} An object of formatted filter values
 */
function createUrlParams (filterBody) {
  const datetime = filterBody.datetime
  const [datetimeGte, datetimeLte] = utils.processDateTimeUpperAndLowerBound(datetime)
  const params = {
    datetime_gte: datetimeGte,
    datetime_lte: datetimeLte,
    record_id: filterBody.record_id,
    user: filterBody.user,
    record_type: filterBody.record_type,
    action: filterBody.action.map(a => a.toLowerCase()),
    detail: filterBody.detail,
    page: filterBody.page,
    purge: filterBody.purge
  }
  return params
}

/**
 * Serialize an object of query params to a URL string representation.
 * @param {Object} params An object of values for the filter parameters
 * @returns {String} The URL string representation.
 *
 * For example, if the key 'user' has the values of ['admin', 'user1', 'user2'],
 * This function converts it to 'user=admin&user=user1&user=user2'
 */
function paramsSerializer (params) {
  return qs.stringify(params, { arrayFormat: 'repeat' })
}

/**
 * For testing purposes
 * Else the utils functions used in extractFilterResults cannot be mocked
 */
export const utils = {
  processDateTimeUpperAndLowerBound,
  capitalize,
  capitalizeAllWords,
  convertToTwoDigitString,
  parseFilterResult,
  createUrlParams,
  paramsSerializer,
  extractChanges,
  formatDateTimeFileName,
  formatDateTimeIfValidDateTime
}

export default utils

import ApiService from '@/modules/auth/services/api.service'
import { ConfigurationError } from '../errors/configuration_error'

const ActiveDirectorySyncService = {
  options: async function () {
    try {
      const response = await ApiService.options('/config/active_directory_sync/')
      return response.data.actions.POST
    } catch (error) {
      throw new ConfigurationError(error.response.status, error.response.data)
    }
  },
  sync: async function (data) {
    try {
      const response = await ApiService.post('/config/active_directory_sync/', data)
      return response.data
    } catch (error) {
      throw new ConfigurationError(error.response.status, error.response.data)
    }
  }
}

export { ActiveDirectorySyncService, ConfigurationError }

export default ActiveDirectorySyncService

import ApiService from '@/modules/auth/services/api.service'
import { ConfigurationError } from '../errors/configuration_error'

const DatabaseConnectionService = {
  options: async function () {
    try {
      const response = await ApiService.options('/config/dbconnection/')
      return response.data.actions.POST
    } catch (error) {
      throw new ConfigurationError(error.response.status, error.response.data)
    }
  },

  get: async function () {
    try {
      const response = await ApiService.get('/config/dbconnection/')
      return response.data
    } catch (error) {
      throw new ConfigurationError(error.response.status, error.response.data)
    }
  },

  check: async function (data) {
    try {
      const response = await ApiService.post('/config/dbconnection/check_database_connection/', data)
      return response.data
    } catch (error) {
      throw new ConfigurationError(error.response.status, error.response.data)
    }
  },

  save: async function (data) {
    try {
      await ApiService.post('/config/dbconnection/', data)
      const registrationResponse = await ApiService.get('/config/dbconnection/database_registration/')
      return registrationResponse.data
    } catch (error) {
      throw new ConfigurationError(error.response.status, error.response.data)
    }
  },

  save_with_no_change: async function () {
    try {
      await ApiService.post('/config/dbconnection/check_database_connection/')
      const registrationResponse = await ApiService.get('/config/dbconnection/database_registration/')
      return registrationResponse.data
    } catch (error) {
      throw new ConfigurationError(error.response.status, error.response.data)
    }
  }
}

export { DatabaseConnectionService, ConfigurationError }

export default DatabaseConnectionService

<template>
  <b-modal v-model="showModal" :title="modalTitle" hide-footer no-close-on-esc hide-header-close no-close-on-backdrop centered >

    <div class="modal-class">
      <p class="my-1"><WarningIcon /></p>
      <p class="my-4">{{ modalMessage }}</p>
    </div>
    <div class="modal-footer">
      <b-button class='logout' @click="$router.push({ path: '/logout' })" variant="primary">Log out</b-button>
    </div>
  </b-modal>
</template>

<script>
import WarningIcon from '@/assets/la-warning-triangle.svg'

export default {
  name: 'NoUserGroupModal',
  components: {
    WarningIcon
  },
  props: {
    showModal: {
      type: Boolean,
      default: false
    },
    modalTitle: {
      type: String,
      default: ''
    },
    modalMessage: {
      type: String,
      default: ''
    }
  }
}
</script>

<style scoped lang="scss">
.modal-class {
  display: flex;
  flex-direction: row;

  p {
    flex-grow: 0;
  }
}
</style>

import { ConfigurationError } from '../errors/configuration_error'
import ApiService from '@/modules/auth/services/api.service'

const UserServices = {
  /**
   * getUsers service directly makes get request to the backend with the params and receives the usersInformation in return.
   * @param {object} - consists of the configurations such as 'searchTerm', 'perPage' and 'sort' that determines the searching, pagination and sorting functionality
   * @return Object that consists of usersInformation
   */

  getUsers: async function (params) {
    try {
      const response = await ApiService.get('/insight_admin/user/', params)
      return response.data
    } catch (error) {
      throw new ConfigurationError(error.response.status, error.response.data)
    }
  },
  /**
   * getUserRecord retrieves specific user information based on the user id.
   * id is the lookup field hence included in the url configuration.
   * A configuration error is thrown that is caught on the action level in the case of an
   * exception
   * @param {integer} id
   * @returns {object} - contains user information except for id field, id field is not
   * determined by the user
   */
  getUserRecord: async function (id) {
    try {
      const response = await ApiService.get(`/insight_admin/user/${id}/`)
      delete response.data.id
      return response.data
    } catch (error) {
      throw new ConfigurationError(error.response.status, error.response.data)
    }
  },
  /**
   * updateUserRecord updates the user information based on the private key id.
   * id is the lookup field hence included in the url configuration.
   * A configuration error is thrown that is caught on the action level in the case of an
   * exception
   * @param {integer} id
   * @param {object} data
   * @returns {object}
   */
  updateUserRecord: async function (id, data) {
    try {
      await ApiService.put(`/insight_admin/user/${id}/`, data)
      return true
    } catch (error) {
      throw new ConfigurationError(error.response.status, error.response.data, error.response.data.showToast)
    }
  },
  /**
   * createUsers makes an API request to the backend with the data object.
   * @param {object} - data object with key value pairs corresponding backend fields declared
   * @return {boolean}
   */
  createUsers: async function (data) {
    try {
      await ApiService.post('/insight_admin/user/', data)
      return true
    } catch (error) {
      throw new ConfigurationError(error.response.status, error.response.data, error.response.data.showToast)
    }
  },
  /**
   * getUserFieldOptions makes an options call to the backend. If there are no exception, the
   * response object is returned, otherwise an exception thrown will be caught in the catch block.
   * @returns {object} - response object from the backend via an options call.
   */
  getUserFieldOptions: async function () {
    try {
      const response = await ApiService.options('/insight_admin/user/')
      return response
    } catch (error) {
      throw new ConfigurationError(error.response.status, error.response.data)
    }
  },
  /**
   * softDeleteUser makes an API PUT request to the backend and if there are no exceptions thrown, a boolean value of true would be returned.
   * Otherwise, the exception would be caught, thrown as a new instance of ConfigurationError.
   * @param {integer} - unique user ID
   * @returns {boolean}
   */
  softDeleteUser: async function (id) {
    try {
      await ApiService.put(
        `/insight_admin/user/${id}/soft_delete_user/`
      )
      return true
    } catch (error) {
      throw new ConfigurationError(error.response.status, error.response.data)
    }
  }
}

export { UserServices }
export default UserServices

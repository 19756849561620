import * as modules from './**/store/index.js'
import * as moduleRotues from './**/routes.js'

/**
 * Automatically register vuex modules from any installed modules
 */
// const moduleRotues = require.context('./', true, /.*\/routes\.js/)

let routes = []
// TODO: eslint 6.8.0 false-positive bug on no-unused-vars. To be resolved when it is fixed in newer version
// eslint-disable-next-line no-unused-vars
for (const key in moduleRotues) {
  routes = routes.concat(moduleRotues[key])
}

export {
  modules,
  routes
}

import { UserService, AuthenticationError } from '../services/user.service'
import router from '@/router'
import ToastMessage from '@/utils/toast_message'
import ApiService from '@/modules/auth/services/api.service'
import WebSocketService from '@/modules/auth/services/websocket.service.js'
import AUTH from '@/constants/AUTH.json'
import INSIGHT from '@/constants/INSIGHT.json'

export default {
  init ({ commit }, token) {
    commit('setToken', token)
  },

  clearErrors ({ commit }) {
    commit('authenticationClearErrors')
  },

  async login ({ commit }, { username, password }) {
    commit('authenticationRequest')
    try {
      const token = await UserService.login(username, password)
      const isAuthenticatorDeviceRegistered = (await UserService.getUser2FaDeviceRegisteredStatus()).device_status
      const is2FaFeatureEnabledOnBackend = (await UserService.getBackEnd2FaEnabledForUserStatus())['2fa_status']
      commit('loginSuccess', token)

      if (is2FaFeatureEnabledOnBackend) {
        if (isAuthenticatorDeviceRegistered) {
          router.push({ name: 'otp' })
        } else {
          router.push({ name: 'device-auth' })
        }
      } else {
        router.push(router.history.current.query.redirect || '/')
      }
      return true
    } catch (e) {
      if (e instanceof AuthenticationError) {
        commit('loginError', { errorCode: e.errorCode, errorMessage: e.message })
      }
      return false
    }
  },

  async changePassword ({ commit }, { oldPassword, newPassword, confirmPassword }) {
    commit('authenticationRequest')

    try {
      await UserService.changePassword(oldPassword, newPassword, confirmPassword)
      commit('changePasswordSuccess')

      return true
    } catch (e) {
      if (e instanceof AuthenticationError) {
        commit('changePasswordError', { errorCode: e.errorCode, errorMessage: e.message })
      }
      return false
    }
  },

  getPasswordFieldOptions: async ({ commit }) => {
    commit('authenticationClearErrors')
    try {
      const response = await UserService.getChangePasswordOptions()
      const formattedData = response.data.actions.PUT
      delete formattedData.new_password1.help_text
      commit('setPasswordFieldOptions', formattedData)
      return true
    } catch (error) {
      commit('changePasswordError', {
        errorCode: error.errorCode,
        errorMessage: error.message
      })
      return false
    }
  },

  async getPasswordOptionHelpText ({ commit }) {
    try {
      const passwordOption = await UserService.getChangePasswordOptions()
      return passwordOption.data.actions.PUT.new_password1.help_text
    } catch (e) {
      if (e instanceof AuthenticationError) {
        commit('changePasswordError', { errorCode: e.errorCode, errorMessage: e.message })
      }
      return false
    }
  },

  async logout ({ commit }, { setupRequired = false, audit = true }) {
    commit('logoutRequest')

    try {
      // Do not send audit signal when audit is not needed
      // Audit is not needed when user is logged out due to expiry of refresh token
      if (audit) {
        // Notify server that user has logged out
        await UserService.sendLogoutSignal()
      }

      return true
    } catch (e) {
      commit('logoutError', {
        errorCode: e.errorCode,
        errorMessage: e.message
      })
      return false
    } finally {
      // Allow logout regardless of whether signal is successfully sent
      UserService.logout()

      // This mutation clears access token and sets authenticating to false
      commit('logoutSuccess')

      // Test data passing
      await router.push({ name: 'login', params: { setupRequired: setupRequired } })
    }
  },

  async refreshToken ({ commit, state }) {
    // If this is the first time the refreshToken has been called, make a request
    // otherwise return the same promise to the caller

    if (!state.refreshTokenPromise) {
      // Wait for the UserService.refreshToken() to resolve. On success set the token and clear promise
      // Clear the promise on error as well.
      try {
        const p = UserService.refreshToken()
        commit('refreshTokenPromise', p)

        const response = await p
        commit('refreshTokenPromise', null)
        commit('loginSuccess', response)
      } catch (error) {
        if (error instanceof AuthenticationError) {
          commit('refreshError', { errorCode: error.errorCode, errorMessage: error.message })
        }
        commit('refreshTokenPromise', null)
        throw error
      }
    }

    return state.refreshTokenPromise
  },

  async retrieveUserData ({ commit }) {
    commit('resetUserData')
    try {
      const responseData = await UserService.retrieveUserData()
      commit('setUserData', responseData)
    } catch (error) {
      if (error instanceof AuthenticationError) {
        commit('userDataError', { errorCode: error.errorCode, errorMessage: error.message })
      }
    }
  },

  async showInterceptorToastAndLogout ({ commit, state, dispatch }, { toastTitle, toastMessage }) {
    const interceptorToastShown = state.interceptorToastShown
    if (!interceptorToastShown) {
      const delay = 5000

      ToastMessage.showErrorDefault({
        vueInstance: this._vm,
        title: toastTitle,
        textMessage: toastMessage,
        autoHideDelay: delay,
        opacity: 1
      })

      commit('interceptorToastShown')

      // Check if server reboot interceptor is currently mounted. If is mounted, unmount it so that the api service
      // does not continue to retry to see server is online as well as dismiss the server restarting model.
      if (ApiService._serverRebootInterceptor !== null) {
        ApiService.unmountServerRebootInterceptor()
        dispatch('config/serverRestarted', null, { root: true })
      }

      await dispatch('logout', { setupRequired: false, audit: false })

      // Wait for delay + 500 milliseconds to let the toast get automatically hidden by default.
      // This is to prevent duplicated toast message from appearing due to multiple 403 server calls to the interceptor
      await new Promise(resolve => setTimeout(resolve, delay + 500))

      commit('interceptorToastHidden')
    }
  },

  /**
   * This function opens a websocket connection with the specified options.
   * @param commit vuex commit injected by Vue
   * @param state vuex state injected by Vue
   * @param dispatch vuex dispatch injected by Vue
   * @param url The url string of the websocket connection
   * @param encodeMessage The boolean option if the incoming message should be encoded
   * @param onmessage The function to trigger whenever an incoming message is received
   * @return {Promise<*>} A websocket connection instance
   */
  async openWebSocketConnection ({ commit, dispatch }, { url, encodeMessage, onmessage }) {
    const connection = await WebSocketService.connect({ url, onmessage, encodeMessage })
    commit('setWebSocketConnectionCache', { connection, url })
    return connection
  },

  /**
   * This function gracefully closes the websocket connection and disable the auto reconnection.
   * @param commit vuex commit injected by Vue
   * @param state vuex state injected by Vue
   * @param url The url string of the websocket connection
   * @return {Promise<void>}
   */
  async closeWebSocketConnection ({ commit, state }, { url }) {
    const connection = state.websocketConnectionCache[url]
    if (connection) {
      connection.onclose = null
      connection.close()
      commit('setWebSocketConnectionCache', { connection: null, url })
    }
  },

  async getBackEnd2FaEnabledForUserStatus () {
    try {
      await UserService.getBackEnd2FaEnabledForUserStatus()
    } catch (error) {
      if (error instanceof AuthenticationError) {
        throw new AuthenticationError(error.errorCode, error.message)
      } else {
        throw error
      }
    }
  },

  async getUser2FaDeviceRegisteredStatus () {
    try {
      await UserService.getUser2FaDeviceRegisteredStatus()
    } catch (error) {
      if (error instanceof AuthenticationError) {
        throw new AuthenticationError(error.errorCode, error.message)
      } else {
        throw error
      }
    }
  },

  async getUser2FaDeviceQrCodeBase64String () {
    try {
      const responseData = await UserService.getUser2FaDeviceQrCodeBase64String()
      return responseData
    } catch (error) {
      if (error instanceof AuthenticationError) {
        throw new AuthenticationError(error.errorCode, error.message)
      } else {
        throw error
      }
    }
  },

  async postOtpCodeToRegisterDeviceAsTotpToken ({ commit }, otpCode) {
    commit('authenticationRequest')
    try {
      await UserService.postOtpCodeToRegisterDeviceAsTotpToken(otpCode)
      ToastMessage.showRegisteredSuccess({
        vueInstance: this._vm,
        opacity: 1
      })
      commit('otpDeviceRegisteredWithUserSuccess')
      return true
    } catch (error) {
      if (error instanceof AuthenticationError) {
        const errorMessage = error.message.message
        commit('otpDeviceRegisteredWithUserFailed', { errorMessage: errorMessage, errorCode: error.errorCode })
        return false
      } else {
        throw error
      }
    }
  },

  async postOtpCodeToVerifyUser ({ commit }, otpCode) {
    commit('otpVerificationRequest')
    try {
      await UserService.postOtpCodeToVerifyUserService(otpCode)
      ToastMessage.showVerifiedSuccess({
        vueInstance: this._vm,
        opacity: 1
      })
      commit('otpCodeVerificationSuccess')
      router.push(router.history.current.query.redirect || '/')
    } catch (error) {
      if (error instanceof AuthenticationError) {
        if (error.errorCode === 400) {
          commit('otpCodeVerificationFailed', { errorMessage: `${AUTH.OTP_DEVICE_FORM.INSTRUCTION}`, errorCode: error.errorCode })
        } else if (error.errorCode === 422) {
          commit('otpCodeVerificationFailed', { errorMessage: error.message.message, errorCode: error.errorCode })
        } else if (error.errorCode === 429) {
          ToastMessage.showErrorDefault({
            vueInstance: this._vm,
            variant: `${INSIGHT.TOAST_MESSAGE.VARIANT.DANGER}`,
            methodKey: `${INSIGHT.TOAST_MESSAGE.METHOD_KEY.LOCKED_OUT}`,
            opacity: 1
          })
          commit('otpCodeVerificationFailed', { errorMessage: error.message.detail, errorCode: error.errorCode })
          router.push({ name: 'logout' })
        }
      } else {
        throw error
      }
    }
  }
}

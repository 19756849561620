export default {
  clearError (state) {
    state.error = false
  },

  setError (state, error) {
    state.error = error
  },

  setSearchError (state, error) {
    state.searchError = error
  },

  clearSearchError (state) {
    state.searchError = false
  },

  setEntities (state, entities) {
    state.entities = entities
  },

  setLinks (state, links) {
    state.links = links
  },

  setRecords (state, records) {
    state.records = records
  },

  setRecord (state, record) {
    state.record = record
  },

  appendRecords (state, records) {
    // TODO: eslint 6.8.0 false-positive bug on no-unused-vars. To be resolved when it is fixed in newer version
    // eslint-disable-next-line no-unused-vars
    for (const record of records.results) {
      state.records.results.push(record)
    }
  },

  setFields (state, fields) {
    state.fields = fields
  },

  setSearchTerm (state, searchTerm) {
    state.searchTerm = searchTerm
  },

  resetFields (state) {
    state.fields = false
    state.record = false
    state.records = false
  },

  resetRecords (state) {
    state.records = false
  },

  setAdvancedSearchFields (state, fields) {
    state.advancedSearchFields = fields
  },

  setUserFavouriteList (state, items) {
    state.userFavouriteList = items
  },

  toggleHideLinkLabel (state, isHidden) {
    state.hideLinkLabel = isHidden
  }

}
